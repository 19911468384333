/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import {
  DropDown,
  GridDatePickerForm,
  GridTextFieldForm,
  // RadioButton,
  WarningModal,
  LoanStage
} from "@toorak/tc-common-fe-sdk";
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useState,
  useCallback
} from "react";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../stores/rootReducer";
import {
  BorrowerInformation,
  emptyBorrower,
  emptyEntityBorrower,
  getEmptyBorrower
} from "../create-loan.reducerHelper";
import {
  borrowerFieldChanged,
  deleteBridgeLoanGuarantor,
  fetchEditHistory,
  updatePrimaryBorrower
} from "../create-loan.reducer";
import {
  checkFieldValidity,
  sanitizeValueByType,
  isEquivalentObjects,
  enableReasonField
} from "../../utils/formatChecks";
import { useStyles } from "../common-style/loanWrapperSections.style";
import {
  deleteBorrower,
  updateBorrowerInfo,
  updateBridgeLoanGurantor,
  updateFESBorrowerInfo
} from "../create-loan.action";
import { getFormattedAuditData, uuidV4 } from "../../utils/utils";
import { prepareCommentObject } from "./LoanDetailsBorrowerInformation";
import { isLATUser } from "../../utils/AccessManagement";
import { bridgeLoanFields } from "../constants/loanFieldType";
import { AutocompleteAddressField } from "../../ui-components/AutoCompleteAddressField";
import { getBorrowerInformationFields } from "../../v2/fields/LoanDetails/BorrowerInformation";
import {
  getLoanUserMapData,
  getSpecificAuditList,
  patchLoanData
} from "../../network/apiService";
import { LoanSizerEnum } from "../../frontend-sizer/FrontendSizerTemplate";
import {
  createInitialErrorFields,
  getUpdatedBorrowerInfoToAdd,
  getUpdatedBorrowerInfoToDelete,
  setErrorField,
  updateBorrowerData
} from "../../v2/views/common/util";
import { ObjectType } from "../../masterView/common";
import {
  formEditStatusEnum,
  updateFieldEditCount
} from "../../v2/loanDetailStore.reducer";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import GetFieldUI from "../../v2/views/common/GetFieldUI";

let editedFieldArray: any = [];
let secondaryBorrowerEdited: boolean = false;
let changedValueFields: any = [];
let emptyValueFieldCount: number = 0;
export interface BorrowerProps {
  isMinimized: boolean;
  borrowerObject: BorrowerInformation;
  borrowerCount: number;
  index: number;
  isEvaluationPanelVisible: boolean;
  hasIndividualBorrower: boolean;
  firstEntityBorrowerId?: string;
  isViewMode?: boolean;
  isServicerOnboarding?: boolean;
  isMandatoryServicerField?: any;
  servicerLoanType?: string;
  setCommentObj?: any;
  reasonCommentObj?: any;
  updatedFields?: any;
  setUpdatedFields?: any;
  borrowerInfoData?: any;
  setBorrowerInfoData?: any;
  borrowerId?: any;
  setGuarantorsData?: any;
  guarantorsData?: any;
}
export interface ErrorObject {
  [index: string]: string;
}

export function SingleBorrowerSection(props: BorrowerProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loanId, loanType, loanStage } = useParams<{
    loanId: string;
    loanStage: LoanStage;
    loanType: string;
  }>() as any;
  const { isFormEditing, isDataEntry, fieldEditCount, formEditStatus } =
    useSelector<RootState, any>((state) => state.loanDetailsStore);

  const editHistory = useSelector<RootState, any>(
    (state) => state.createLoanStore.editHistory
  );
  const { validate } = useSelector<
    RootState,
    { isSubmitValidation: boolean; validate: boolean }
  >((state) => state.createLoanStore.validateForm);

  let editHistory2: any;
  editHistory2 = editHistory;

  const {
    isMinimized,
    borrowerObject,
    borrowerId,
    index,
    borrowerCount,
    isEvaluationPanelVisible,
    hasIndividualBorrower,
    firstEntityBorrowerId,
    isViewMode,
    isServicerOnboarding,
    isMandatoryServicerField,
    // servicerLoanType,
    setCommentObj,
    reasonCommentObj,
    updatedFields,
    setUpdatedFields,
    borrowerInfoData,
    setBorrowerInfoData,
    setGuarantorsData,
    guarantorsData
  } = props;

  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const [borrowerInfo, setBorrowerInfo] = useState<{ [index: string]: any }>(
    {}
  );

  const editedFields = useSelector<RootState, any>(
    (state: any) => state.createLoanStore.editedFields
  );
  const [totalChangedFields, setTotalChangedFields] = useState<{
    [index: string]: boolean;
  }>({});
  const [editedFieldArraystate, setEditedFieldArraystate] = useState([]);

  const {
    bridgeLoanBorrowerInformation,
    bridgeLoanInformation,
    thirtyYearLoanInformation,
    bridgeLoanGuarantorInformation,
    loanState,
    bridgeIsGroundUpLoan,
    loanSizerType
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);
  const [fieldErrors, setFieldErrors] = useState<any>(new Map());
  const [borrowerIndex, setBorrowerIndex] = useState(0);
  const [borrowerFields, setBorrowerFields] = useState<any>([]);
  const loanInformationData =
    loanType === "InvestorDSCR"
      ? thirtyYearLoanInformation
      : bridgeLoanInformation;
  const colSpan = 4;
  const dataEntryCheck =
    [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError].includes(loanState) ||
    (loanState !== LoanStatusEnum.DataEntry && loanStage === LoanStage.fes);

  let borrowerIdx =
    loanStage === LoanStage.fes
      ? 0
      : bridgeLoanBorrowerInformation?.findIndex(
          (bInfo: any) => bInfo?.borrowerId === borrowerId
        );
  const setStoreToLocal = useCallback(() => {
    const currentBorrower =
      loanStage === LoanStage.fes
        ? borrowerObject?.payload
        : bridgeLoanBorrowerInformation?.find(
            (bInfo: any) => bInfo?.borrowerId === borrowerId
          )?.payload;
    setBorrowerInfo(currentBorrower);
  }, [borrowerObject, bridgeLoanBorrowerInformation]);

  useEffect(() => {
    const fields = getBorrowerInformationFields(
      loanStage.toLowerCase(),
      loanType,
      loanInformationData?.payload?.selectedToorakProduct || loanSizerType
    );
    setBorrowerFields(fields);
  }, [
    loanStage,
    loanType,
    loanInformationData?.payload?.selectedToorakProduct,
    loanSizerType
  ]);

  useEffect(() => {
    if (isViewMode) {
      setEditedFieldArraystate([]);
      changedValueFields = [];
      editedFieldArray = [];
    }

    const currentBorrower =
      loanStage === LoanStage.fes
        ? borrowerObject?.payload
        : borrowerInfoData?.find(
            (bInfo: any) => bInfo?.borrowerId === borrowerId
          )?.payload;
    const isChangeInPrimaryBorrower = borrowerInfoData?.find(
      (bInfo: any) =>
        bInfo?.borrowerId === borrowerId &&
        `${borrowerInfo?.isPrimaryBorrower}` !==
          `${bInfo?.payload?.isPrimaryBorrower}`
    );
    if (!Object.keys(borrowerInfo)?.length) {
      setBorrowerInfo({ ...currentBorrower });
    } else if (isChangeInPrimaryBorrower && (isFormEditing || isDataEntry)) {
      setBorrowerInfo({
        ...borrowerInfo,
        isPrimaryBorrower: isChangeInPrimaryBorrower?.payload?.isPrimaryBorrower
      });
    }
    if (borrowerCount > 1) {
      borrowerInfoData?.map((item: any, index: any) => {
        if (item?.borrowerId === borrowerId) {
          setBorrowerIndex(index);
        }
      });
    }
  }, [borrowerInfoData, isViewMode, borrowerFields]);

  useEffect(() => {
    if (
      !loanState ||
      fieldErrors.size !== 0 ||
      Object.keys(borrowerInfo).length === 0
    )
      return;
    let initialErrorData = new Map();
    initialErrorData = createInitialErrorFields(borrowerFields, borrowerInfo);
    setFieldErrors((fieldErrors: any) => {
      return new Map([...initialErrorData, ...fieldErrors]);
    });
  }, [borrowerFields, loanState, borrowerInfo]);

  const updateBorrowerInfoData = useCallback(
    (borrowerInformationData: any) => {
      updateBorrowerData(
        borrowerId,
        { payload: borrowerInformationData },
        dispatch,
        loanStage
      );
    },
    [loanStage, dispatch]
  );

  useEffect(() => {
    if (dataEntryCheck) {
      updateBorrowerInfoData(borrowerInfo);
    } else if (isFormEditing) {
      setBorrowerInfoData(
        borrowerInfoData.map((bInfo: any) => {
          if (bInfo.borrowerId === borrowerId) {
            return {
              ...bInfo,
              payload: {
                ...bInfo.payload,
                ...borrowerInfo
              }
            };
          }
          return bInfo;
        })
      );
    }
  }, [borrowerInfo]);

  useEffect(() => {
    if (formEditStatus === formEditStatusEnum.notSaved) {
      setStoreToLocal();
    }
  }, [formEditStatus, setStoreToLocal]);

  const updateBorrowerFields = (
    item: any,
    fieldName: any,
    value: any,
    additionalData?: any
  ) => {
    let tempBorrowerDetails: any = updatedFields?.borrowerInfo?.length
      ? [...updatedFields?.borrowerInfo]
      : [];
    const key = item?.backendKey || fieldName;
    let addressData: any = {};

    if (fieldName === "billingAddress") {
      if (value !== "") {
        addressData = {
          [fieldName]: {
            billingAddress: additionalData?.fullAddress,
            city: additionalData?.city ?? "",
            state: additionalData?.state ?? "",
            pincode: additionalData?.pincode ?? "",
            locationValidationStatus: value ? "COMPLETE" : "OVERRIDDEN"
          },
          locationValidationStatus: value ? "COMPLETE" : "OVERRIDDEN",
          city: additionalData?.city ?? "",
          state: additionalData?.state ?? "",
          pincode: additionalData?.pincode ?? ""
        };
      }
    } else {
      addressData = {
        [fieldName]: value,
        locationValidationStatus: "OVERRIDDEN",
        city: "",
        state: "",
        pincode: ""
      };
    }

    borrowerInfoData?.map((bInfo: any, bIdx: number) => {
      const isPrimaryBorrower = borrowerInfoData?.some(
        (bInformation: any) => bInformation?.payload?.isPrimaryBorrower
      );
      if (
        updatedFields?.borrowerInfo?.length &&
        updatedFields?.borrowerInfo[bIdx] &&
        bIdx === index
      ) {
        const newUpdatedFields =
          key === "loanUserType"
            ? updatedFields?.borrowerInfo?.filter(
                (bInfo: any, idx: number) => idx !== borrowerIdx
              )
            : [...updatedFields?.borrowerInfo];

        let returnObj = {
          ...newUpdatedFields[bIdx]
        };
        if (fieldName === "billingAddress" && value !== "") {
          returnObj = {
            ...returnObj,
            ...addressData
          };
        } else {
          returnObj = {
            ...returnObj,
            [`${key}`]: value
          };
        }
        if (key === "loanUserType") {
          returnObj.hasBorrowerChanged = true;
          returnObj.isPrimaryBorrower =
            !isPrimaryBorrower && value === "Individual";
        }
        tempBorrowerDetails[bIdx] = returnObj;
      } else if (bIdx === index) {
        if (fieldName === "billingAddress" && value !== "") {
          tempBorrowerDetails[index] = {
            ...addressData
          };
        } else {
          tempBorrowerDetails[index] = {
            ...tempBorrowerDetails[index],
            [`${key}`]: value as string
          };
        }
        if (key === "loanUserType") {
          tempBorrowerDetails[index].isPrimaryBorrower =
            !isPrimaryBorrower && value === "Individual";
          tempBorrowerDetails[index].hasBorrowerChanged = true;
        }
      }
    });
    setUpdatedFields({ ...updatedFields, borrowerInfo: tempBorrowerDetails });
  };

  const handleChange = (value: any, item: any, fieldLabel: string) => {
    const { fieldName, backendKey, fieldType } = item;
    if (fieldType?.() === "radioGroup") {
      handlePrimaryBorrowerClick(borrowerId);
      return;
    }
    const sanitisedValue = sanitizeValueByType(value, fieldType);
    let borrowerObj: any = {
      [fieldName]: sanitisedValue,
      borrowerLoanUserSequence: borrowerInfo?.borrowerLoanUserSequence || "0"
    };
    const regex = /^[0-9-]+$/;
    if (value && item.fieldType() === "phone" && !regex.test(value)) {
      return;
    }

    if (!fieldEditCount) {
      dispatch(updateFieldEditCount(1));
    }

    if (
      fieldName === "borrowerType" &&
      ((isDataEntry && !bridgeLoanGuarantorInformation?.length) ||
        (isFormEditing && !guarantorsData?.length)) &&
      loanStage !== LoanStage.fes
    ) {
      if (sanitisedValue === "Entity") {
        if (!isFormEditing) {
          const id = uuidV4();
          dispatch(
            updateBridgeLoanGurantor(id, [
              {
                guarantorId: uuidV4(),
                errors: null,
                payload: {
                  isPrimaryGuarantor: loanType === "InvestorDSCR",
                  creditScore: "",
                  guarantorEmail: "",
                  guarantorExperience: "",
                  guarantorLastName: "",
                  guarantorFirstName: "",
                  originalCreditReportDate: null,
                  pOEntity: "",
                  foreignNationalString: ""
                }
              }
            ])
          );
        } else {
          setGuarantorsData([
            ...guarantorsData,
            {
              guarantorId: uuidV4(),
              errors: null,
              payload: {
                isPrimaryGuarantor: loanType === "InvestorDSCR",
                creditScore: "",
                guarantorEmail: "",
                guarantorExperience: "",
                guarantorLastName: "",
                guarantorFirstName: "",
                originalCreditReportDate: null,
                pOEntity: "",
                foreignNationalString: ""
              }
            }
          ]);
        }
      } else if (loanState === LoanStatusEnum.DataEntry) {
        bridgeLoanGuarantorInformation?.map((guarantor: any) => {
          dispatch(deleteBridgeLoanGuarantor(guarantor?.guarantorId));
        });
      }
    }

    if (fieldName === "borrowerType") {
      if (sanitisedValue === "Entity") {
        borrowerObj.isPrimaryBorrower = false;
      } else {
        const singlePrimaryBorrower = borrowerInfoData?.some(
          (bInfo: any) => bInfo?.payload?.isPrimaryBorrower
        );
        borrowerObj.isPrimaryBorrower =
          !singlePrimaryBorrower && sanitisedValue === "Individual";
      }
    }

    let payloadObj: any = {
      payload: { [fieldName]: sanitisedValue }
    };

    if (fieldName === "billingAddress") {
      if (sanitisedValue !== "") {
        payloadObj = {
          payload: {
            [fieldName]: value?.fullAddress ?? sanitisedValue,
            locationValidationStatus: value ? "COMPLETE" : "OVERRIDDEN",
            city: value?.city ?? "",
            state: value?.state ?? "",
            pincode: value?.pincode ?? ""
          }
        };
      } else {
        payloadObj = {
          payload: {
            [fieldName]: sanitisedValue,
            locationValidationStatus: "OVERRIDDEN",
            city: "",
            state: "",
            pincode: ""
          }
        };
      }
    }

    if (fieldName === "billingAddress" && sanitisedValue !== "") {
      setBorrowerInfo({ ...borrowerInfo, ...payloadObj.payload });
    } else if (fieldName === "borrowerType") {
      const singlePrimaryBorrower = borrowerInfoData?.find(
        (bInfo: any) => bInfo?.payload?.isPrimaryBorrower
      );
      const emptyData =
        sanitisedValue === "Individual" ? emptyBorrower : emptyEntityBorrower;
      borrowerObj = {
        ...emptyData.payload,
        ...borrowerObj,
        borrowerLoanUserSequence: borrowerInfo?.borrowerLoanUserSequence ?? "0",
        isPrimaryBorrower:
          sanitisedValue === "Individual" ? !singlePrimaryBorrower : false
      };
      setBorrowerInfo(borrowerObj);
    } else {
      setBorrowerInfo({ ...borrowerInfo, ...borrowerObj });
    }

    if (fieldName === "borrowerType" || fieldName === "firstTimeHomeBuyer") {
      onBlur(sanitisedValue, item, true);
    }
    if (fieldType?.() === "date") {
      // updateBorrowerFields(item, fieldName, sanitisedValue)
      onBlur(value, item);
    }
  };

  const onBlur = (
    value: any,
    item: any,
    isBorrowerTypeUpdate?: boolean,
    additionalAdressData?: any
  ) => {
    const { fieldName } = item;
    const borrowerToBeUpdated: any = [];
    const additionalData = additionalAdressData
      ? additionalAdressData
      : { fullAddress: value };
    let commentField = "";
    let payloadObj: any = {
      payload: { [fieldName]: value }
    };

    if (!isBorrowerTypeUpdate && fieldName === "borrowerType") return;

    const sanitisedValue = sanitizeValueByType(value, item?.fieldType());

    if (fieldName === "billingAddress") {
      if (sanitisedValue !== "") {
        payloadObj = {
          payload: {
            [fieldName]: value?.fullAddress ?? sanitisedValue,
            locationValidationStatus: value ? "COMPLETE" : "OVERRIDDEN",
            city: value?.city ?? "",
            state: value?.state ?? "",
            pincode: value?.pincode ?? ""
          }
        };
      } else {
        payloadObj = {
          payload: {
            [fieldName]: sanitisedValue,
            locationValidationStatus: "OVERRIDDEN",
            city: "",
            state: "",
            pincode: ""
          }
        };
      }
    }

    if (fieldName === "billingAddress" && sanitisedValue !== "") {
      setBorrowerInfo({ ...borrowerInfo, ...payloadObj.payload });
    }

    if (["borrowerEmail"].includes(fieldName)) {
      const isEmailValid = checkFieldValidity(sanitisedValue, {
        fieldTpe: "email"
      });
      if (!isEmailValid) {
        setFieldErrors((fieldsErrors: any) => {
          const updatedFieldsErrors = new Map(fieldsErrors);
          return updatedFieldsErrors.set(
            fieldName,
            "Please enter a valid email"
          );
        });
        return;
      } else {
        setFieldErrors((fieldsErrors: any) => {
          const updatedFieldsErrors = new Map(fieldsErrors);
          updatedFieldsErrors.set(fieldName, "");
          return updatedFieldsErrors;
        });
      }
    }

    switch (fieldName) {
      case "borrowerType":
        commentField = `data.loan.loanUserMap[${borrowerInfo.loanUserMapId}].loanUserType`;
        break;
      case "foreignNationalString":
        commentField = `data.loan.loanUserMap[${borrowerInfo.loanUserMapId}].customer.foreignNationalString`;
        break;
      case "originalCreditReportDate":
        commentField = `data.loan.loanUserMap[${borrowerInfo.loanUserMapId}].originalCreditScoreReportDate`;
        break;
      case "firstTimeHomeBuyer":
        commentField = `data.loan.loanUserMap[${borrowerInfo.loanUserMapId}].firstTimeHomeBuyer`;
        break;
      default:
        commentField = `data.loan.loanUserMap[${borrowerInfo.loanUserMapId}].${fieldName}`;
        break;
    }

    if (item?.commentField) {
      commentField = `data.loan.loanUserMap[${borrowerInfo.loanUserMapId}].${item?.commentField}`;
    }

    if (isEvaluationPanelVisible) {
      prepareCommentObject(
        commentField,
        value,
        "",
        false,
        setCommentObj,
        isLATUser() ||
          enableReasonField(
            editedFieldArraystate,
            item.ruleFieldName + borrowerIndex,
            loanState
          ),
        reasonCommentObj,
        true
      );
    }

    if (fieldName === "borrowerType") {
      const singlePrimaryBorrower = borrowerInfoData?.some(
        (bInfo: any) => bInfo?.payload?.isPrimaryBorrower
      );
      payloadObj.payload.isPrimaryBorrower =
        borrowerInfoData.length === 1 && value === "Individual"
          ? true
          : !singlePrimaryBorrower;
    }

    if (fieldName === "borrowerType" && isFormEditing) {
      const singlePrimaryBorrower = borrowerInfoData?.find(
        (bInfo: any) =>
          bInfo?.payload?.isPrimaryBorrower &&
          bInfo?.payload?.borrowerType === "Individual" &&
          bInfo.borrowerId !== borrowerId
      );
      const firstIndividualBorrowerIdx = borrowerInfoData?.findIndex(
        (bInfo: any) =>
          !bInfo?.payload?.isPrimaryBorrower &&
          bInfo?.payload?.borrowerType === "Individual" &&
          bInfo.borrowerId !== borrowerId
      );

      borrowerInfoData?.map((bInfo: any, bIdx: number) => {
        if (bInfo.borrowerId === borrowerId) {
          borrowerToBeUpdated.push({
            ...bInfo,
            payload: {
              ...bInfo.payload,
              [fieldName]: value,
              hasBorrowerChanged: true,
              isPrimaryBorrower:
                !singlePrimaryBorrower && value === "Individual"
            }
          });
        } else {
          borrowerToBeUpdated.push({
            ...bInfo,
            payload: {
              ...bInfo.payload
            }
          });
        }
        if (
          value === "Entity" &&
          !singlePrimaryBorrower &&
          borrowerInfoData?.length > 1 &&
          firstIndividualBorrowerIdx >= 0
        ) {
          borrowerToBeUpdated[firstIndividualBorrowerIdx].payload = {
            ...borrowerToBeUpdated[firstIndividualBorrowerIdx]?.payload,
            isPrimaryBorrower: true
          };
          setBorrowerInfoData(borrowerToBeUpdated);
        }
      });
    }

    // updating the updatedFields state
    updateBorrowerFields(item, fieldName, value, additionalData);

    // set error object
    setErrorField(item, value, setFieldErrors);
  };

  let infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentLoan
  );
  const loanInfoContent = useMemo(
    () => JSON.parse(JSON.stringify(infoContent || {})),
    [infoContent]
  );

  const handleDelete = async (borrowerToBeUpdated?: any) => {
    const tempBorrowerData = borrowerToBeUpdated.length
      ? borrowerToBeUpdated
      : borrowerInfoData;
    setOpenWarning(false);
    if (dataEntryCheck) {
      try {
        dispatch(showLoader());
        let partyId = borrowerInfo.partyId;
        const loanUserMap: any = await getLoanUserMapData(loanId);
        const borrowerUsers = loanUserMap?.data?.filter(
          (user: ObjectType) => user?.loanUserType === "Borrower"
        );

        if (!partyId) {
          partyId =
            borrowerUsers?.find(
              (user: ObjectType) =>
                user?.loanUserSequence ==
                  borrowerInfo?.borrowerLoanUserSequence &&
                user?.loanUserType === "Borrower"
            )?.partyId ?? "";
        }
        const currentUserSequence = borrowerUsers?.find(
          (user: ObjectType) => user?.partyId === partyId
        )?.loanUserSequence;

        const updatedFields = getUpdatedBorrowerInfoToDelete(
          borrowerId,
          tempBorrowerData,
          true,
          partyId,
          currentUserSequence
        );
        const returnPayload: any = {
          loanState,
          loanData: {
            loanId,
            loanTypeId: loanType === "BridgeLoan" ? 2 : 1,
            loanType,
            loanUserMap: updatedFields
          }
        };
        const currentLoanType = loanType === "BridgeLoan" ? "bridge" : "dscr";
        if (borrowerUsers.length) {
          patchLoanData(
            returnPayload,
            currentLoanType,
            loanId,
            loanStage.toUpperCase()
          );
        }
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(hideLoader());
      }
    }
    let newUpdatedBorrowerFieldEdit = updatedFields?.borrowerInfo?.length
      ? [...updatedFields?.borrowerInfo]
      : [];
    const deletedBorrowerInfo = tempBorrowerData?.filter(
      (bInfo: any, idx: any) => {
        if (bInfo.borrowerId !== borrowerId) {
          return true;
        }
        newUpdatedBorrowerFieldEdit = newUpdatedBorrowerFieldEdit?.filter(
          (item: any, bIdx: number) => bIdx !== idx
        );
      }
    );
    setUpdatedFields({
      ...updatedFields,
      borrowerInfo: newUpdatedBorrowerFieldEdit.filter((item: any) => item)
    });
    const anyPrimaryBorrower = deletedBorrowerInfo?.find(
      (bInfo: any) =>
        bInfo?.payload?.isPrimaryBorrower &&
        bInfo?.payload?.borrowerType === "Individual"
    );
    const firstIndividualBorrower = deletedBorrowerInfo?.findIndex(
      (bInfo: any) => bInfo?.payload?.borrowerType === "Individual"
    );
    if (!anyPrimaryBorrower) {
      const newBData = deletedBorrowerInfo.map((bInfo: any, idx: any) => {
        if (
          bInfo?.payload?.borrowerType === "Individual" &&
          idx === firstIndividualBorrower
        ) {
          return {
            ...bInfo,
            payload: {
              ...bInfo?.payload,
              isPrimaryBorrower: true
            }
          };
        }
        return bInfo;
      });
      setBorrowerInfoData([...(newBData ?? [])]);
    } else {
      setBorrowerInfoData(deletedBorrowerInfo);
    }

    dispatch(updateFieldEditCount(1));
    if (isDataEntry) {
      dispatch(deleteBorrower(borrowerId));
    }
    if (!updatedFields?.borrowerInfo?.length) {
      const tempData = { borrowerId, payload: { op: "delete" } };
      setUpdatedFields({
        ...updatedFields,
        borrowerInfo: tempData
      });
    }
    return borrowerId;
  };

  const handleAddBorrower = async (
    partyType: any,
    deletedBorrowerId?: any,
    borrowerToBeUpdated?: any,
    isReset?: any
  ) => {
    let tempBorrowerData = isFormEditing
      ? borrowerToBeUpdated?.length
        ? borrowerToBeUpdated
        : borrowerInfoData
      : bridgeLoanBorrowerInformation;
    tempBorrowerData = tempBorrowerData.filter(
      (item: any) => item.borrowerId !== deletedBorrowerId
    );
    let userSequence: any = 0;
    tempBorrowerData.forEach((item: any) => {
      if (+item.payload.borrowerLoanUserSequence > userSequence) {
        userSequence = +item?.payload?.borrowerLoanUserSequence;
      }
    });
    // const loanUserSequence = `${userSequence + 1}`;
    const borrowerId = uuidV4();

    const isPrimaryBorrower: any = tempBorrowerData?.find(
      (item: any) =>
        item.payload.borrowerType === "Individual" &&
        item.payload.isPrimaryBorrower === true
    );
    const emptyBorrowerPayload = {
      ...getEmptyBorrower(loanType).payload,
      ...{
        isPrimaryBorrower: !Boolean(isPrimaryBorrower) || Boolean(isReset),
        partyId: "",
        borrowerType: partyType,
        op: "add"
      }
    };
    let newBorrower = {
      borrowerId,
      errors: null,
      payload: emptyBorrowerPayload
    };

    const loanUserMap: any = await getLoanUserMapData(loanId);
    const borrowerUsers = loanUserMap?.data
      ?.filter((user: ObjectType) => user?.loanUserType === "Borrower")
      ?.sort((b1: any, b2: any) => b1.loanUserSequence - b2.loanUserSequence);

    const { partyId, loanUserSequence }: ObjectType =
      borrowerUsers?.[borrowerUsers?.length - 1] ?? {};
    const newSequence = isFormEditing
      ? !isNaN(+loanUserSequence)
        ? +loanUserSequence + 1
        : 0
      : loanUserSequence;

    newBorrower = {
      ...newBorrower,
      payload: {
        ...newBorrower.payload,
        partyId: isFormEditing ? "" : partyId,
        borrowerLoanUserSequence: `${newSequence}`
      }
    };

    const tempNewBorrowerAdded = [...tempBorrowerData, newBorrower];
    if (dataEntryCheck) {
      try {
        dispatch(showLoader());
        const data = getUpdatedBorrowerInfoToAdd(
          tempNewBorrowerAdded.length,
          emptyBorrowerPayload,
          newSequence,
          isReset
        );
        const returnPayload: any = {
          loanState,
          loanData: {
            loanId,
            loanTypeId: loanType === "BridgeLoan" ? 2 : 1,
            loanType,
            loanUserMap: data
          }
        };
        const currentLoanType = loanType === "BridgeLoan" ? "bridge" : "dscr";
        await patchLoanData(
          returnPayload,
          currentLoanType,
          loanId,
          loanStage.toUpperCase()
        );
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(hideLoader());
      }
    }
    const dataToBePushed = tempBorrowerData.length
      ? [...tempBorrowerData, newBorrower]
      : [newBorrower];
    setBorrowerInfoData(dataToBePushed);
    dispatch(updateBorrowerInfo(borrowerId, newBorrower));
    if (
      [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError].includes(
        loanState
      ) &&
      isDataEntry
    ) {
      dispatch(updateFESBorrowerInfo(borrowerId, newBorrower));
    }
  };

  const isRequired = (item: any) => {
    let required =
      (borrowerInfo.isPrimaryBorrower &&
        !bridgeLoanFields[item.fieldName]?.optionalIn?.includes(loanStage)) ||
      (!hasIndividualBorrower &&
        borrowerId === firstEntityBorrowerId &&
        !bridgeLoanFields[item.fieldName]?.optionalIn?.includes(loanStage));

    if (required && item.fieldName === "originalCreditScoreMedian") {
      if (borrowerInfo.foreignNationalString === "Yes") {
        required = false;
      }
    }
    return required;
  };

  const checkGroundUpFields = (fieldName: string): boolean => {
    //SSN or EIN fields to show only for Servicer Onboarding flow
    if (["ssNumber", "eiNumber"].includes(fieldName))
      return !!isServicerOnboarding;
    if (
      ![
        "heavyRehabExperience",
        "borrowerGUCExperience",
        "borrowerExperience"
      ].includes(fieldName)
    )
      return true;
    return bridgeIsGroundUpLoan ||
      [
        loanInformationData?.payload?.selectedToorakProduct,
        loanSizerType
      ].includes(LoanSizerEnum.groundUp)
      ? ["heavyRehabExperience", "borrowerGUCExperience"].includes(fieldName)
      : fieldName === "borrowerExperience";

    // showing heavyRehabExperience field, only when bridgeIsGroundUpLoan is true & loan is not submitted
  };

  const onEditTagHover = (item: any) => {
    if (!item) return;
    const { fieldType } = item;
    const fieldPath = `data.loan.loanUserMap[${borrowerInfo.loanUserMapId}].${item.commentField}`;
    getSpecificAuditList(`${loanId}_${loanStage}`, fieldPath, "loans").then(
      (res: any) => {
        const auditData = getFormattedAuditData(res, fieldPath, fieldType);
        dispatch(fetchEditHistory(auditData));
      }
    );
  };

  const getEditedTagVisibility = (item: any, bIdx: number) => {
    return editedFields.includes(`${item}`);
  };

  const handlePrimaryBorrowerClick = (borrowerId: string) => {
    if (
      borrowerCount === 1 &&
      borrowerInfoData?.length === 1 &&
      borrowerInfo?.payload?.isPrimaryBorrower
    ) {
      return;
    }

    let tempUpdatedBorrowerInfo: any = [];

    const borrowersToBeUpdated = borrowerInfoData?.map(
      (bData: any, idx: number) => {
        if (
          updatedFields?.borrowerInfo?.length &&
          updatedFields?.borrowerInfo[idx]
        ) {
          tempUpdatedBorrowerInfo[idx] = {
            ...(updatedFields?.borrowerInfo[idx] ?? {})
          };
        }
        if (bData?.payload?.borrowerType === "Individual") {
          tempUpdatedBorrowerInfo[idx] = {
            ...(tempUpdatedBorrowerInfo[index] ?? {}),
            isPrimaryBorrower: bData?.borrowerId === borrowerId
          };

          return {
            ...bData,
            payload: {
              ...bData?.payload,
              isPrimaryBorrower: bData?.borrowerId === borrowerId
            }
          };
        }
        return {
          ...bData
        };
      }
    );

    if (dataEntryCheck) {
      dispatch(updatePrimaryBorrower({ borrowerId }));
      setBorrowerInfoData(tempUpdatedBorrowerInfo);
    } else {
      setBorrowerInfoData(borrowersToBeUpdated);
    }
    setUpdatedFields({
      ...updatedFields,
      borrowerInfo: tempUpdatedBorrowerInfo
    });
  };

  async function handleDeleteData() {
    try {
      if (borrowerCount == 1) {
        const deletedBorrowerId = await handleDelete(borrowerInfoData);
        handleAddBorrower(
          "Individual",
          deletedBorrowerId,
          borrowerInfoData,
          true
        );
      } else {
        setOpenWarning(true);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Grid
      container
      spacing={4}
      style={{
        paddingTop: isMandatoryServicerField ? 0 : 14,
        paddingBottom: 8,
        display: isMinimized ? "none" : ""
      }}
      className={classes.sectionButtonWrapper}
    >
      {openWarning && (
        <WarningModal
          isOpen={openWarning}
          handleClose={handleDelete}
          handleOptional={() => {
            setOpenWarning(false);
          }}
          primaryBtnName="Proceed"
          header="Warning"
          body="Click on Proceed to delete this borrower."
        />
      )}
      {loanStage !== LoanStage.fes ? (
        <Grid
          item
          xs={12}
          style={{ padding: 16, paddingTop: 7, paddingBottom: 7 }}
        >
          <Typography style={{ fontWeight: 600 }}>
            Borrower {loanStage === LoanStage.fes ? "" : index + 1}
          </Typography>
        </Grid>
      ) : null}
      <GetFieldUI
        formFields={borrowerFields}
        currentObjectValue={borrowerInfo}
        isEvaluationPanelVisible={isEvaluationPanelVisible}
        isEditable={isFormEditing || isDataEntry}
        handleChange={handleChange}
        onBlur={onBlur}
        fieldsError={fieldErrors}
        infoContentPath={`fieldInfo.bridge.${loanStage.toLowerCase()}.loan.loanUserMap.loanUserType`}
        editHistoryPath={`data.loan.loanUserMap[${borrowerInfo?.loanUserMapId}]`}
        otherData={{ borrowerInfoData }}
      />
      {(!isEvaluationPanelVisible || !isViewMode) &&
        loanStage !== LoanStage.fes && (
          <Grid xs={12} style={{ marginLeft: 16, marginTop: 15 }}>
            <Button
              variant="contained"
              data-testid="download-button"
              color="info"
              // color="default"
              // size="small"
              className={classes.deleteBtn}
              disabled={
                borrowerCount < 2 &&
                isEquivalentObjects(
                  borrowerInfo,
                  borrowerInfo?.loanUserMapId
                    ? {
                        ...getEmptyBorrower(loanType).payload,
                        ...{ loanUserMapId: borrowerInfo?.loanUserMapId }
                      }
                    : getEmptyBorrower(loanType).payload
                )
              }
              startIcon={
                <DeleteOutlineRoundedIcon
                  style={{ color: "#32325d", margin: 0 }}
                />
              }
              onClick={() => {
                handleDeleteData();
              }}
            />
          </Grid>
        )}
    </Grid>
  );
}
