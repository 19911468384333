/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/camelcase */
import { TableCell, Box, TableRow, Checkbox, Tooltip } from "@mui/material";
import React from "react";
import { ObjectType } from "./common";
import ColumnSearchFilter from "./ColumnSearchFilter";
import ColumnTypeFilter from "./ColumnTypeFilter";
import SortFilter from "./SortFilter";
import Arrow_expand from "../images/Arrow_expand.svg";
import Arrow_collapse from "../images/Arrow_collapse.svg";
import { AutoCompleteFilter } from "./autoCompleteFilter/AutoCompleteFilter";
import DateRangeFilter from "./DateRangeFilter";
import { ColumnLoanTypeFilterMasterView } from "./ColumnLoanTypeFilterMasterView";

interface TableHeaderProps {
  classes: any;
  columns: ObjectType[];
  gridTemplate: string;
  showCheckBox: boolean;
  selectAllRows: Function;
  allSelected: boolean;
  isCollapsed: boolean;
  showCollapseButton: boolean;
  collapsColumnIndex: number;
  expandColumnIndex: number;
  handleCheckBoxChange: Function;
  handleFilterToggle: Function;
  tableSearchConfig: any;
  filtersOptions: any;
  tableFilterConfig: any;
  filtersLoader: any;
  overRideStyles: any;
  handleSearchChange: Function;
  handleUpdateSelectedCheckboxes: Function;
  handleAutoCompleteSearch: Function;
  tableSortConfig: any;
  onSort: Function;
  onCollapseColumn: any;
  disableSelectAll: boolean;
  handleDateRangeFilter: Function;
  fewRowsSelected: boolean;
  selectAllTootltipText?: string;
  customTableRowStyle?: any
}

const TableHeaderSelect = React.memo((props: any) => {
  const {
    classes,
    selectAllRows,
    allSelected,
    disableSelectAll,
    fewRowsSelected,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectAllTootltipText
  } = props;

  return (
    <TableCell className={classes.stickyHeader}>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow
        }}
        placement="right"
        arrow
        title={selectAllTootltipText}
        disableHoverListener={!selectAllTootltipText || fewRowsSelected}
      >
        <div>
          {fewRowsSelected && !allSelected ? (
            <Checkbox
              checked={!allSelected}
              indeterminate={fewRowsSelected}
              onChange={e => {
                selectAllRows(false);
              }}
              className={classes.stickyHeader}
              classes={{
                indeterminate: classes.resolvedCheckboxColor,
                root: classes.checkboxBase
              }}
            />
          ) : (
            <Checkbox
              checked={allSelected}
              onChange={e => {
                selectAllRows(e.target.checked, true);
              }}
              className={classes.stickyHeader}
              classes={{
                checked: classes.resolvedCheckboxColor,
                root: classes.checkboxBase
              }}
              disabled={disableSelectAll}
            />
          )}
        </div>
      </Tooltip>
    </TableCell>
  );
});

const TableHeaderRow = React.memo((props: any) => {
  const {
    classes,
    columns,
    isCollapsed,
    showCollapseButton,
    collapsColumnIndex,
    expandColumnIndex,
    handleCheckBoxChange,
    handleFilterToggle,
    tableSearchConfig,
    filtersOptions,
    tableFilterConfig,
    filtersLoader,
    overRideStyles,
    handleDateRangeFilter,
    handleSearchChange,
    handleUpdateSelectedCheckboxes,
    handleAutoCompleteSearch,
    tableSortConfig,
    onSort,
    onCollapseColumn,
    showCheckBox
  } = props;
  let cumulativeWidth = 0;
  return columns.map((col: ObjectType, index: number) => {
    if (col?.frozen) {
      if (index === 0) {
        cumulativeWidth = showCheckBox ? 50 : 0;
      } else {
        cumulativeWidth += columns[index - 1].width;
      }
    }
    return (
      <TableCell
        component="div"
        key={col.id}
        className={`${col?.headClass ?? ""} ${
          col?.frozen ? classes.stickyHeader : ""
        }`}
        style={{
          ...col?.headStyle,
          ...(col?.frozen && {
            left: cumulativeWidth,
            zIndex: 1,
            boxShadow:
              (col?.expandBtn && isCollapsed) ||
              (showCollapseButton && collapsColumnIndex === index) ||
              ((isCollapsed || !showCollapseButton) &&
                expandColumnIndex === index)
                ? "5px 0px 5px -1px #dedede"
                : undefined
          }),
          width: col.width || "auto"
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span><b>{col.title}</b></span>
          <span>{col.subTitle}</span>
        </div>
        {(col?.filterList?.length ||
          col?.preFilterList?.length ||
          col.showDropDownFilter ||
          col.showSort ||
          col.showSearchFilter ||
          col.showAutoSearchFilter ||
          col.showDateRangeFilter ||
          showCollapseButton) && (
          <Box {...col.boxProps}>
            {col?.preFilterList?.map((filter: any) => filter.component)}
            {col.showDropDownFilter && (
              <ColumnTypeFilter
                columnName={col.tbField}
                filterTitle={col.title}
                handleCheckBoxChange={
                  col.handleCheckBoxChange || handleCheckBoxChange
                }
                handleFilterToggle={
                  col.handleFilterToggle || handleFilterToggle
                }
                isFrozen={col.frozen}
                options={col.filtersOptions || filtersOptions?.[col.tbField]}
                isLoading={col.optionsLoading || filtersLoader?.[col.tbField]}
                checked={col.checkedOptions || tableFilterConfig?.[col.tbField]}
                overRideStyles={overRideStyles}
                showSelectAll={col.showSelectAllOption}
                isFilterSearchRequired={col.isFilterSearchRequired}
              />
            )}
            {col.showSearchFilter && (
              <ColumnSearchFilter
                searchValue={
                  col.searchConfig || tableSearchConfig?.[col.tbField]
                }
                filterTitle={col.title}
                columnName={col.tbField}
                onSearch={handleSearchChange}
                overRideStyles={overRideStyles}
                isFrozen={col.frozen}
              />
            )}
            {col.showAutoSearchFilter && (
              <AutoCompleteFilter
                options={col.filtersOptions || filtersOptions?.[col.tbField]}
                isLoading={col.optionsLoading || filtersLoader?.[col.tbField]}
                columnName={col.tbField}
                columnLabel={col.title}
                checked={col.checkedOptions || tableFilterConfig?.[col.tbField]}
                handleAutoCompleteSearch={
                  col.handleAutoCompleteSearch || handleAutoCompleteSearch
                }
                handleUpdateSelectedCheckboxes={handleUpdateSelectedCheckboxes}
                searchConfig={col.autoSearchConfig}
                filterSelections={tableFilterConfig}
                isFrozen={col.frozen}
              />
            )}{" "}
            {col.showLoanTypeFilter && (
              <ColumnLoanTypeFilterMasterView
                handleFilterToggle={
                  col.handleFilterToggle || handleFilterToggle
                }
                isFrozen={col.frozen}
                isLoading={col.optionsLoading || filtersLoader?.[col.tbField]}
              />
            )}
            {col.showDateRangeFilter && (
              <DateRangeFilter
                filterTitle={col.title}
                columnName={col.tbField}
                handleDateRangeChange={handleDateRangeFilter}
                filterSelections={tableFilterConfig}
              />
            )}
            {col.showSort && (
              <SortFilter
                columnName={col.tbField}
                onSort={onSort}
                activeSortState={tableSortConfig?.[col.tbField]}
              />
            )}
            {showCollapseButton &&
              collapsColumnIndex === index &&
              !isCollapsed && (
                <img
                  src={Arrow_collapse}
                  style={{ position: "absolute" }}
                  className={`left-arrow ${classes.arrowLeft}`}
                  alt="Collapse"
                  onClick={onCollapseColumn}
                />
              )}
            {showCollapseButton &&
              expandColumnIndex === index &&
              isCollapsed && (
                <img
                  src={Arrow_expand}
                  style={{ position: "absolute" }}
                  className={`left-arrow ${classes.arrowLeft}`}
                  alt="Expand"
                  onClick={onCollapseColumn}
                />
              )}
            {col?.filterList?.map((filter: any) => filter.component)}
          </Box>
        )}
      </TableCell>
    );
  });
});

export const TableHeader = React.memo((props: TableHeaderProps) => {
  const {
    classes,
    columns,
    gridTemplate,
    showCheckBox,
    selectAllRows,
    allSelected,
    isCollapsed,
    showCollapseButton,
    collapsColumnIndex,
    expandColumnIndex,
    handleCheckBoxChange,
    handleFilterToggle,
    tableSearchConfig,
    filtersOptions,
    tableFilterConfig,
    filtersLoader,
    overRideStyles,
    handleDateRangeFilter,
    handleSearchChange,
    handleUpdateSelectedCheckboxes,
    handleAutoCompleteSearch,
    tableSortConfig,
    onSort,
    onCollapseColumn,
    disableSelectAll,
    fewRowsSelected,
    selectAllTootltipText,
    customTableRowStyle = {}
  } = props;

  return (
    <TableRow
      component="div"
      className={`${classes?.tableRowGrid} ${classes?.headerRowBackground}`}
      style={{
        gridTemplateColumns: `${gridTemplate}`,
        ...customTableRowStyle
      }}
    >
      {showCheckBox && (
        <TableHeaderSelect
          classes={classes}
          selectAllRows={selectAllRows}
          allSelected={allSelected}
          disableSelectAll={disableSelectAll}
          fewRowsSelected={fewRowsSelected}
          selectAllTootltipText={selectAllTootltipText}
        />
      )}
      <TableHeaderRow
        classes={classes}
        columns={columns}
        isCollapsed={isCollapsed}
        showCollapseButton={showCollapseButton}
        collapsColumnIndex={collapsColumnIndex}
        expandColumnIndex={expandColumnIndex}
        handleCheckBoxChange={handleCheckBoxChange}
        handleFilterToggle={handleFilterToggle}
        tableSearchConfig={tableSearchConfig}
        filtersOptions={filtersOptions}
        tableFilterConfig={tableFilterConfig}
        filtersLoader={filtersLoader}
        overRideStyles={overRideStyles}
        handleDateRangeFilter={handleDateRangeFilter}
        handleSearchChange={handleSearchChange}
        handleUpdateSelectedCheckboxes={handleUpdateSelectedCheckboxes}
        handleAutoCompleteSearch={handleAutoCompleteSearch}
        tableSortConfig={tableSortConfig}
        onSort={onSort}
        onCollapseColumn={onCollapseColumn}
        showCheckBox={showCheckBox}
      />
    </TableRow>
  );
});
