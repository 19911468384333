export enum RecourseEnum {
  fullRecourse = "Full Recourse",
  limitedRecourse = "Limited Recourse",
  nonRecourse = "Non-Recourse"
}
export enum LeaseStatusEnum {
  occupiedLongTerm = "Occupied / Long Term Rental",
  unOcupiedLongTerm = "Unoccupied / Long Term Rental",
  occupiedShortTerm = "Occupied / Short Term Rental",
  unOccupiedShortTerm = "Unoccupied / Short Term Rental"
}
export const borrowerType = ["Entity", "Individual"];
export enum LoanStructureTypes {
  multipleDraw = "Multiple Draws",
  singleDraw = "Single Draw (No Rehab Escrow)",
  singleFunding = "Single Funding (With Rehab Escrow)"
}
export const loanStructureDropDown = [
  LoanStructureTypes.multipleDraw,
  LoanStructureTypes.singleDraw,
  LoanStructureTypes.singleFunding
];
export enum ToorakProductEnum {
  Rehab = "Bridge",
  Rental = "2+1",
  GroundUp = "Ground-Up"
}
export const toorakProductDropDown = [
  ToorakProductEnum.Rehab,
  ToorakProductEnum.Rental,
  ToorakProductEnum.GroundUp
];

export enum FundingChannelEnum {
  tableFunding = "Table Funding",
  advanceFunding = "Advance Funding",
  warehouseFunding = "Warehouse Funding"
}
export const fundingChannelDropDown = [
  "Normal",
  FundingChannelEnum.tableFunding,
  FundingChannelEnum.advanceFunding,
  FundingChannelEnum.warehouseFunding
];

export const loanPurposeDropDown = ["Purchase", "Refinance"];

export const yOrNDropDown = ["Y", "N"];
export const thirtyYearToorakProduct = ["30 Year Rental"];
export const accuralTypeDropDown = ["Actual/360", "30/360"];

export const propertyTypeDropDown = [
  "SFR",
  "2-4 Unit",
  "5+ Unit Multifamily",
  "Condo",
  "Townhome",
  "Mixed Use"
];
export enum ValuationSource {
  APPRAISAL = "Appraisal",
  APPRAISAL_PENDING = "Appraisal Pending",
  Toorval = "Toorval"
}
export const rentalPropertyTypeDropDown = [
  "SFR",
  "2-4 Unit",
  "Condo",
  "Townhome"
];
export const oneFourFamilyPropertyTypeDropDown = [
  "SFR",
  "2-4 Unit",
  "Condo",
  "Townhome"
];
export const multiFamMixedUsePropertyTypeDropDown = [
  "5+ Unit Multifamily",
  "Mixed Use"
];
export const thirtyYearPropertyTypeDropDown = [
  "SFR",
  "2-4 Unit",
  "Condo",
  "Townhome",
  // "5+ Unit Multifamily"
];
// dont change the order in rentDescription.it will effect unit info component.
export const rentDescriptionOld = [
  "Occupied/Long Term Lease",
  "Unoccupied/Short Term Lease(Vacant for last 2 months)",
  "Occupied/Short Term Lease",
  "Unoccupied/Unleased"
];
// dont change the order in rentDescription.it will effect unit info component.
export const rentDescription = [
  LeaseStatusEnum.occupiedLongTerm,
  LeaseStatusEnum.unOcupiedLongTerm,
  LeaseStatusEnum.occupiedShortTerm,
  LeaseStatusEnum.unOccupiedShortTerm
];

export const thirtyYearLoanPurpose = [
  "Purchase",
  "Cash Out Refinance",
  "Rate/Term Refinance"
];

export const borrowerInformation = ["Experienced", "New"];

export const yesNoDropdown = ["Yes", "No"];

export const foreignNationalOptions = [
  "Yes",
  "No",
  "Permanent Resident Alien",
  "Non-Permanent Resident Alien"
];

export const multiFamilyRecourse = [
  RecourseEnum.fullRecourse,
  RecourseEnum.limitedRecourse,
  RecourseEnum.nonRecourse
];
export const recoursePrePost = [
  RecourseEnum.fullRecourse,
  RecourseEnum.nonRecourse
];

export const cashOutNot = ["Cash Out", "No Cash Out"];

export const prepaymentPenaltyTypeDropDown = [
  "5/5/5/5/5",
  "5/4/3/2/1",
  "1/1/1/1/1",
  "4/3/2/1",
  "1/1/1/1",
  "5/4/3",
  "3/2/1",
  "1/1/1",
  "2/1",
  "1/1",
  "1",
  "2",
  "0"
];
export const exitStrategy = [
  "Stabilized Bridge (No Rehab)",
  "Rehab to Hold/Rent",
  "Rehab to Flip",
  "Sale",
  "Condo Sale"
];

export const valuationSource = ["Appraisal", "Appraisal Pending"];

export const multiFamMixedUsevaluationSource = ["Appraisal", "Borrower"];

export const appraisalVendor = [
  "Accurate Group",
  "Appraisal Nation",
  "Clear Capital",
  "Service Link",
  "Not Approved Vendor"
];

export const propertyCondition = ["C1", "C2", "C3", "C4", "C5", "C6"];

export const propertyTypeThirtyYearFes = [
  "SFR",
  "2-4 Unit",
  "Condo",
  "Townhome",
  // "5+ Unit Multifamily"
];
export const predominantPropertyType = propertyTypeThirtyYearFes;

export const prepaymentPenalty = [60, 48, 36, 24, 12, 0];
export const rateType = ["Fixed", "Adjustable"];
export const armIndexTypes = ["1 Month Term SOFR", "Prime"];

export const thirdPartyValuationStatus = ["Ordered", "Pending"];

export const condoEligibilityDropDown = ["Standard", "Min", "Not Eligible", "N/A"];

export const condoEligibilityV2DropDown = [
  "Warrantable",
  "Non-Warrantable",
  "Not Eligible",
  "N/A"
];

export const condoEligibilityV2DropDownFes = [
  "Standard",
  "Min",
  "Not Eligible",
  "N/A"
];

export const condoEligibilityDropDownFes = [
  "Warrantable",
  "Non-Warrantable",
  "Not Eligible",
  "N/A"
];

export const crossCollaterlizationData = [
  {
    dataTestId: "radio-cross-yes",
    label: "Yes",
    value: "Y"
  },
  {
    dataTestId: "radio-cross-no",
    label: "No",
    value: "N"
  }
];

export const exitStrategyDropDown = [
  "Stabilized Bridge (No Rehab)",
  "Rehab to Hold/Rent",
  "Rehab to Flip",
  // "Condo Sale"
];

export const closingCostValues = [
  {
    "label": "0.0000%",
    "value": 0
  },
  {
    "label": "0.5000%",
    "value": 0.5
  },
  {
    "label": "1.0000%",
    "value": 1
  },
  {
    "label": "1.5000%",
    "value": 1.5
  },
  {
    "label": "2.0000%",
    "value": 2
  },
  {
    "label": "2.5000%",
    "value": 2.5
  },
  {
    "label": "3.0000%",
    "value": 3
  },
  {
    "label": "3.5000%",
    "value": 3.5
  },
  {
    "label": "4.0000%",
    "value": 4
  },
  {
    "label": "4.5000%",
    "value": 4.5
  },
  {
    "label": "5.0000%",
    "value": 5
  },
  {
    "label": "5.5000%",
    "value": 5.5
  },
  {
    "label": "6.0000%",
    "value": 6
  },
  {
    "label": "6.5000%",
    "value": 6.5
  },
  {
    "label": "7.0000%",
    "value": 7
  },
  {
    "label": "7.5000%",
    "value": 7.5
  },
  {
    "label": "8.0000%",
    "value": 8
  },
  {
    "label": "8.5000%",
    "value": 8.5
  },
  {
    "label": "9.0000%",
    "value": 9
  },
  {
    "label": "9.5000%",
    "value": 9.5
  },
  {
    "label": "10.0000%",
    "value": 10
  }
];

export const interestRateValues = [{
  label: "5.500%",
  value: 5.5
}, {
  label: "5.625%",
  value: 5.625
}, {
  label: "5.750%",
  value: 5.75
}, {
  label: "5.875%",
  value: 5.875
}, {
  label: "6.000%",
  value: 6
}, {
  label: "6.125%",
  value: 6.125
}, {
  label: "6.250%",
  value: 6.25
}, {
  label: "6.375%",
  value: 6.375
}, {
  label: "6.500%",
  value: 6.5
}, {
  label: "6.625%",
  value: 6.625
}, {
  label: "6.750%",
  value: 6.75
}, {
  label: "6.875%",
  value: 6.875
}, {
  label: "7.000%",
  value: 7
}, {
  label: "7.125%",
  value: 7.125
}, {
  label: "7.250%",
  value: 7.25
}, {
  label: "7.375%",
  value: 7.375
}, {
  label: "7.500%",
  value: 7.5
}, {
  label: "7.625%",
  value: 7.625
}, {
  label: "7.750%",
  value: 7.75
}, {
  label: "7.875%",
  value: 7.875
}, {
  label: "8.000%",
  value: 8
}, {
  label: "8.125%",
  value: 8.125
}, {
  label: "8.250%",
  value: 8.25
}, {
  label: "8.375%",
  value: 8.375
}, {
  label: "8.500%",
  value: 8.5
}, {
  label: "8.625%",
  value: 8.625
}, {
  label: "8.750%",
  value: 8.75
}, {
  label: "8.875%",
  value: 8.875
}, {
  label: "9.000%",
  value: 9
}, {
  label: "9.125%",
  value: 9.125
}, {
  label: "9.250%",
  value: 9.25
}, {
  label: "9.375%",
  value: 9.375
}, {
  label: "9.500%",
  value: 9.5
}, {
  label: "9.625%",
  value: 9.625
}, {
  label: "9.750%",
  value: 9.75
}, {
  label: "9.875%",
  value: 9.875
}, {
  label: "10.000%",
  value: 10
}, {
  label: "10.125%",
  value: 10.125
}, {
  label: "10.250%",
  value: 10.25
}, {
  label: "10.375%",
  value: 10.375
}, {
  label: "10.500%",
  value: 10.5
}, {
  label: "10.625%",
  value: 10.625
}, {
  label: "10.750%",
  value: 10.75
}, {
  label: "10.875%",
  value: 10.875
}, {
  label: "11.000%",
  value: 11
}, {
  label: "11.125%",
  value: 11.125
}, {
  label: "11.250%",
  value: 11.25
}, {
  label: "11.375%",
  value: 11.375
}, {
  label: "11.500%",
  value: 11.5
}, {
  label: "11.625%",
  value: 11.625
}, {
  label: "11.750%",
  value: 11.75
}, {
  label: "11.875%",
  value: 11.8755
},
{
  label: "12.000%",
  value: 12
}, {
  label: "12.125%",
  value: 12.125
}, {
  label: "12.250%",
  value: 12.25
}, {
  label: "12.375%",
  value: 12.375
}, {
  label: "12.500%",
  value: 12.5
}, {
  label: "12.625%",
  value: 12.625
}, {
  label: "12.750%",
  value: 12.75
}, {
  label: "12.875%",
  value: 12.875
}, {
  label: "13.000%",
  value: 13
}, {
  label: "13.125%",
  value: 13.125
}, {
  label: "13.250%",
  value: 13.25
}, {
  label: "13.375%",
  value: 13.375
}, {
  label: "13.500%",
  value: 13.5
}, {
  label: "13.625%",
  value: 13.625
}, {
  label: "13.750%",
  value: 13.75
}, {
  label: "13.875%",
  value: 13.875
},
{
  label: "14.000%",
  value: 14
}, {
  label: "14.125%",
  value: 14.125
}, {
  label: "14.250%",
  value: 14.25
}, {
  label: "14.375%",
  value: 14.375
}, {
  label: "14.500%",
  value: 14.5
}, {
  label: "14.625%",
  value: 14.625
}, {
  label: "14.750%",
  value: 14.75
}, {
  label: "14.875%",
  value: 14.875
},
{
  label: "15.000%",
  value: 15
},
];

type PropertyDetailsRepresentable = {
  [key: string]: string;
};

export const propertyTypeObject: PropertyDetailsRepresentable = propertyTypeDropDown.reduce(
  (previousValue: any, currentValue: any) => ({
    ...previousValue,
    [currentValue]:
      currentValue === "SFR" ? "Single Family Detached" : currentValue
  }),
  {}
);

export const gUpFESExitStrategyOptions = [
  "Build to Flip/Sell",
  "Build to Rent"
];

export const gUpFESLoanStructure = [
  LoanStructureTypes.multipleDraw,
  LoanStructureTypes.singleFunding
];

export const gUpLoanTerm = [
  { label: "12 months", value: 12 },
  { label: "13 months", value: 13 },
  { label: "14 months", value: 14 },
  { label: "15 months", value: 15 },
  { label: "16 months", value: 16 },
  { label: "17 months", value: 17 },
  { label: "18 months", value: 18 },
  { label: "19 months", value: 19 },
  { label: "20 months", value: 20 },
  { label: "21 months", value: 21 },
  { label: "22 months", value: 22 },
  { label: "23 months", value: 23 },
  { label: "24 months", value: 24 }
];

export const irFundingSources = [
  "None",
  "3 Months Out of Pocket",
  "6 Months Out of Pocket",
  "3 Months Funded via Loan",
  "6 Months Funded via Loan",
  "9 Months Funded via Loan",
  "Funded via Loan"
];

export const USStates = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "FM",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MH",
  "MI",
  "MN",
  "MO",
  "MP",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "PW",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY"
];

export const riskCategoryList = ["A", "B", "C", "D", "E", "NA"]
