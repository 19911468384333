/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  // Tabs,
  // Tab,
  IconButton,
  Avatar
} from "@mui/material";

import { useDispatch } from "react-redux";

import { push } from "connected-react-router";

import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { getCookie, setCookie } from "../../utils/cookies";
import closeIcon from "../../images/close.svg";
import signoutIcon from "../../images/signout.svg";
import menuIcon from "../../images/menu.svg";
import {
  // deselectActiveBundle,
  removeSearched
  // setTabView
} from "../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
// import { updateSlectedFilters } from "../create-loan.action";
import {
  isAssetManagement,
  isPrivilege,
  isRole,
  isILP,
  isLATUser
} from "../../utils/AccessManagement";
import {
  AssetManagementRolesEnum,
  DrawRolesEnum,
  InternalRolesEnum
} from "../../utils/constants";
import { setTabView } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";

export const hamBurgRef = () => { };
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: "#fff",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(14),
      marginRight: "35px",
      "&:focus": {
        opacity: 1
      },
      "&:disbaled": {
        color: "#fff"
      }
    },
    tabHeading: {
      fontSize: "14px !important"
    },
    paperHeader: {
      // flexDirection: "row",
      // height: "60px",
      backgroundImage: "linear-gradient(93deg, #0fcbef 4%, #1071ee 90%)",
      // backgroundImage: "linear-gradient(90deg, #014DB0 55%, #009BC9 90%)",
      boxShadow: "none",
      // paddingTop: theme.spacing(1),
      // paddingRight: theme.spacing(3),
      // paddingLeft: theme.spacing(3),
      paddingRight: 20,
      paddingLeft: 20,
      // paddingBottom: "3px"
      position: "fixed",
      zIndex: 2
    },
    wrapper: {
      display: "flex"
      // margin: "auto"
    },
    logo: {
      // paddingTop: theme.spacing(1)
      [theme.breakpoints.down("md")]: {
        maxWidth: "20%",
        flexBasis: "20%"
      }
    },
    imgLogo: {
      width: "212px"
    },
    menuTabContainer: {
      [theme.breakpoints.down("md")]: {
        maxWidth: "80%",
        flexBasis: "80%"
      }
    },
    userInfo: {
      // alignSelf: "center",
      // paddingTop: "2px",
      display: "flex"
    },
    imgCapitalContainer: {
      textAlign: "right",
      paddingRight: "7px",
      whiteSpace: "nowrap",
      margin: "auto",
      [theme.breakpoints.down("xs")]: {
        visibility: "hidden"
      }
    },
    imgCapital: {
      width: "36px",
      height: "20px"
    },
    notifyIconContainer: {
      margin: "auto !important",
      textAlign: "center"
    },
    profileImage: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      background: "#512DA8",
      fontSize: "20px",
      color: "#fff",
      textAlign: "center",
      lineHeight: "40px"
    },
    icon: {
      color: "white",
      margin: "9px",
      height: "23px",
      position: "relative",
      cursor: "pointer"
    },
    imgUser: {
      textAlign: "right",
      margin: "auto"
    },
    indicatorHeader: {
      height: "4px",
      borderRadius: "30px",
      // maxWidth: "32px",
      backgroundColor: "#ffffff"
      // marginLeft: "40px"
    },
    customWidth: {
      width: "277px",
      maxHeight: "581px",
      borderRadius: "4px",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      position: "absolute",
      top: "32px",
      zIndex: 3,
      right: "15px"
    },
    hamburgerMenu: {
      textAlign: "right",
      margin: "auto"
    },
    overlayBG: {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      position: "fixed",
      left: "0",
      top: "0",
      right: "0",
      height: "100%",
      width: "100%",
      zIndex: 2
    },
    hambergerMenuList: {
      backgroundColor: "#fff",
      borderLeft: "1px solid #e2e4f1",
      position: "fixed",
      height: "100%",
      right: "0",
      top: "0",
      width: "275px",
      zIndex: 3
    },
    show: {
      display: "block"
    },
    userInfoBox: {
      borderBottom: "1px solid #e2e4f1",
      color: "#32325d",
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      padding: "25px",
      width: "100%",
      "& .MuiListItem-gutters": {
        borderBottom: "0",
        padding: "0 !Important"
      }
    },
    userImage: {
      marginRight: "10px",
      width: "40px"
    },
    userDetail: {
      textAlign: "left",
      width: "calc(100% - 40px)"
    },
    userName: {
      color: "#32325d",
      fontSize: "14px",
      fontWeight: 600,
      margin: "0"
    },
    eMail: {
      fontSize: "11px"
    },
    mainMenuListContainer: {
      borderBottom: "1px solid #e2e4f1",
      padding: "0 0 10px 0",
      listStyle: "none",
      textAlign: "left",
      marginBottom: "15px",
      maxHeight: "70vh",
      overflowY: "auto"
    },
    mainMenuItem: {
      color: "#32325d",
      cursor: "pointer",
      fontSize: "14px",
      textTransform: "uppercase",
      padding: "20px 25px 20px 25px",
      fontWeight: 600
    },
    profileMenuListContainer: {
      borderBottom: "1px solid #e2e4f1",
      padding: "0 0 10px 0",
      listStyle: "none",
      textAlign: "left",
      marginBottom: "15px"
    },
    profileMenuItem: {
      color: "#32325d",
      cursor: "pointer",
      fontSize: "12px",
      padding: "15px 25px 15px 25px"
    },
    menuLogo: {
      position: "absolute",
      bottom: "0",
      padding: "10px",
      textAlign: "center",
      width: "100%"
    },
    menuImgCapital: {
      height: "35px",
      width: "75px"
    },
    menuClose: {
      position: "absolute",
      color: "000",
      right: "0",
      top: "0"
    },
    iconImg: {
      display: "inline-block",
      verticalAlign: "middle",
      height: "18px",
      margin: "0 5px 2px 0",
      width: "18px"
    }
    // diableMenu: {
    //   cursor: "not-allowed"
    // }
  })
);

export const HamBurger = (props: any) => {
  const token = getCookie("id_token") || "";
  const navigate = useNavigate();
  const { showTab } = props;
  const { name, email, roles } = JSON.parse(
    atob(token.split(".")[1])
  );
  const classes = useStyles();
  const firstName = getCookie("firstName") || "";
  const lastName = getCookie("lastName") || "";
  let intials = "";
  if (firstName !== null && firstName !== "null" && firstName !== "") {
    intials += firstName.charAt(0);
  }
  if (lastName !== null && lastName !== "null" && lastName !== "") {
    intials += lastName.charAt(0);
  }
  const dispatch = useDispatch();
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [showMenu, setShowMenu] = React.useState(false);
  const useMenuStyles = makeStyles((theme) => ({
    userDetailList: {
      border: "0",
      borderBottom: "1px solid #e2e4f1",
      backgroundColor: "transparent"
    },
    logoutContainer: {
      borderTop: "1px solid #e2e4f1"
    }
  }));
  const classesStyle = useMenuStyles();

  const showHamburgerMenu = () => {
    setShowMenu(true);
  };

  const hideHamburgerMenu = () => {
    setShowMenu(false);
  };
  const clearFilters = () => {
    // dispatch(updateSlectedFilters({}));
    setCookie("globalSearchValue", "");
  };
  const routingActions = (tabNumber: number) => {
    if (!isILP() && !isAssetManagement()) {
      if (tabNumber === 2) {
        removeSearched(dispatch);
        // dispatch(deselectActiveBundle());
      }
      navigate(`/loans/list?tab=${tabNumber}`);
    } else if (isAssetManagement()) {
      if (tabNumber === 14) {
        navigate(`/asset/home/counsel/loans?tab=${tabNumber}`);
      }else{
        navigate(`/asset/home?tab=${tabNumber}`);
      }
      
    } else {
      navigate(`/internal/loans/list?tab=${tabNumber}`);
    }
    dispatch(setTabView(tabNumber));
    setShowMenu(false);
    clearFilters();
  };
  const logOutClick = () => {
    // setAnchorEl(null);
    localStorage.removeItem("customizedColumn");
    sessionStorage.removeItem("excelUpload");
    navigate("/logout");
  };

  const isDrawAMRole = () => {
    return (
      isRole(DrawRolesEnum.DRAW_MEMBER) || isRole(DrawRolesEnum.DRAW_MANAGER)
    );
  };

  const roleName =
    roles && roles?.filter((item: any) => item === "LAT_BULK_UPLOAD");
  return (
    <Box
      display={{ xs: "flex", sm: "flex", md: "flex", lg: "none" }}
      style={{ justifyContent: "flex-end", height: 46 }}
    >
      {/* <section className="dummy" ref={wrapperRefHamburgererRef}> */}
      <IconButton
        aria-label="Open Menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={showHamburgerMenu}
        color="inherit"
      >
        <img src={menuIcon} alt="menu-icon" />
      </IconButton>
      {showMenu ? (
        <>
          <div className={`${classes.overlayBG}`} />
          <div className={`${classes.hambergerMenuList}`}>
            <IconButton
              aria-label="Close Menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={hideHamburgerMenu}
              color="inherit"
              className={classes.menuClose}
            >
              <img src={closeIcon} alt="close-icon" />
            </IconButton>
            <div className={classes.userInfoBox}>
              {/* <div className={classes.userImage}><img src={user} alt="user" /> </div>
            <div className={classes.userDetail}>
              <h4 className={classes.userName}>John Doe</h4>
              <span className={classes.eMail}>johandoe@company.com</span>
            </div> */}
              <ListItem className={classesStyle.userDetailList}>
                <ListItemAvatar>
                  <Avatar>
                    <div id="profileImage" className={classes.profileImage}>
                      {intials.toUpperCase()}
                    </div>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} secondary={email} />
              </ListItem>
            </div>
            <ul className={classes.mainMenuListContainer}>
              {!isILP() && !isAssetManagement() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(0);
                  }}
                >
                  Dashboard
                </li>
              )}
              {!isILP() && !isAssetManagement() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(1);
                  }}
                >
                  Loans
                </li>
              )}
              {!isILP() && !isAssetManagement() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(2);
                  }}
                >
                  Purchase & Settlement
                </li>
              )}
              {!isILP() && !isAssetManagement() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(3);
                  }}
                >
                  Documents
                </li>
              )}
              {!isILP() && !isAssetManagement() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(4);
                  }}
                >
                  Scenario
                </li>
              )}
              {!isILP() && !isAssetManagement() && showTab !== false && (
                <li
                  className={`${classes.mainMenuItem}`}
                  onClick={() => {
                    routingActions(5);
                  }}
                >
                  Appraiser Search
                </li>
              )}
              {isRole("APPRAISER") && (
                <li
                  className={`${classes.mainMenuItem}`}
                  onClick={() => {
                    routingActions(9);
                  }}
                >
                  Toorval Appraisal
                </li>
              )}
              {!isILP() &&
                !isAssetManagement() &&
                isPrivilege("publish_pricing") && (
                  <li
                    className={`${classes.mainMenuItem}`}
                    onClick={() => {
                      routingActions(12);
                    }}
                  >
                    Publish Pricing
                  </li>
                )}
              {!isILP() &&
                isLATUser() &&
                !isAssetManagement() &&
                isPrivilege("takeout_partner") && (
                  <li
                    className={`${classes.mainMenuItem}`}
                    onClick={() => {
                      routingActions(13);
                    }}
                  >
                    Take-Out Partners
                  </li>
                )}
              {(isRole(DrawRolesEnum.ORIGINATOR_DRAW) ||
                isRole(DrawRolesEnum.ORIGINATOR_VIEW_DRAW)) &&
                showTab !== false && (
                  <li
                    className={`${classes.mainMenuItem}`}
                    onClick={() => {
                      routingActions(8);
                    }}
                  >
                    Draw Request
                  </li>
                )}
              {!isILP() &&
                !isAssetManagement() &&
                showTab !== false &&
                roleName &&
                roleName[0] === "LAT_BULK_UPLOAD" && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(6);
                    }}
                  >
                    Bulk Evaluation
                  </li>
                )}
              {!isILP() &&
                !isAssetManagement() &&
                showTab !== false &&
                isRole(InternalRolesEnum.LAT_ADMIN) &&
                (window.location.hostname === "qa.toorakcapital.info" ||
                  window.location.hostname === "localhost") && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(6);
                    }}
                  >
                    Docs Index Self Serve
                  </li>
                )}
              {isILP() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(0);
                  }}
                >
                  Loans
                </li>
              )}
              {/* {isILP() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(1);
                  }}
                >
                  Communication
                </li>
              )} */}
              {/* {isILP() &&
                !isRole(InternalRolesEnum.LAT_ADMIN) &&
                (isRole(InternalRolesEnum.LAT_APPROVER) ||
                  isRole(InternalRolesEnum.LAT_INQUIRY)) &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(2);
                    }}
                  >
                    Action
                  </li>
                )} */}
              {/* {isILP() &&
                isRole(InternalRolesEnum.LAT_ADMIN) &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(2);
                    }}
                  >
                    Approver Action
                  </li>
                )}
              {isILP() &&
                isRole(InternalRolesEnum.LAT_ADMIN) &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(3);
                    }}
                  >
                    Inquiry Action
                  </li>
                )} */}
              {/* {isILP() &&
                isPrivilege("view_cs_report") &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(4);
                    }}
                  >
                    Low CS Report
                  </li>
                )} */}
              {isILP() &&
                showTab !== false &&
                (isRole(InternalRolesEnum.LAT_APPROVER) ||
                  isRole(InternalRolesEnum.LAT_ADMIN)) && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(2);
                    }}
                  >
                    Approver Dashboard
                  </li>
                )}
              {isILP() &&
                isPrivilege("takeout_partner") &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(6);
                    }}
                  >
                    Take-Out Partners
                  </li>
                )}
              {/* {isILP() &&
                showTab !== false &&
                isRole(InternalRolesEnum.LAT_BD) && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(5);
                    }}
                  >
                    Pricing Grid
                  </li>
                )} */}
              {/* {isAssetManagement() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(1);
                  }}
                >
                  Draw Request
                </li>
              )} */}
              {/* {isAssetManagement() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(2);
                  }}
                >
                  Securitisation
                </li>
              )} */}
              {isAssetManagement() &&
                (isDrawAMRole() || isPrivilege("view_asset_management")) &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(0);
                    }}
                  >
                    Draw Request
                  </li>
                )}
              {isAssetManagement() &&
                (isRole(AssetManagementRolesEnum.AM_SO) ||
                  isPrivilege("view_asset_management")) &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(1);
                    }}
                  >
                    Servicer Onboarding
                  </li>
                )}
              {isAssetManagement() &&
                (isDrawAMRole() || isPrivilege("view_asset_management")) &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(2);
                    }}
                  >
                    Purchased Loans
                  </li>
                )}
                {isAssetManagement() &&
                showTab !== false && (
                  <li
                    className={classes.mainMenuItem}
                    onClick={() => {
                      routingActions(14);
                    }}
                  >
                    Counsel Data
                  </li>
                )}
              {/* {(isAssetManagement() || isDrawAMRole()) && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(2);
                  }}
                >
                  Real Insights
                </li>
              )} */}
              {/* {isAssetManagement() && showTab !== false && (
                <li
                  className={classes.mainMenuItem}
                  onClick={() => {
                    routingActions(3);
                  }}
                >
                  Communication
                </li>
              )} */}
            </ul>
            <ul className={classes.profileMenuListContainer}>
              {/* <li className={classes.profileMenuItem}><img src={profileIcon} alt="close-icon" className={classes.iconImg} />My Account</li>
            <li className={classes.profileMenuItem}><img src={signoutIcon} alt="close-icon" className={classes.iconImg}/>Dark Theme</li> */}
              <li className={classes.profileMenuItem} onClick={logOutClick}>
                <img
                  src={signoutIcon}
                  alt="close-icon"
                  className={classes.iconImg}
                />
                Signout
              </li>
            </ul>
            {/* <div className={classes.menuLogo}>
              {getCookie("organizationName") !== null &&
                getCookie("organizationName") !== undefined
                ? getCookie("organizationName")
                : ""}
            </div> */}
          </div>
        </>
      ) : (
        ""
      )}
      {/* </section> */}
    </Box>
  );
};