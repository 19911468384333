import React from "react";
import CounselApp from "@toorak/tc-counsel-web-app";
import { Header } from "../create-loan/home/Header";
import { publicClient } from "@toorak/tc-common-fe-sdk";
import { isRole } from "../utils/AccessManagement";

function CounselWrapper() {
  const isAssetManager = isRole("COUNSEL_ADMIN");
  let basePath = isAssetManager ? "/asset/home/counsel" : "/counsel"

  return (
    <div>
      {!isAssetManager ? (
        <Header
          value={0}
          setTabValue={0}
          showTab={false}
          showNotificationIcon={true}
        />
      ) : null}

      <CounselApp publicClient={publicClient} basePath={basePath} />
    </div>
  );
}

export default CounselWrapper;
