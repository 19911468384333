/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, MenuItem, Theme } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import makeStyles from "@mui/styles/makeStyles";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import {
  ConfirmationDialog,
  ConfirmFormModal,
  LoanStage,
  UserRole,
  WarningModal,
  WarningModalProps
} from "@toorak/tc-common-fe-sdk";

import {
  Roles,
  getExcelDataInSession,
  isRole,
  isILP,
  isLoanOnHold,
  checkIfHasRole,
  isOrigUser
} from "../../utils/AccessManagement";
import iconSettings from "../../images/icon_settings.svg";
import useOutsideClick from "../../custom-hooks/useOutsideClick";
import {
  resetAllStores,
  deleteLoan,
  exportLoanToExcel,
  clickAndDownloadFile,
  dispatchAndUpdate
  // setLoanTableFunding,
  // updateLoanLevelData,
  // setWarehouseFunding
} from "../create-loan.action";
import {
  showLoader,
  hideLoader
  // clearLoader
} from "../../stores/loaderAndException/loaderAndException.action";
// import {
//   resetExcelUploadReducer,
//   setDeleteLoan
// } from "../../stores/ExcelUpload/excel-upload.action";
// import { resetRetryRequestReducer } from "../../stores/RetryRequest/retry-request.action";
import { getLoanType } from "../../config/config";
import { getCookie } from "../../utils/cookies";
import {
  updateILPState,
  getNextActionsFromCamunda,
  updateTTFState
} from "../../stores/ILP/ILP.action";
import { RootState } from "../../stores/rootReducer";
import {
  attachScenarioToLoans,
  getScenarioAttachedToLoan,
  getScenarioListFromElasticSearch,
  updateScenarioDetailsListInRedux
} from "../../stores/Scenario/ScenarioActions";
import { AttachScenarioDialog } from "../scenario/AttachScenarioDialog";
import { isNullOrUndefined } from "../../utils/formatChecks";
import {
  ApproverActionsEnum,
  InternalDDStatusEnum,
  InternalRolesEnum,
  InternalStatusEnum,
  IRToPIStates,
  ResponseTypeEnum,
  tapeToFileStatusEnum,
  TABLE_FUNDING,
  postApprovalStates,
  ConditionStatusDisplayValues
} from "../../utils/constants";

import {
  AddOnHoldDialog,
  onHoldEnumValue
} from "../on-hold-loan/AddOnHoldDialog";
import { ViewOnHoldDialog } from "../on-hold-loan/ViewOnHoldDialog";
import { useHoldLandMark } from "../on-hold-loan/useHoldLandMark";
import {
  updateLoanTableFunding,
  updateWarehouseFunding
} from "../../network/apiService";
import {
  // checkAppraisalStatusApproveConditionally,
  reviewAppraisalForm
} from "../../stores/appraisal/appraisal.action";
import { AppraisalStatus } from "../appraisal/util";
import { clearLoader } from "../../stores/loaderAndException/loaderAndException.reducer";
import { resetRetryRequestReducer } from "../../stores/RetryRequest/retry-request.reducer";
import {
  resetExcelUploadReducer,
  toggleDeleteLoan
} from "../../stores/ExcelUpload/excel-upload.reducer";

import {
  setLoanTableFunding,
  // setLoanTableFunding,
  setWarehouseFunding,
  updateLoanLevelData
} from "../create-loan.reducer";
import { checkAppraisalStatusApproveConditionally } from "../../stores/appraisal/appraisal.reducer";
import { TermSheetWrapper } from "../../ui-components/TermsheetWrapper";
import { useShowTermsheet } from "../../v2/hooks/useShowTermsheet";

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    cursor: "pointer",
    fontSize: 12,
    color: "#32325d",
    paddingBottom: 6,
    lineHeight: 2,
    fontWeight: 600
  }
}));

export const loanStageToReadable: {
  [key: string]: string;
} = {
  PRE: "Pre Close",
  POST: "Post Close",
  FES: "GuideLine Evaluation",
  TOORVAL: "Toorval"
};
export const loanTypeToReadable: {
  [key: string]: string;
} = {
  [getLoanType[0].displayValue]: getLoanType[0].name,
  [getLoanType[1].displayValue]: getLoanType[1].name,
  [getLoanType[2].displayValue]: getLoanType[2].name
};

/** This component displays Action Button */
export const ActionDropDown = (props: {
  StatusUpdate?: any;
  setShowLoanSummary?: any;
  isEvaluationPanelVisible: boolean;
  loanState: LoanStatusEnum;
  loanStage: LoanStage;
  loanId: string;
  loanType: string;
  isViewMode?: boolean;
  isWithdrawnLoan?: boolean;
  isTTFTab?: boolean;
  availableLoanStages: LoanStage[];
  workflowState?: string;
  tapeToFileStatus?: string;
  assignedTo?: string;
  roleOfAssignee?: string;
  qcRequired?: boolean;
  loanProcessType?: any;
  activeTab?: string;
  handleAddBulkAction?: any;
  setOpenLoanDetlNew?: (arg0: boolean) => void;
  setOpenConfirmCustody?: (arg0: boolean) => void;
  originatorId?: string;
  // setModal?: (arg: string) => void;
  selectedInquiries?: any;
}) => {
  const {
    StatusUpdate,
    isEvaluationPanelVisible,
    loanState,
    loanStage,
    loanId,
    loanType,
    isViewMode,
    isWithdrawnLoan,
    availableLoanStages,
    workflowState,
    tapeToFileStatus,
    isTTFTab,
    assignedTo,
    roleOfAssignee,
    qcRequired,
    // loanProcessType,
    handleAddBulkAction,
    // setShowLoanSummary,
    activeTab,
    setOpenLoanDetlNew,
    setOpenConfirmCustody,
    originatorId,
    // setModal,
    selectedInquiries
  } = props;
  const classes = useStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate = useNavigate();
  const [dialogdetails, setDialogdetails] = React.useState<any>({});
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [scenarioIDArr, setScenarioIDArr] = useState<any[]>([]);
  const [isOpenHoldDialog, setIsOpenHoldDialog] = useState<boolean>(false);
  const [isRemoveHoldDialog, setIsRemoveHoldDialog] = useState<boolean>(false);
  const [holdAction, setHoldAction] = useState<string>("HISTORY");
  const [showTableFundedDialog, setShowTableFundedDialog] =
    useState<boolean>(false);
  const [showWarehouseFundingDialog, setShowWarehouseFundingDialog] =
    useState<boolean>(false);
  const [showTermSheet, setShowTermSheet] = useState<boolean>(false);

  let inquiries = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.exceptionsResult?.exceptions
  );
  const chatLines = useSelector<RootState, any>(
    (state) => state.exceptionsResultsStore?.exceptionsResult?.chatLines ?? []
  );

  const onHoldBy = useSelector<RootState, any>(
    (state) => state.createLoanStore?.loanDetails?.onHoldBy
  );

  const ttfSubmissionState = useSelector<RootState, any>(
    (state) => state.createLoanStore?.loanDetails?.isTapeToFileSubmitted
  );

  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  const bundleId = useSelector<RootState, any>(
    (state) =>
      state.spStore.loanBundleDetails?.loanbundleData?.bundleDetails?.bundleId
  );

  const loanFundingType = useSelector<RootState, any>(
    (state) => state.createLoanStore?.loanDetails?.loanDetailId?.fundingType
  );
  const isWarehouseFunded = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails?.loanDetailId?.isWarehouseFunded
  );

  inquiries = useMemo(() => {
    return inquiries instanceof Array && inquiries.length
      ? inquiries.filter(
          (item) => item.type.toLowerCase() === "inquiry" && !item.hold
        )
      : [];
  }, [inquiries]);
  const grade = useSelector<RootState, any>((state) => state.ILPStore?.grade);
  const dispatch = useDispatch();
  const [isMoreActionsVisible, setIsMoreActionsVisible] = useState(false);
  const [scenarioCount, setScenarioCount] = useState(0);
  const [openWarning, setOpenWarning] = useState<WarningModalProps | null>(
    null
  );
  const showTermsheet = useShowTermsheet();

  const ref = useRef<HTMLDivElement>(null);

  const {
    DataEntry,
    InitialReview,
    Submit,
    ExcelError,
    ExcelSuccess,
    DueDiligenceReview,
    FinalReview,
    Approved,
    PurchaseInitiated
    // Purchased
  } = LoanStatusEnum;
  const [nextStates, setNextStates] = useState<any[]>([]);
  const [ttfSubmitState, setTTFSubmitState] = useState(false);
  const [nextTTFStates, setNextTTFStates] = useState<any[]>([]);
  useEffect(() => {
    if (
      isILP() &&
      activeTab === "shadowDiligence" &&
      (isRole(InternalRolesEnum.LAT_APPROVER) ||
        isRole(InternalRolesEnum.LAT_ADMIN)) &&
      InternalDDStatusEnum.ReviewInProgress === workflowState
    ) {
      setNextStates(["Update Condition Status", "Update Action"]);
    } else if (isILP() && workflowState !== null && workflowState) {
      if (
        !(
          InternalDDStatusEnum.IRCompleted === workflowState ||
          InternalDDStatusEnum.QCCompleted === workflowState
        )
      ) {
        dispatch(
          getNextActionsFromCamunda(loanId, workflowState, setNextSteps)
        );
      } else {
        setNextStates([]);
      }
    }
  }, [loanId, workflowState]);

  useEffect(() => {
    ttfSubmissionState ? setTTFSubmitState(true) : setTTFSubmitState(false);
  }, [ttfSubmissionState]);

  useEffect(() => {
    if (isILP() && tapeToFileStatus !== null && tapeToFileStatus) {
      if (!(tapeToFileStatus === tapeToFileStatusEnum.Completed)) {
        dispatch(
          getNextActionsFromCamunda(loanId, tapeToFileStatus, setNextTTFSteps)
        );
      } else {
        setNextTTFStates([]);
      }
    } else {
      setNextTTFStates([
        tapeToFileStatusEnum.InProgress,
        tapeToFileStatusEnum.Completed
      ]);
    }
  }, [loanId, tapeToFileStatus]);

  const holdLoanLandMark: any = useHoldLandMark();

  const setNextTTFSteps = (response: any) => {
    if (response?.data?.length) {
      setNextTTFStates(response.data);
    } else if (tapeToFileStatus === tapeToFileStatusEnum.InProgress) {
      setNextTTFStates([tapeToFileStatusEnum.Completed]);
    } else {
      setNextTTFStates([
        tapeToFileStatusEnum.InProgress,
        tapeToFileStatusEnum.Completed
      ]);
    }
  };

  const setNextSteps = (response: any) => {
    if (response?.data?.length) {
      const filteredList = response.data.filter((val: string) => {
        if (val.includes("IR")) {
          if (val === InternalDDStatusEnum.IRPending) {
            return (
              isRole(InternalRolesEnum.LAT_ADMIN) &&
              !workflowState?.includes("UnAssigned")
            );
          }
          if (val === InternalDDStatusEnum.IRHold) {
            return false;
          }
          return (
            getCookie("person_id") === assignedTo ||
            (isRole(InternalRolesEnum.LAT_ADMIN) &&
              !workflowState?.includes("UnAssigned"))
          );
        }
        if (val.includes("QC")) {
          if (val === InternalDDStatusEnum.QCPending) {
            return (
              isRole(InternalRolesEnum.LAT_ADMIN) &&
              !workflowState?.includes("UnAssigned") &&
              workflowState !== InternalDDStatusEnum.IRCompleted
            );
          }
          if (val === InternalDDStatusEnum.QCHold) {
            return false;
          }
          return (
            (isRole(InternalRolesEnum.LAT_QC) &&
              getCookie("person_id") === assignedTo) ||
            (isRole(InternalRolesEnum.LAT_ADMIN) &&
              !workflowState?.includes("UnAssigned") &&
              workflowState !== InternalDDStatusEnum.IRCompleted)
          );
        }
        if (val.includes("Review")) {
          if (val === InternalDDStatusEnum.ReviewInProgress) {
            return (
              workflowState === InternalDDStatusEnum.ApproverPending &&
              (isRole(InternalRolesEnum.LAT_APPROVER) ||
                isRole(InternalRolesEnum.LAT_ADMIN))
            );
          }
          if (val === InternalDDStatusEnum.ReviewCleared) {
            return false;
          }
          return (
            isRole(InternalRolesEnum.LAT_APPROVER) ||
            isRole(InternalRolesEnum.LAT_ADMIN)
          );
        }
        return true;
      });
      setNextStates(filteredList);
    } else {
      setNextStates([]);
    }
  };

  const isLATTreasurer: boolean = isRole(UserRole.LAT_TREASURER);
  const loanOnHold = useMemo(() => {
    return isLoanOnHold(onHoldBy);
  }, [onHoldBy]);
  const actionBtn = [
    {
      title: "Update Status",
      role: [Roles.LAT],
      hidden:
        isViewMode ||
        isWithdrawnLoan ||
        !isEvaluationPanelVisible ||
        loanOnHold,
      showInStage: [LoanStage.pre, LoanStage.post]
    },
    {
      title: "View Confirmed Loan Details",
      role: [Roles.LAT],
      hidden: activeTab !== "settlement" || !isLATTreasurer
    },
    {
      title: "View Confirmed Custody Document",
      role: [Roles.LAT],
      hidden: activeTab !== "settlement" || !isLATTreasurer
    },
    {
      title: "Export Loan To Excel",
      role: [Roles.LAT, Roles.Originator],
      hidden: LoanStage.tv === loanStage || !isEvaluationPanelVisible
    },
    {
      title: "View Previous Stages of Loan",
      role: [Roles.LAT, Roles.Originator],
      hidden:
        (availableLoanStages && availableLoanStages.length <= 1) ||
        isViewMode ||
        isWithdrawnLoan
    },
    {
      title: "Hide Previous Stages of Loan",
      role: [Roles.LAT, Roles.Originator],
      hidden: isLoanOnHold(onHoldBy) || !isViewMode || isWithdrawnLoan
    },
    {
      title: "Approve",
      role: [Roles.Appraiser, Roles.LAT],
      showInStage: [LoanStage.tv]
    },
    {
      title: "Approve Conditionally",
      role: [Roles.Appraiser, Roles.LAT],
      showInStage: [LoanStage.tv]
    },
    {
      title: "Reject",
      role: [Roles.Appraiser, Roles.LAT],
      showInStage: [LoanStage.tv]
    },
    {
      title: `Scenario (${scenarioCount})`,
      role: [Roles.LAT, Roles.Originator],
      showInState: [DataEntry, Submit, ExcelError, ExcelSuccess],
      hidden: LoanStage.tv === loanStage || isViewMode || isWithdrawnLoan
    },
    {
      title: "Delete the Loan",
      role: [Roles.LAT, Roles.Originator],
      showInState: [DataEntry, Submit, ExcelError, ExcelSuccess],
      hidden:
        (LoanStage.tv === loanStage &&
          loanState !== LoanStatusEnum.DataEntry) ||
        isViewMode ||
        !isEvaluationPanelVisible ||
        isWithdrawnLoan ||
        (loanDetails.sizerRateLockExpiryDate &&
          loanStage === LoanStage.fes &&
          loanType === getLoanType[1].displayValue)
    },
    {
      title: "Put Loan On Hold",
      role: [Roles.LAT, Roles.Originator],
      showInState: [
        InitialReview,
        DueDiligenceReview,
        FinalReview,
        Approved,
        PurchaseInitiated
      ],
      hidden:
        isLoanOnHold(onHoldBy) ||
        isViewMode ||
        isLATTreasurer ||
        isWithdrawnLoan
    },
    {
      title: "Withdraw Loan",
      role: [Roles.LAT, Roles.Originator],
      showInState: IRToPIStates,
      hidden:
        isLoanOnHold(onHoldBy) ||
        isViewMode ||
        isWithdrawnLoan ||
        isLATTreasurer
    },
    {
      title: "Remove On Hold",
      role: [Roles.LAT, Roles.Originator],
      showInState: [
        InitialReview,
        DueDiligenceReview,
        FinalReview,
        Approved,
        PurchaseInitiated
      ],
      hidden:
        (isOrigUser() && onHoldBy === onHoldEnumValue.systemValue) ||
        !isLoanOnHold(onHoldBy) ||
        isLATTreasurer ||
        isWithdrawnLoan
    },
    {
      title: "Hold Loan History",
      role: [Roles.LAT, Roles.Originator],
      showInState: [
        InitialReview,
        DueDiligenceReview,
        FinalReview,
        Approved,
        PurchaseInitiated
      ],
      hidden: !holdLoanLandMark?.length || isWithdrawnLoan
    },
    {
      title: "View Termsheet shared with borrower",
      role: [Roles.LAT, Roles.Originator],
      hidden: !showTermsheet || LoanStage.tv === loanStage || !isEvaluationPanelVisible
    }
    // {
    //   title: "Loan Summary Checklist",
    //   role: [Roles.LAT],
    //   showInState: [
    //     InitialReview,
    //     DueDiligenceReview,
    //     FinalReview,
    //     Approved,
    //     Purchased,
    //     PurchaseInitiated
    //   ],
    //   showInStage: [LoanStage.pre, LoanStage.post],
    //   hidden: ![
    //     getLoanType[0].displayValue,
    //     getLoanType[1].displayValue
    //   ].includes(loanType)
    // }
  ];
  const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
  const handleConfirmDelete = async () => {
    dispatch(showLoader(true));
    await deleteLoan(loanId, loanStage);
    dispatch(hideLoader(true));

    setOpenWarning(null);
    const excelUploadData = getExcelDataInSession();
    if (excelUploadData?.loanIdList) {
      const { loanIdList } = excelUploadData;
      const keys = Object.keys(loanIdList);
      const indexOfLoan = keys.indexOf(loanId);
      if (indexOfLoan >= 0) {
        if (keys.length > 2) {
          dispatch(resetAllStores());
          delete loanIdList[loanId];
          const dataToStoreInSession = {
            ...excelUploadData,
            ...{ loanIdList }
          };
          sessionStorage.setItem(
            "excelUpload",
            JSON.stringify(dataToStoreInSession)
          );
          dispatch(toggleDeleteLoan());
        } else {
          sessionStorage.removeItem("excelUpload");
          dispatch(resetAllStores());
          if (keys.length === 1 || !excelUploadData?.maintainExcelBundle) {
            dispatch(resetExcelUploadReducer());
            dispatch(resetRetryRequestReducer());
            navigate(`/loans/list?tab=1`);
          } else if (excelUploadData?.maintainExcelBundle) {
            const id = indexOfLoan ? keys[0] : keys[1];
            navigate(`/loan/createLoan/${loanType}/${loanStage}/${id}`);
            window.location.reload();
          }
        }
      }
    }
    if (!excelUploadData?.maintainExcelBundle) {
      dispatch(resetAllStores());
      dispatch(resetExcelUploadReducer());
      dispatch(resetRetryRequestReducer());
      navigate(`/loans/list?tab=1`);
    }
  };
  const handleDelete = async () => {
    if ([DataEntry, Submit, ExcelError, ExcelSuccess].includes(loanState)) {
      setOpenWarning({
        isOpen: true,
        handleClose: handleConfirmDelete,
        header: "Delete Loan",
        primaryBtnName: "Yes",
        body: `Are you sure you want to delete the loan.`,
        handleOptional: () => {
          setOpenWarning(null);
        }
      });
    }
  };

  const handleConfirmWithdraw = async () => {
    dispatch(
      dispatchAndUpdate(
        loanId,
        LoanStatusEnum.Withdrawn,
        loanStage,
        dispatch,
        ""
      )
    );
    setOpenConfirmBox(false);
  };
  const handleWithdraw = async () => {
    if (IRToPIStates.includes(loanState)) {
      //
      setOpenConfirmBox(true);
    }
  };

  const scenarioArr: any[] = [];
  const dailogHandler = () => {
    const dialogData = {
      topic: "delete",
      // head: "Attach to Scenario Id"
      head: "Scenario"
    };
    setDialogdetails(dialogData);
    setOpenDialog(true);
  };
  const handleClickAddScenario = () => {
    const excelUploadData = getExcelDataInSession();
    const maintainExcelUploadBundle = excelUploadData?.maintainExcelBundle;
    dispatch(showLoader(maintainExcelUploadBundle));
    const fromPreSubmitLoan = true;
    getScenarioCount();
    const scenarioListView = getScenarioListFromElasticSearch(
      fromPreSubmitLoan,
      loanType,
      "",
      20,
      selectedTags
    );
    scenarioListView
      .then((response: any) => {
        dispatch(
          updateScenarioDetailsListInRedux(
            response.data?.response?.hits?.hits,
            response.data?.response?.hits?.total?.value
          )
        );
        const data = response.data?.response?.hits?.hits;
        if (!isNullOrUndefined(data)) {
          data.forEach((item: any) => {
            if (item?._source?.scenarioId) {
              const scenarioID = {
                scenarioId: `${item?._source?.scenarioId} - ${
                  item?._source?.waiver.title || ""
                }`
              };
              scenarioArr.push(scenarioID);
            }
          });
          setScenarioIDArr(scenarioArr);
          dailogHandler();
          dispatch(hideLoader(maintainExcelUploadBundle));
        } else {
          dispatch(hideLoader(maintainExcelUploadBundle));
        }
      })
      .catch((err: any) => {
        dispatch(clearLoader());
        console.log("err", err);
      });
  };
  const saveAddedScenario = () => {
    const excelUploadData = getExcelDataInSession();
    const maintainExcelUploadBundle = excelUploadData?.maintainExcelBundle;
    dispatch(showLoader(maintainExcelUploadBundle));
    setOpenDialog(false);
    const attachScenarioToLoansPromise = attachScenarioToLoans(
      loanId,
      selectedTags
    );
    attachScenarioToLoansPromise
      .then((res: any) => {
        getScenarioCount();
        dispatch(hideLoader(maintainExcelUploadBundle));
      })
      .catch((err: any) => {
        console.log("err", err);
        dispatch(clearLoader());
      });
  };
  const handleActionBtnClick = (item: any) => {
    switch (item.title) {
      case "Update Status":
        StatusUpdate(true);
        break;
      case "View Confirmed Loan Details":
        setOpenLoanDetlNew && setOpenLoanDetlNew(true);
        break;
      case "View Confirmed Custody Document":
        setOpenConfirmCustody && setOpenConfirmCustody(true);
        break;
      case "View Previous Stages of Loan":
        if (availableLoanStages?.length > 1) {
          window.open(
            `${window.location.origin}/loan/createLoan/${loanType}/${availableLoanStages[1]}/${loanId}`
          );
        }
        break;
      case "Export Loan To Excel": {
        dispatch(showLoader(true));
        exportLoanToExcel(loanId, loanStage)
          .then((res) => {
            if (res.url) {
              clickAndDownloadFile(res.url, `${loanId}-data_tape.xlsx`)
                .then((res) => {
                  dispatch(hideLoader(true));
                })
                .catch((e) => {
                  dispatch(hideLoader(true));
                  console.log(e);
                });
            } else {
              dispatch(hideLoader(true));
            }
          })
          .catch((e) => {
            dispatch(hideLoader(true));
            console.log(e);
          });
        break;
      }
      case "Hide Previous Stages of Loan":
        if (availableLoanStages?.length) {
          dispatch(resetAllStores());
          navigate(
            `/loan/createLoan/${loanType}/${availableLoanStages[0]}/${loanId}`
          );
        }
        break;
      case "Delete the Loan":
        handleDelete();
        break;
      case `Scenario (${scenarioCount})`:
        handleClickAddScenario();
        break;
      case "Withdraw Loan": {
        if (postApprovalStates.includes(loanState)) {
          handleWithdrawingBundleLoan();
        } else {
          handleWithdraw();
        }
        break;
      }
      case "Put Loan On Hold": {
        if (loanState === PurchaseInitiated) {
          handleBundleLoanWarningOpen();
        } else {
          handleHoldDialogOpen();
        }
        break;
      }
      case "Remove On Hold":
        removeOnHoldLoan();
        break;
      case "Hold Loan History":
        onHoldLoanHistory();
        break;
      case "Mark as Table Fund":
      case "Unmark as Table Fund":
        // onHoldLoanHistory();
        // handleMarkAsTableFunded();
        setShowTableFundedDialog(true);
        break;
      case "Mark as Warehouse Funded":
      case "Unmark as Warehouse Funded":
        // onHoldLoanHistory();
        // handleMarkAsTableFunded();
        setShowWarehouseFundingDialog(true);
        break;

      case "Approve":
        dispatch(
          reviewAppraisalForm({
            status: AppraisalStatus.approved,
            toorakLoanID: loanId,
            reload: true
          })
        );
        break;
      case "Approve Conditionally":
        dispatch(checkAppraisalStatusApproveConditionally(true));
        break;
      case "Reject":
        dispatch(
          reviewAppraisalForm({
            status: AppraisalStatus.rejected,
            toorakLoanID: loanId,
            reload: true
          })
        );
        break;
      // case "Loan Summary Checklist":
      //   setShowLoanSummary(true);
      //   break;
      case "View Termsheet shared with borrower":
        setShowTermSheet(true);
        break;
      default:
        console.log("default");
    }
  };

  const toggleTableFundingStatus = async () => {
    setShowTableFundedDialog(false);
    const fundingType =
      loanFundingType === TABLE_FUNDING ? "normal" : TABLE_FUNDING;
    try {
      const payload = {
        fundingType,
        originatorId,
        loanStage,
        loanType:
          loanType === getLoanType[0].displayValue
            ? getLoanType[0].loanCreationName
            : getLoanType[1].loanCreationName,
        partyId: getCookie("person_id")
      };
      dispatch(showLoader(true));
      const response: any = await updateLoanTableFunding(loanId, payload);
      dispatch(hideLoader(true));
      if (response.status !== 200) return;
      dispatch(
        setLoanTableFunding({
          fundingType: response?.data?.loanDetailId?.fundingType
        })
      );
      dispatch(
        updateLoanLevelData({
          fieldId: "loanProcessType",
          value: response?.data?.loanProcessType
        })
      );
    } catch (err) {
      dispatch(hideLoader(true));
    }
  };
  const toggleWarehouseFundingStatus: any = async () => {
    setShowWarehouseFundingDialog(false);
    try {
      const payload = {
        isWarehouseFunded: !isWarehouseFunded
      };
      dispatch(showLoader(true));
      const response: any = await updateWarehouseFunding(loanId, payload);
      dispatch(hideLoader(true));
      if (response.status !== 200) return;
      dispatch(
        setWarehouseFunding({
          isWarehouseFunded: response?.data?.loanDetailId?.isWarehouseFunded
        })
      );
      dispatch(
        updateLoanLevelData({
          fieldId: "loanProcessType",
          value: response?.data?.loanProcessType
        })
      );
    } catch (err) {
      dispatch(hideLoader(true));
    }
  };

  const handleCancelTableFundedCancel = () => {
    setShowTableFundedDialog(false);
  };

  const handleCancelWarehouseFundedCancel = () => {
    setShowWarehouseFundingDialog(false);
  };

  const removeOnHoldLoan = () => {
    setHoldAction("REMOVE");
    setIsRemoveHoldDialog(true);
  };

  const handleRemoveLoanFromBundle = () => {
    setOpenWarning(null);
    handleHoldDialogOpen();
  };

  const handleBundleLoanWarningOpen = () => {
    setOpenWarning({
      isOpen: true,
      handleClose: handleRemoveLoanFromBundle,
      header: "Alert",
      primaryBtnName: "Proceed",
      body: `This loan is part of Bundle ID ${bundleId}. If you proceed loan will be removed from respective bundle and will be moved back to Approved status.`,
      handleOptional: () => {
        setOpenWarning(null);
      }
    });
  };

  const handleWithdrawingBundleLoan = () => {
    setOpenWarning({
      isOpen: true,
      handleClose: () => setOpenWarning(null),
      header: "Alert",
      primaryBtnName: "Ok",
      body: `This loan is part of Bundle ID ${bundleId}. Please contact Toorak Treasury team to remove the loan from Bundle and then you can withdraw the loan.`,
      handleOptional: () => {
        setOpenWarning(null);
      }
    });
  };

  const onHoldLoanHistory = () => {
    setHoldAction("HISTORY");
    setIsRemoveHoldDialog(true);
  };

  const handleRemoveHoldDialogClose = () => {
    setIsRemoveHoldDialog(false);
  };

  const handleHoldDialogOpen = () => {
    setIsOpenHoldDialog(true);
  };

  const handleHoldDialogClose = () => {
    setIsOpenHoldDialog(false);
  };

  const handleNextStateClick = (item: any) => {
    if (item.includes("Complete") && !inquiries?.length) {
      setOpenWarning({
        isOpen: true,
        handleClose: () => updateInternalState(item),
        header: "Warning",
        primaryBtnName: "Yes",
        body: `Do you want to mark the status as Completed?`,
        handleOptional: () => {
          setOpenWarning(null);
        }
      });
    } else {
      updateInternalState(item);
    }
  };

  const updateInternalState = (item: string) => {
    setOpenWarning(null);
    dispatch(
      updateILPState(
        [
          {
            loanId,
            qcRequired,
            roleOfAssignee,
            workflowState: item,
            assignedTo
          }
        ],
        workflowState
      )
    );
  };

  const handleNextTTFStateClick = (item: any) => {
    const reqObj = {
      loanId,
      ttfCurrentState: tapeToFileStatus,
      tapeToFileStatus: item
    };
    updateTTFState(reqObj, loanStage, loanDetails, dispatch);
  };

  useOutsideClick(ref, () => {
    if (isMoreActionsVisible) setIsMoreActionsVisible(!isMoreActionsVisible);
  });

  const moreActionList = () => {
    let displayList = null;
    if (!isILP()) {
      displayList = actionBtn
        .filter(
          (ele) =>
            checkIfHasRole(ele.role) &&
            !ele.hidden &&
            (!ele.showInState || ele.showInState?.includes(loanState)) &&
            (!ele.showInStage || ele.showInStage?.includes(loanStage))
        )
        .map((item) => {
          return (
            <MenuItem
              onClick={() => {
                setIsMoreActionsVisible(false);
                handleActionBtnClick(item);
              }}
              role="button"
              onKeyDown={() => {
                setIsMoreActionsVisible(false);
              }}
              tabIndex={0}
              key={item.title}
              className={classes.menuItem}
            >
              {item.title}
            </MenuItem>
          );
        });
    } else if (isTTFTab) {
      displayList = nextTTFStates.map((item: any) => {
        let isDisable = false;
        if (item === tapeToFileStatusEnum.Completed && !ttfSubmitState) {
          isDisable = true;
        }
        return (
          <MenuItem
            disabled={isDisable}
            onClick={() => {
              setIsMoreActionsVisible(false);
              handleNextTTFStateClick(item);
            }}
            role="button"
            onKeyDown={() => {
              setIsMoreActionsVisible(false);
            }}
            tabIndex={0}
            key={item}
            className={classes.menuItem}
          >
            {item}
          </MenuItem>
        );
      });
    } else if (
      (isRole(InternalRolesEnum.LAT_APPROVER) ||
        isRole(InternalRolesEnum.LAT_ADMIN)) &&
      InternalDDStatusEnum.ReviewInProgress === workflowState
    ) {
      displayList = nextStates.map((item: any) => {
        const isDisable = !selectedInquiries.length;
        return (
          <MenuItem
            disabled={isDisable}
            onClick={() => {
              const actionKey =
                item !== "Update Condition Status"
                  ? "bulk-approver"
                  : "update-condition-status";
              setIsMoreActionsVisible(false);
              handleAddBulkAction && handleAddBulkAction(actionKey);
            }}
            role="button"
            onKeyDown={() => {
              setIsMoreActionsVisible(false);
            }}
            tabIndex={0}
            key={item}
            className={classes.menuItem}
          >
            {item}
          </MenuItem>
        );
      });
    } else {
      const inquiriesExist = inquiries.length > 0;
      const isClosed =
        !inquiriesExist ||
        inquiries.every(({ status }: any) =>
          [
            ConditionStatusDisplayValues.Rescind,
            ConditionStatusDisplayValues.Waive,
            ConditionStatusDisplayValues.Satisfied
          ].includes(status)
        );
      const isRecommendationThereForAllInquiries =
        !inquiriesExist ||
        inquiries.every((inquiry: any) => {
          return inquiry.response.some((val: any) => {
            return val.responseType === ResponseTypeEnum.Recommendation;
          });
        });

      const isActionThereForAllInquiries =
        !inquiriesExist ||
        inquiries.every((inquiry: any) => {
          return inquiry.response.some((val: any) => {
            return val.responseType === ResponseTypeEnum.Approver;
          });
        });
      // const isAnyActionReachOutToOriginator = inquiries.some(
      //   (inquiry: any) => {
      //     return inquiry.response.some((responseObj: any) => {
      //       if (responseObj.responseType === ResponseTypeEnum.Approver) {
      //         const id = responseObj.chatSequenceId.toString();
      //         return chatLines.some((val: any) => {
      //           if (
      //             Object.keys(val)[0] === id &&
      //             val[id].length &&
      //             val[id][0].tags.length &&
      //             val[id][0].tags[0] ===
      //               ApproverActionsEnum.ReachouttoOriginator
      //           ) {
      //             return true;
      //           }
      //           return false;
      //         });
      // });
      const isAnyActionReachOutToOriginator = inquiries.some((inquiry: any) => {
        return inquiry.response.some((responseObj: any) => {
          if (responseObj.responseType === ResponseTypeEnum.Approver) {
            const id = responseObj.chatSequenceId.toString();
            // eslint-disable-next-line
            return chatLines.some((val: any) => {
              if (
                Object.keys(val)[0] === id &&
                val?.[id]?.length &&
                val?.[id]?.[0]?.tags?.length &&
                val[id][0].tags[0] ===
                  ApproverActionsEnum.MakeitVisibletoOriginator
              ) {
                return true;
              }
            });
          }
          return false;
        });
      });
      displayList = nextStates.map((item: any) => {
        let isDisable = false;
        if (item === InternalDDStatusEnum.QCCompleted) {
          if (grade === "" || !isRecommendationThereForAllInquiries) {
            isDisable = true;
          }
        } else if (item === InternalDDStatusEnum.IRCompleted) {
          if (
            !qcRequired &&
            (grade === "" || !isRecommendationThereForAllInquiries)
          ) {
            isDisable = true;
          }
        } else if (item === InternalDDStatusEnum.ReviewCompleted) {
          if (!isClosed || !isActionThereForAllInquiries) {
            isDisable = true;
          }
        } else if (item === InternalDDStatusEnum.ReviewCleared) {
          if (
            isClosed ||
            !isActionThereForAllInquiries ||
            isAnyActionReachOutToOriginator
          ) {
            isDisable = true;
          }
        }
        return (
          <MenuItem
            disabled={isDisable}
            onClick={() => {
              setIsMoreActionsVisible(false);
              handleNextStateClick(item);
            }}
            role="button"
            onKeyDown={() => {
              setIsMoreActionsVisible(false);
            }}
            tabIndex={0}
            key={item}
            className={classes.menuItem}
          >
            {item}
          </MenuItem>
        );
      });
    }

    return displayList;
  };
  const getScenarioCount = () => {
    const excelUploadData = getExcelDataInSession();
    const maintainExcelUploadBundle = excelUploadData?.maintainExcelBundle;
    dispatch(showLoader(maintainExcelUploadBundle));
    const scenarioDetailsPromise = getScenarioAttachedToLoan(loanId);
    scenarioDetailsPromise
      .then((res: any) => {
        setScenarioCount(res.data.length);
        const { data } = res;
        const scenarioSelectedTags: any[] = [];
        if (!isNullOrUndefined(res.data)) {
          data.forEach((item: any) => {
            if (item?.scenarioId) {
              const scenarioID = {
                scenarioId: item?.scenarioId
              };
              scenarioSelectedTags.push(scenarioID);
            }
          });
          setSelectedTags(scenarioSelectedTags);
        }
        dispatch(hideLoader(maintainExcelUploadBundle));
      })
      .catch((err: any) => {
        console.log("err", err);
        dispatch(clearLoader());
      });
  };
  useEffect(() => {
    if (loanId) {
      getScenarioCount();
    }
  }, [loanId]);

  return (
    <>
      {openWarning && (
        <WarningModal
          isOpen={openWarning.isOpen}
          handleClose={openWarning.handleClose}
          handleOptional={openWarning.handleOptional}
          primaryBtnName={openWarning.primaryBtnName}
          header={openWarning.header}
          body={openWarning.body}
        />
      )}
      <ConfirmFormModal
        isOpen={openConfirmBox}
        handleClose={(event: any, reason: string) => {
          if (reason !== "backdropClick") {
            setOpenConfirmBox(false);
          }
        }}
        confirmCondition={false}
        header="Withdraw this Loan"
        body={
          <p
            style={{
              lineHeight: 1.5,
              marginTop: 30,
              fontSize: 14,
              padding: 20,
              width: 500,
              paddingBlock: 0
            }}
          >
            Are you sure you want to withdraw this loan?
          </p>
        }
        size="xl"
        successHandler={handleConfirmWithdraw}
        primaryBtnName="Yes"
        SecondaryBtnName="No"
        disableBackdropClick
        footerStyles={{
          marginTop: "30px",
          padding: "0px 20px 20px 20px"
        }}
      />
      {Boolean(
        (((nextStates.length && activeTab === "shadowDiligence") ||
          (nextTTFStates.length && activeTab === "tapeToFile")) &&
          isILP()) ||
          !isILP()
      ) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            position: isILP() ? "relative" : undefined
          }}
        >
          <div
            className="more-action-iconBtn"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              setIsMoreActionsVisible(!isMoreActionsVisible);
            }}
            role="button"
            // onKeyDown={(e) => {
            //   e.stopPropagation();
            //   setIsMoreActionsVisible(!isMoreActionsVisible);
            // }}
            tabIndex={0}
          >
            <img
              style={{ width: 16, height: 16 }}
              src={iconSettings}
              alt="settings icon"
            />
            <Typography
              variant="h5"
              style={{
                marginLeft: 6,
                color: "#32325d",
                fontSize: 12,
                fontWeight: 600
              }}
            >
              More Actions
            </Typography>
            <KeyboardArrowLeftIcon
              fontSize="large"
              htmlColor="#32325d"
              style={{
                width: 16,
                height: 16,
                marginLeft: 12,
                transform: isMoreActionsVisible
                  ? "rotate(90deg)"
                  : "rotate(270deg)",
                objectFit: "contain"
              }}
            />
          </div>
          {isMoreActionsVisible && (
            <div
              ref={ref}
              style={{
                borderRadius: 4,
                boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.16)",
                backgroundColor: "#ffffff",
                padding: 0,
                paddingTop: 8,
                paddingBottom: 6,
                marginTop: 35,
                position: "absolute",
                zIndex: 1,
                left:
                  isILP() &&
                  !(
                    (isRole(InternalRolesEnum.LAT_APPROVER) ||
                      isRole(InternalRolesEnum.LAT_ADMIN)) &&
                    InternalDDStatusEnum.ReviewInProgress === workflowState
                  )
                    ? "35px"
                    : "auto",
                width: isILP() ? "max-content" : undefined
              }}
            >
              {moreActionList()}
            </div>
          )}
        </div>
      )}
      <AttachScenarioDialog
        dialogDetails={dialogdetails}
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        setSelectedTags={setSelectedTags}
        selectedTags={selectedTags}
        tagData={scenarioIDArr}
        handleSave={saveAddedScenario}
        fromPreSubmit
        name="scenarioId"
        label="Add Scenario"
      />
      {isOpenHoldDialog && (
        <AddOnHoldDialog
          isOpen={isOpenHoldDialog}
          handleClose={handleHoldDialogClose}
          bundleId={bundleId}
          loanState={loanState}
        />
      )}
      {isRemoveHoldDialog && (
        <ViewOnHoldDialog
          action={holdAction}
          isOpen={isRemoveHoldDialog}
          handleClose={handleRemoveHoldDialogClose}
        />
      )}
      {showTermSheet ? <TermSheetWrapper showTermSheet={showTermSheet} setShowTermSheet={setShowTermSheet} /> : null}
      <ConfirmationDialog
        onCancel={handleCancelTableFundedCancel}
        onConfirm={toggleTableFundingStatus}
        open={showTableFundedDialog}
        msg={
          loanFundingType === TABLE_FUNDING
            ? "Are you sure you want to unmark loan as table fund?"
            : "Are you sure you want to mark loan as table fund?"
        }
      />
      <ConfirmationDialog
        onCancel={handleCancelWarehouseFundedCancel}
        onConfirm={toggleWarehouseFundingStatus}
        open={showWarehouseFundingDialog}
        msg={
          isWarehouseFunded
            ? "Are you sure you want to unmark loan as warehouse funding?"
            : "Are you sure you want to mark loan as warehouse funding?"
        }
      />
    </>
  );
};
