import {
  cleanCurrency,
  isValidCurrency
} from "@toorak/tc-common-util-sdk/dist/errorCountService/fieldMissingCount";
import {
  LeaseStatusEnum,
  rentDescription
} from "../../../create-loan/constants/loanCreationDropDownValues";
import { sanitizeCurrency } from "../../../utils/formatChecks";

const leaseStatus = {
  fieldName: "leaseStatus",
  backendKey: "leaseStatus",
  editedLabel: "leaseStatus",
  fieldLabel: "Rental Characterisation",
  required: (data: any) => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: rentDescription.map((item: any) => ({
    value: item,
    label: item
  })),
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const currentLeaseTerm = {
  fieldName: "currentLeaseTerm",
  backendKey: "currentLeaseTermMonthly",
  editedLabel: "currentLeaseTermMonths",
  fieldLabel: "Current Lease Term(Months)",
  required: (data: any) => {
    if (data) {
      if (
        data.unitInformation?.leaseStatus ===
        LeaseStatusEnum.unOccupiedShortTerm
      ) {
        return true;
      }
    }
    return false;
  },
  fieldType: () => "number",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrFesCurrentLeaseTerm = {
  fieldName: "currentLeaseTermMonthly",
  backendKey: "currentLeaseTermMonthly",
  editedLabel: "currentLeaseTermMonths",
  fieldLabel: "Current Lease Term(Months)",
  required: (data: any) => true,
  fieldType: () => "number",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  ChangeValueMethod: (data: any) => Number(data)
};

const dscrPrePostCurrentLeaseTerm = {
  fieldName: "currentLeaseTerm",
  backendKey: "currentLeaseTermMonthly",
  editedLabel: "currentLeaseTermMonths",
  fieldLabel: "Current Lease Term(Months)",
  required: (data: any) => true,
  fieldType: () => "number",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  ChangeValueMethod: (data: any) => Number(data)
};

const marketRentMonthly: any = {
  fieldName: "marketRentMonthly",
  backendKey: "marketRentMonthly",
  editedLabel: "marketRentMonthly",
  fieldLabel: "Market Rent Monthly ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  required: (data: any) => {
    if (data) {
      if (
        data.unitInformation?.leaseStatus === LeaseStatusEnum.occupiedShortTerm
      ) {
        return true;
      }
    }
    return false;
  },
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const DSCRMarketRentMonthly = {
  fieldName: "marketRentMonthly",
  backendKey: "marketRentMonthly",
  editedLabel: "marketRentMonthly",
  fieldLabel: "Market Rent Monthly ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  required: (data: any) => true,
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const inPlaceLeaseRent = {
  fieldName: "inPlaceLeaseRent",
  backendKey: "inPlaceLeaseRentMonthly",
  editedLabel: "inPlaceLeaseRentMonthly",
  commentField: "inPlaceLeaseRentMonthly",
  fieldLabel: "In Place Lease Rent Monthly ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  required: (data: any) => {
    if (data) {
      if (
        data.unitInformation?.leaseStatus ===
        LeaseStatusEnum.unOccupiedShortTerm
      ) {
        return true;
      }
    }
    return false;
  },
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const DSCRInPlaceLeaseRent = {
  fieldName: "inPlaceLeaseRent",
  backendKey: "inPlaceLeaseRentMonthly",
  editedLabel: "inPlaceLeaseRentMonthly",
  fieldLabel: "In Place Lease Rent Monthly ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  required: (data: any) => true,
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const recentLeaseRent = {
  fieldName: "recentLeaseRent",
  backendKey: "mostRecentLeaseRentMonthly",
  editedLabel: "mostRecentLeaseRentMonthly",
  fieldLabel: "Net Monthly Operating Income ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  required: (data: any) => true,
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const monthlyGrossRent = {
  fieldName: "monthlyGrossRent",
  editedLabel: "monthlyGrossRent",
  fieldLabel: "Monthly Unit Rent ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  required: (data: any) => false,
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    true
};

const percentageOccupied = {
  fieldName: "percentageOccupied",
  backendKey: "percentageOccupied",
  editedLabel: "percentageOccupied",
  fieldLabel: "% Unit Occupied",
  required: (data: any) => false,
  fieldType: () => "percentage",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true
};

const dscrLeaseTermMonthly = {
  fieldName: "currentLeaseTermMonthly",
  backendKey: "currentLeaseTermMonthly",
  editedLabel: "currentLeaseTermMonths",
  fieldLabel: "Current Lease Term(Months)",
  required: (data: any) => false,
  fieldType: () => "number",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  ChangeValueMethod: (data: any) => Number(data)
};

const dscrUOMarketRentMonthly = {
  fieldName: "marketRentMonthly",
  backendKey: "marketRentMonthly",
  editedLabel: "marketRentMonthly",
  fieldLabel: "Market Rent Monthly ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  required: (data: any) => false,
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrUOInPlaceLeaseRent = {
  fieldName: "inPlaceLeaseRent",
  backendKey: "inPlaceLeaseRentMonthly",
  editedLabel: "inPlaceLeaseRentMonthly",
  fieldLabel: "In Place Lease Rent Monthly ($)",
  errorChecks: isValidCurrency,
  ChangeValueMethod: cleanCurrency,
  FocusOutMethod: sanitizeCurrency,
  required: (data: any) => false,
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const thirdPartRentEstimate = {
  fieldName: "thirdPartyRentRangeAmount",
  backendKey: "thirdPartyRentRangeAmount",
  editedLabel: "thirdPartyRentRangeAmount",
  fieldLabel: "Third-party Rent Estimate ($)",
  required: (data: any) => false,
  fieldType: () => "currency",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
}

const prePostFields = [
  leaseStatus,
  currentLeaseTerm,
  marketRentMonthly,
  inPlaceLeaseRent,
  recentLeaseRent,
  monthlyGrossRent,
  percentageOccupied
];

const dscrFesFields = [
  leaseStatus,
  dscrFesCurrentLeaseTerm,
  DSCRMarketRentMonthly,
  DSCRInPlaceLeaseRent,
  percentageOccupied,
  monthlyGrossRent
];

const getInfoFields = (rentalCharacterization: string, stage?: any) => {
  switch (rentalCharacterization) {
    case LeaseStatusEnum.occupiedLongTerm:
      return [
        leaseStatus,
        stage === "fes" ? {...dscrLeaseTermMonthly, required: ()=> true} : dscrPrePostCurrentLeaseTerm,
        DSCRMarketRentMonthly,
        thirdPartRentEstimate,
        DSCRInPlaceLeaseRent,
        monthlyGrossRent,
        percentageOccupied
      ];
    case LeaseStatusEnum.unOcupiedLongTerm:
      return [
        leaseStatus,
        DSCRMarketRentMonthly,
        thirdPartRentEstimate,
        monthlyGrossRent,
        percentageOccupied
      ];

    case LeaseStatusEnum.occupiedShortTerm:
      return [
        leaseStatus,
        dscrUOMarketRentMonthly,
        thirdPartRentEstimate,
        recentLeaseRent,
        monthlyGrossRent,
        percentageOccupied
      ];
    case LeaseStatusEnum.unOccupiedShortTerm:
      return [
        leaseStatus,
        { ...dscrLeaseTermMonthly, fieldName: "currentLeaseTerm" },
        DSCRMarketRentMonthly,
        thirdPartRentEstimate,
        dscrUOInPlaceLeaseRent,
        monthlyGrossRent,
        percentageOccupied
      ];

    default:
      return dscrFesFields;
  }
}

export const getUnitInformationFields = (
  loanStage: any,
  rentalCharacterization: string
) => {
  switch (loanStage) {
    case "dscrfes":
      return getInfoFields(rentalCharacterization, "fes");
    case "bridgefes":
      return [];
    case "dscrpre":
    case "dscrpost":
      return getInfoFields(rentalCharacterization);
    case "bridgepre":
    case "bridgepost":
      return prePostFields;
    default:
      return [];
  }
};
