import moment from "moment";
import { ObjectType } from "../../masterView/common";
import { MMMDDYYYY, sanitizePercentage } from "../../utils/formatChecks";

const checkLineIsActive = (lineData: ObjectType, loanCreationDate: string) => {
  const { effectiveStartDate, effectiveEndDate } = lineData;
  if (loanCreationDate) {
    if (effectiveStartDate && effectiveEndDate) {
      return moment.utc(loanCreationDate).isBetween(effectiveStartDate, effectiveEndDate, undefined, '[]');
    } else if (effectiveStartDate) {
      return moment.utc(loanCreationDate).isAfter(effectiveStartDate);
    } else if (effectiveEndDate) {
      return moment.utc(loanCreationDate).isBefore(effectiveEndDate);
    }
    return true;
  }
  return true;
}

export const testResultsArray = [
  { value: "PASS", label: "Pass" },
  { value: "FAIL", label: "Fail" }
];

export const DBFieldsBridgeFunding: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        required: "required",
        keyName: "maxNoteAmountTest",
        label: "Max Note Amount Test",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "5+ Unit Multifamily",
            Property Type = "Mixed Use"
        ),
        Cut off Date Maximum Loan Amount <= 10000000
    ),
    "Pass",
    IF(
        Cut off Date Maximum Loan Amount > 5000000,
        "Fail",
        "Pass"
    )
)`
      },
      {
        required: "required",
        keyName: "minNoteAmountTest",
        label: "Min Note Amount Test",
        formulaVariables: [{
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    Cut off Date Maximum Loan Amount >= 50000,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        },
        {
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        }],
        formulaText: `=IF(
    AND(
        Foreign National  = "No",
        Original Credit Score (Median)  >= 575
    ),
    "Pass",
    IF(
        AND(
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        label: "AILTV 85%",
        keyName: "aiLtv85",
        formulaVariables: [{
          key: 'Loan Purpose',
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }, {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    Original "as is" LTV <= 85%,
    "Pass",
    IF(
        AND(
            ISNUMBER(
                Original "as repaired" LTV
            ) =
            TRUE,
            Loan Purpose = "Purchase"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        label: "ARVLTV 80%",
        keyName: "arvLtv80",
        formulaVariables: [{
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }, {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    OR(
        Original "as repaired" LTV <= 80%,
        AND(
            Original "as repaired" LTV <= "n/a",
            Original "as is" LTV <= 85%
        )
    ),
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "ltc90",
        label: "LTC 90%",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
    AND(
        Loan Purpose = "Purchase",
        Original LTC <= 90%
    ),
    "Pass",
    IF(
        Loan Purpose = "Refinance",
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        keyName: "loanTerm",
        label: "Loan Term",
        formulaVariables: [{
          key: "First Payment Date of Loan",
          fieldPath: "loanInformation.firstPaymentDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Original Maturity Date",
          fieldPath: "loanInformation.maturityDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    AND(
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) <= 37,
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) > 5
    ),
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "newCustomerUpbTest",
        label: "New Customer UPB Test",
        formulaVariables: [{
          key: "Original Loan Amount",
          fieldPath: "loanEconomics.loanAmount"
        },
        {
          key: "Customer Type (Experienced or New)",
          fieldPath: "loanCharacterisations.customerType"
        }],
        formulaText: `=IF(
    AND(
        Original Loan Amount >= 2500000,
        Customer Type (Experienced or New) = "New Customer"
    ),
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal Age As Of Origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) < 180,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "gucTest",
        label: "GUC Test",
        dropDownValues: [{ value: "PASS", label: "Pass" },
        { value: "NEED GU DB APPROVAL", label: "Need GU DB Approval" }],
        formulaVariables: [{
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }],
        formulaText: `= IF(
              Toorak Product = "Ground Up",
          "Need GU DB Approval",
          "Pass"
      )`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        label: "Appraisal Age As Of Today",
        keyName: "appraisalAgeAsOfToday",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      }
    ]
  }];

export const MSFieldsBridgeFunding: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "msApproval",
        label: "MS Approval",
        formulaVariables: [{
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        },
        {
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }, {
          key: "Total Budget Amount ($)",
          fieldPath: "loanEconomics.budgetAmount"
        }],
        formulaText: `=IF(
    AND(
        Cut off Date Maximum Loan Amount > 2500000,
        Total Budget Amount ($) >= 50% * Cut off Date Maximum Loan Amount,
        Toorak Product = "Bridge"
    ),
    "Fail",
    IF(
        AND(
            Cut off Date Maximum Loan Amount > 4000000,
            Toorak Product = "Bridge"
        ),
        "Fail",
        IF(
            AND(
                Toorak Product = "2+1",
                Cut off Date Maximum Loan Amount > 4000000
            ),
            "Fail",
            "Pass"
        )
    )
)`
      },
      {
        keyName: "mixUsedOrMulti",
        label: "Mix Used or multi",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        }],
        formulaText: `=IF(
    OR(
        Property Type = "Mixed Use",
        Property Type = "5+ Unit Multifamily"
    ),
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "maxLoanAmountTest",
        label: "Max Loan Amount Test",
        formulaVariables: [{
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    Cut off Date Maximum Loan Amount >= 50000,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "msFico",
        label: "MS FICO",
        formulaVariables: [{
          key: "State",
          fieldPath: "state"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        OR(
            State = "MA",
            State = "NV"
        ),
        Original Credit Score (Median)  <= 660
    ),
    "Fail",
    "Pass"
)`
      },
      {
        required: "required",
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        Foreign National  = "No",
        Original Credit Score (Median)  >= 600
    ),
    "Pass",
    IF(
        AND(
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        keyName: "loanTerm",
        label: "Loan Term",
        formulaVariables: [{
          key: "First Payment Date of Loan",
          fieldPath: "loanInformation.firstPaymentDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Original Maturity Date",
          fieldPath: "loanInformation.maturityDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    AND(
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) <= 36,
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) > 5
    ),
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "heavyRehab",
        label: "Heavy Rehab",
        formulaVariables: [{
          key: "Rehab Type",
          fieldPath: "loanCharacterisations.rehabType"
        },
        {
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }],
        formulaText: `=IF(
          OR(
           Rehab Type = "Heavy Rehab",
           Toorak Product = "Ground" 
     ),
       "Fail",
       "Pass" 
 )`
      },
      {
        keyName: "ltcLessThanEquals90",
        label: "LTC <= 90%",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }, {
          key: 'Original LTC',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
          AND(
              Toorak Product = "Bridge", Original LTC <= 90 %
          ),
          "Pass",
          IF(
              AND(
                  Toorak Product = "2+1", Original LTC <= 80 %
              ),
              "Pass",
              IF(
                  Loan Purpose = "Refinance",
                  "Pass",
                  "Fail"
              )
          )
      )`
      },
      {
        required: "required",
        keyName: "arLtvLessThanEquals80",
        label: "ARLTV <= 80%",
        formulaVariables: [{
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    Original "as repaired" LTV <= 80%,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "aiLtvLessThanEquals85",
        label: "AILTV <= 85%",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    Original "as is" LTV <= 85%,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "propertyValue",
        label: "Property Value",
        formulaVariables: [{
          key: 'Original "As Is" Appraisal Value',
          fieldPath: "originalAsIsAppraisalValue"
        },
        {
          key: 'Original "As Repaired" Appraised Value',
          fieldPath: "originalRepairedAppraisalValue"
        }],
        formulaText: `=IF(
    MAX(
        Original "As Is" Appraisal Value,
        Original "As Repaired" Appraised Value
    ) < 75000,
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "gurantorTest",
        label: "Guarantor Test",
        formulaVariables: [{
          key: "State",
          fieldPath: "state"
        },
        {
          key: "Number of Guarantors",
          fieldPath: "guarantorAndBorrowerCount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            State = "AZ",
            State = "DC"
        ),
        Number of Guarantors  = " "
    ),
    "Pass",
    IF(
        AND(
            Number of Guarantors  > 0
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal Age As Of Origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        required: "required",
        keyName: "aiLtvLessThanEquals75",
        label: "AILTV <= 75%",
        formulaVariables: [{
          key: 'Original "As is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    Original "as is" LTV <= 75%,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "arLtvLessThanEquals70",
        label: "ARLTV <= 70%",
        formulaVariables: [{
          key: 'Original "As repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    Original "as repaired" LTV <= 70%,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal Age As Of Today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      },
      {
        keyName: "ltcLessThanEquals85",
        label: "LTC <= 85%",
        formulaVariables: [{
          key: 'Original LTC',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF( Original LTC <= 85 %,
          "Pass",
          "Fail"
      )`
      }
    ]
  }];

export const DBFieldsDSCRFunding: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        required: "required",
        keyName: "minDscr",
        label: "DB MIN DSCR",
        formulaVariables: [{
          key: "DSCR",
          fieldPath: "loanResultEconomics.dscr"
        }],
        formulaText: `IF(
    DSCR < 0.7,
    "fail",
    "Pass"
)`
      },
      {
        required: "required",
        keyName: "termToMaturity",
        label: "Term to Maturity",
        formulaVariables: [{
          key: "First Payment Date",
          fieldPath: "loanInformation.firstPaymentDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Maturity Date",
          fieldPath: "loanInformation.maturityDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `IF(
    (        DATEDIF(
            First Payment Date,
            Maturity Date,
            "M"
        ) + 1 ) = 360,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        Foreign National  = "No",
        Original Credit Score >= 575
    ),
    "Pass",
    IF(
        AND(
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        keyName: "asIsLtv",
        label: "As Is LTV",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `IF(
    Original "as is" LTV <= 0.9,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "loanAmount",
        label: "Loan Amount",
        formulaVariables: [{
          key: "Current Loan Balance",
          fieldPath: "loanEconomics.currentLoanBalance"
        }],
        formulaText: `IF(
    OR(
        Current Loan Balance ($) > 5000000,
        Current Loan Balance ($) < 50000
    ),
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal Age As Of Origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "dbAsIsLtv",
        label: "DB As Is LTV",
        formulaVariables: [{
          key: "DSCR",
          fieldPath: "loanResultEconomics.dscr"
        },
        {
          key: 'Original "As is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `IF(
    AND(
        DSCR < 1,
        Original "as is" LTV > 0.8
    ),
    "Fail",
    "Pass"
)`
      },
      {
        required: "required",
        keyName: "dbDscr",
        label: "DB DSCR",
        formulaVariables: [{
          key: "Borrowing Entity Name",
          fieldPath: "borrowerInfo.partyType"
        },
        {
          key: "DSCR",
          fieldPath: "loanResultEconomics.dscr"
        }],
        formulaText: `IF(
    AND(
        Borrowing Entity Name = "Individual",
        DSCR < 1.25
    ),
    "Fail",
    "Pass"
)`
      },
      {
        required: "required",
        keyName: "interestOnlyPeriodTest",
        label: "Interest Only Period",
        formulaVariables: [{
          key: "Interest Only Period",
          fieldPath: "loanEconomics.interestOnlyPeriod"
        }],
        formulaText: `IF(
    Interest Only Period > 120,
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "mixedMultiTest",
        label: "Mixed/Multi Test",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        }],
        formulaText: `IF(
    OR(
        Property Type = "5+ Unit Multifamily",
        Property Type = "Mixed Use"
    ),
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "guarantorTest",
        label: "Guarantor Test",
        formulaVariables: [{
          key: "Number of Guarantors /Natural Person Borrowers",
          fieldPath: "guarantorAndBorrowerCount"
        }],
        formulaText: `=IF(
    Number of Guarantors /Natural Person Borrowers >= 1,
    "Pass",
    "Fail"
)`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "dbAsIsLtvLimitation",
        label: "DB As Is LTV Limitation",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `IF(
    Original "as is" LTV > 0.75,
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal Age As Of Today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      }
    ]
  }];

export const MSFieldsDSCRFunding: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        required: "required",
        keyName: "minDscr",
        label: "MS DSCR",
        formulaVariables: [{
          key: "DSCR",
          fieldPath: "loanResultEconomics.dscr"
        }],
        formulaText: `IF(
    DSCR >= 0.9,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "termToMaturity",
        label: "Term to Maturity",
        formulaVariables: [{
          key: "First Payment Date",
          fieldPath: "loanInformation.firstPaymentDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Maturity Date",
          fieldPath: "loanInformation.maturityDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `IF(
    (        DATEDIF(
            First Payment Date,
            Maturity Date,
            "M"
        ) + 1 ) = 360,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "msFico",
        label: "MS FICO",
        formulaVariables: [{
          key: "Original Credit Score",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        },
        {
          key: "State",
          fieldPath: "state"
        },],
        formulaText: `IF(
    AND(
        OR(
            State = "MA",
            State = "NV"
        ),
        Original Credit Score <= 660
    ),
    "Fail",
    "Pass"
)`
      },
      {
        required: "required",
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        Foreign National  = "No",
        Original Credit Score >= 600
    ),
    "Pass",
    IF(
        AND(
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        keyName: "asIsLtv",
        label: "As Is LTV",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `IF(
    Original "as is" LTV <= 0.8,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "loanAmount",
        label: "Loan Amount",
        formulaVariables: [{
          key: "Current Loan Balance",
          fieldPath: "loanEconomics.currentLoanBalance"
        }],
        formulaText: `IF(
    OR(
        Current Loan Balance > 2000000,
        Current Loan Balance < 50000
    ),
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal Age As Of Origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "interestOnlyPeriodTest",
        label: "Interest Only Period",
        formulaVariables: [{
          key: "Interest Only Period",
          fieldPath: "loanEconomics.interestOnlyPeriod"
        }],
        formulaText: `IF(
    Interest Only Period > 120,
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "propertyValue",
        label: "Property Value",
        formulaVariables: [{
          key: 'Original "As Is" Appraisal Value',
          fieldPath: "originalAsIsAppraisalValue"
        }],
        formulaText: `IF(
    Original "As Is" Appraisal Value < 75000,
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "mixedMultiTest",
        label: "Mixed/Multi Test",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        }],
        formulaText: `IF(
    OR(
        Property Type = "5+ Unit Multifamily",
        Property Type = "Mixed Use"
    ),
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "guarantorTest",
        label: "Guarantor Test",
        formulaVariables: [{
          key: "State",
          fieldPath: "state"
        }, {
          key: "Number of Guarantors /Natural Person Borrowers",
          fieldPath: "guarantorAndBorrowerCount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            State = "AZ",
            State = "DC"
        ),
        Number of Guarantors /Natural Person Borrowers = " "
    ),
    "Pass",
    IF(
        Number of Guarantors /Natural Person Borrowers >= 1,
        "Pass",
        "Fail"
    )
)`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "leaseStatusTest",
        label: "Lease Status",
        formulaVariables: [{
          key: "Lease Status",
          fieldPath: "loanPricing.leaseStatus"
        }],
        formulaText: `IF(OR(
    Lease Status = "Standard",
    Lease Status= "Standard Lease"
        ))
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal Age As Of Today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      },
      {
        keyName: "aiLtv",
        label: "AILTV <= 72.5%",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `IF(
    Original "as is" LTV <= 0.725,
    "Pass",
    "Fail"
)`
      }
    ]
  }
];

export const securtization2020_1: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "minLoanAmount",
        label: "Min Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " >= 50000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " >= 100000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "maxLoanAmount",
        label: "Max Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " <= 5000000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " <= 10000000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asIsLtv",
        label: "As is LTV",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        }, {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
          AND(
              Loan Purpose = "purchase",
              OR(
                  Loan Structure = "multiple draws",
                  Loan Structure = "single funding (with rehab escrow)"
              )
          ),
          "Pass",
          IF(
              AND(
                  Loan Purpose = "purchase",
                  Loan Structure = "single draw (No rehab escrow)",
                  Original "As Is" LTV <= 85%
              ),
              "Pass",
              IF(
                  AND( 
                      Loan Purpose= "Refinance",
                      Loan Structure = "single draw (No rehab escrow)",
                      Original "As Is" LTV <= 80%
                  ),
                  "Pass",
                  IF(
                      AND(
                          Loan Purpose = "Refinance",
                          OR(
                              Loan Structure = "multiple draws",
                              Loan Structure = "single funding (with rehab escrow)"
                          ),
                          Original "As Is" LTV <= 85%
                      ),
                      "Pass",
                      "Fail"
                  )
              )
          )
      )`
      },
      {
        keyName: "asRepairedLtv",
        label: "As repaired LTV",
        formulaVariables: [{
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
          AND(
              Loan Purpose = "purchase",
              OR(
                  Loan Structure = "multiple draws",
                  Loan Structure = "single funding (with rehab escrow)"
              ),
              Original "As Repaired" LTV <= 80%
          ),
          "Pass",
          IF(
              AND(
                  Loan Purpose = "purchase",
                  Loan Structure = "single draw (No rehab escrow)"
              ),
              "Pass",
              IF(
                  AND(
                      Loan Purpose = "Refinance",
                      Loan Structure = "single draw (No rehab escrow)"
                  ),
                  "Pass",
                  IF(
                      AND(
                          Loan Purpose = "Refinance",
                          OR(
                              Loan Structure = "multiple draws",
                              Loan Structure = "single funding (with rehab escrow)"
                          ),
                          Original "As Repaired" LTV <= 75%
                      ),
                      "Pass",
                      "Fail"
                  )
              )
          )
      )`
      },
      {
        keyName: "ltc",
        label: "LTC",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
          AND(
              Loan Purpose = "purchase",
              OR(
                  Loan Structure = "multiple draws",
                  Loan Structure = "single funding (with rehab escrow)"
              ),
              Original LTC <= 90%
          ),
          "Pass",
          IF(
              AND(
                  Loan Purpose = "purchase",
                  Loan Structure = "single draw (No rehab escrow)",
                  Original LTC <= 90%
              ),
              "Pass",
              IF(
                  Loan Purpose = "Refinance",
                  "Pass",
                  "Fail"
              )
          )
      )`
      },
      {
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Foreign National  = "N",
            Foreign National  = "No"
        ),
        Original Credit Score (Median)  >= 575
    ),
    "Pass",
    IF(
        OR(
            Foreign National  = "Y",
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "originalTerm",
        label: "Original Term",
        formulaVariables: [{
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }, {
          key: "Extension Options Conversion",
          fieldPath: "loanInformation.extensionOption"
        }],
        formulaText: `=IF(
          AND(
              Original Term to Maturity >= 5,
              ( Original Term to Maturity + Extension Options Conversion ) <= 37
          ),
          "Pass",
          "Fail"
      )`
      },
      {
        keyName: "extensionOption",
        label: "Extension Option",
        formulaVariables: [{
          key: "Extension Options Conversion",
          fieldPath: "loanInformation.extensionOption"
        },
        {
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
          OR(
            Extension Options Conversion <= 12
          ),
          "Pass",
          "Fail"
      )`
      },
      {
        keyName: "groundUpTest",
        label: "Ground Up Test",
        formulaVariables: [{
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        },
        {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `= IF(
              Toorak Product = "Ground Up"
          "Pass",
          IF(
              AND(
                  Toorak Product = "Ground Up",
                  Original "As Is" LTV <= 80%,
                  Original "As Repaired" LTV <= 70%,
                  Original LTC <= 80%
              ),
              "Pass",
              "Fail"
          )
      )`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal age as of origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal age as of today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      }
    ]
  }];

export const securtization2021_1: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "minLoanAmount",
        label: "Min Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " >= 50000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " >= 100000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "maxLoanAmount",
        label: "Max Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " <= 7500000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " <= 15000000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asIsLtv",
        label: "As is LTV",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        }, {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    AND(
        $Loan Purpose = "purchase",
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        )
    ),
    "Pass",
    IF(
        Original "As Is" LTV <= 90%,
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asRepairedLtv",
        label: "As Repaired LTV",
        formulaVariables: [{
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    AND(
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        ),
        Original "As Repaired" LTV <= 80%
    ),
    "Pass",
    IF(
        $Loan Structure = "single draw (No rehab escrow)",
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "ltc",
        label: "LTC",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
    AND(
        $Loan Purpose = "purchase",
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        ),
        Original LTC <= 90%
    ),
    "Pass",
    IF(
        AND(
            $Loan Purpose = "purchase",
            $Loan Structure = "single draw (No rehab escrow)",
            Original LTC <= 100%
        ),
        "Pass",
        IF(
            Loan Purpose = "Refinance",
            "Pass",
            "Fail"
        )
    )
)`
      },
      {
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Foreign National  = "N",
            Foreign National  = "No"
        ),
        Original Credit Score (Median)  >= 575
    ),
    "Pass",
    IF(
        OR(
            Foreign National  = "Y",
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "originalTerm",
        label: "Original Term",
        formulaVariables: [{
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
    AND(
        Original Term to Maturity >= 5,
        Original Term to Maturity <= 37
    ),
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "extensionOption",
        label: "Extension Option",
        formulaVariables: [{
          key: "Extension Options Conversion",
          fieldPath: "loanInformation.extensionOption"
        },
        {
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
    ( Extension Options Conversion + Original Term to Maturity ) <= 61,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "groundUpTest",
        label: "Ground Up Test",
        formulaVariables: [{
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        },
        {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
        Toorak Product = "Ground Up",
    "Pass",
    IF(
        AND(
            Toorak Product = "Ground Up",
            Original "As Is" LTV <= 80%,
            Original "As Repaired" LTV <= 75%,
            Original LTC <= 100%
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal age as of origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal age as of today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      }
    ]
  }];

export const securtization2024_1: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "minLoanAmount",
        label: "Min Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " >= 50000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " >= 100000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "maxLoanAmount",
        label: "Max Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " <= 7500000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " <= 15000000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asIsLtv",
        label: "As is LTV",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        }, {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    AND(
        $Loan Purpose = "purchase",
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        )
    ),
    "Pass",
    IF(
        Original "As Is" LTV <= 90%,
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asRepairedLtv",
        label: "As Repaired LTV",
        formulaVariables: [{
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    AND(
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        ),
        Original "As Repaired" LTV <= 80%
    ),
    "Pass",
    IF(
        $Loan Structure = "single draw (No rehab escrow)",
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "ltc",
        label: "LTC",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
    AND(
        $Loan Purpose = "purchase",
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        ),
        Original LTC <= 90%
    ),
    "Pass",
    IF(
        AND(
            $Loan Purpose = "purchase",
            $Loan Structure = "single draw (No rehab escrow)",
            Original LTC <= 100%
        ),
        "Pass",
        IF(
            Loan Purpose = "Refinance",
            "Pass",
            "Fail"
        )
    )
)`
      },
      {
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Foreign National  = "N",
            Foreign National  = "No"
        ),
        Original Credit Score (Median)  >= 575
    ),
    "Pass",
    IF(
        OR(
            Foreign National  = "Y",
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "originalTerm",
        label: "Original Term",
        formulaVariables: [{
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
    AND(
        Original Term to Maturity >= 11,
        Original Term to Maturity <= 37
    ),
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "extensionOption",
        label: "Extension Option",
        formulaVariables: [{
          key: "Extension Options Conversion",
          fieldPath: "loanInformation.extensionOption"
        },
        {
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
    ( Extension Options Conversion + Original Term to Maturity ) <= 61,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "groundUpTest",
        label: "Ground Up Test",
        formulaVariables: [{
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        },
        {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
        Toorak Product = "Ground Up",
    "Pass",
    IF(
        AND(
            Toorak Product = "Ground Up",
            Original "As Is" LTV <= 80%,
            Original "As Repaired" LTV <= 75%,
            Original LTC <= 100%
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal age as of origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal age as of today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      }
    ]
  }];

export const securtization2024_2: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "minLoanAmount",
        label: "Min Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " >= 50000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " >= 100000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "maxLoanAmount",
        label: "Max Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " <= 7500000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " <= 15000000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asIsLtv",
        label: "As is LTV",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        }, {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    AND(
        $Loan Purpose = "purchase",
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        )
    ),
    "Pass",
    IF(
        Original "As Is" LTV <= 90%,
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asRepairedLtv",
        label: "As Repaired LTV",
        formulaVariables: [{
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    AND(
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        ),
        Original "As Repaired" LTV <= 80%
    ),
    "Pass",
    IF(
        $Loan Structure = "single draw (No rehab escrow)",
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "ltc",
        label: "LTC",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
    AND(
        $Loan Purpose = "purchase",
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        ),
        Original LTC <= 90%
    ),
    "Pass",
    IF(
        AND(
            $Loan Purpose = "purchase",
            $Loan Structure = "single draw (No rehab escrow)",
            Original LTC <= 100%
        ),
        "Pass",
        IF(
            Loan Purpose = "Refinance",
            "Pass",
            "Fail"
        )
    )
)`
      },
      {
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Foreign National  = "N",
            Foreign National  = "No"
        ),
        Original Credit Score (Median)  >= 575
    ),
    "Pass",
    IF(
        OR(
            Foreign National  = "Y",
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "originalTerm",
        label: "Original Term",
        formulaVariables: [{
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
    AND(
        Original Term to Maturity >= 10,
        Original Term to Maturity <= 37
    ),
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "extensionOption",
        label: "Extension Option",
        formulaVariables: [{
          key: "Extension Options Conversion",
          fieldPath: "loanInformation.extensionOption"
        },
        {
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
    ( Extension Options Conversion + Original Term to Maturity ) <= 61,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "groundUpTest",
        label: "Ground Up Test",
        formulaVariables: [{
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        },
        {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
        Toorak Product = "Ground Up",
    "Pass",
    IF(
        AND(
            Toorak Product = "Ground Up",
            Original "As Is" LTV <= 80%,
            Original "As Repaired" LTV <= 75%,
            Original LTC <= 100%
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal age as of origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal age as of today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      }
    ]
  }];

export const securtization2024RTL: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "minLoanAmount",
        label: "Min Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " >= 50000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " >= 100000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "maxLoanAmount",
        label: "Max Loan Amount",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        },
        {
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Property Type = "2-4 Unit",
            Property Type = "SFR",
            Property Type = "Condo"
        ),
        $"Cut off Date Maximum Loan Amount " <= 7500000
    ),
    "Pass",
    IF(
        AND(
            OR(
                Property Type = "5+ Unit Multifamily",
                Property Type = "Mixed use"
            ),
            $"Cut off Date Maximum Loan Amount " <= 15000000
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asIsLtv",
        label: "As is LTV",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        }, {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    AND(
        $Loan Purpose = "purchase",
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        )
    ),
    "Pass",
    IF(
        Original "As Is" LTV <= 90%,
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "asRepairedLtv",
        label: "As Repaired LTV",
        formulaVariables: [{
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    AND(
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        ),
        Original "As Repaired" LTV <= 80%
    ),
    "Pass",
    IF(
        $Loan Structure = "single draw (No rehab escrow)",
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "ltc",
        label: "LTC",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
    AND(
        $Loan Purpose = "purchase",
        OR(
            $Loan Structure = "multiple draws",
            $Loan Structure = "single funding (with rehab escrow)"
        ),
        Original LTC <= 90%
    ),
    "Pass",
    IF(
        AND(
            $Loan Purpose = "purchase",
            $Loan Structure = "single draw (No rehab escrow)",
            Original LTC <= 100%
        ),
        "Pass",
        IF(
            Loan Purpose = "Refinance",
            "Pass",
            "Fail"
        )
    )
)`
      },
      {
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        OR(
            Foreign National  = "N",
            Foreign National  = "No"
        ),
        Original Credit Score (Median)  >= 575
    ),
    "Pass",
    IF(
        OR(
            Foreign National  = "Y",
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "originalTerm",
        label: "Original Term",
        formulaVariables: [{
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
    AND(
        Original Term to Maturity >= 11,
        Original Term to Maturity <= 37
    ),
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "extensionOption",
        label: "Extension Option",
        formulaVariables: [{
          key: "Extension Options Conversion",
          fieldPath: "loanInformation.extensionOption"
        },
        {
          key: "Original Term to Maturity",
          fieldPath: "loanFeatures.originalTermToMaturity"
        }],
        formulaText: `=IF(
    ( Extension Options Conversion + Original Term to Maturity ) <= 61,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "groundUpTest",
        label: "Ground Up Test",
        formulaVariables: [{
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        },
        {
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        },
        {
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
        Toorak Product = "Ground Up",
    "Pass",
    IF(
        AND(
            Toorak Product = "Ground Up",
            Original "As Is" LTV <= 80%,
            Original "As Repaired" LTV <= 75%,
            Original LTC <= 100%
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal age as of origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal age as of today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      }
    ]
  }];

export const JPMFieldsBridgeFunding: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "mixUsedOrMulti",
        label: "Mixed/Multi Test",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        }],
        formulaText: `=IF(
    OR(
        Property Type = "Mixed Use",
        Property Type = "5+ Unit Multifamily"
    ),
    "Fail",
    "Pass"
)`
      },
      {
        required: "required",
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        Foreign National  = "No",
        Original Credit Score (Median)  >= 580
    ),
    "Pass",
    IF(
        AND(
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        keyName: "loanTerm",
        label: "Term to Maturity",
        formulaVariables: [{
          key: "First Payment Date of Loan",
          fieldPath: "loanInformation.firstPaymentDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Original Maturity Date",
          fieldPath: "loanInformation.maturityDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    AND(
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) <= 24,
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) > 5
    ),
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "heavyRehab",
        label: "Heavy Rehab",
        formulaVariables: [{
          key: "Rehab Type",
          fieldPath: "loanCharacterisations.rehabType"
        },
        {
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }],
        formulaText: `=IF(
          OR(
           Rehab Type = "Heavy Rehab",
           Toorak Product = "Ground" 
     ),
       "Fail",
       "Pass" 
 )`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal Age As Of Origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "eligibilityLtcTest",
        label: "LTC <= 85%",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }, {
          key: 'Original LTC',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
          AND(
              Toorak Product = "Bridge", Original LTC <= 85 %
          ),
          "Pass",
          IF(
              AND(
                  Toorak Product = "2+1", Original LTC <= 85 %
              ),
              "Pass",
              IF(
                  Loan Purpose = "Refinance",
                  "Pass",
                  "Fail"
              )
          )
      )`
      },
      {
        required: "required",
        keyName: "eligibilityArltvTest",
        label: "ARLTV <= 75%",
        formulaVariables: [{
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    Original "as repaired" LTV <= 75%,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "eligibilityAiltvTest",
        label: "AILTV <= 80%",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        },
        {
          key: "Loan Structure",
          fieldPath: "loanInformation.selectedLoanStructure"
        }
        ],
        formulaText: `IF(
      Loan Structure = “Single Draw (No Rehab Escrow)“
      IF (
               Original "as is" LTV <= 80%,
               "Pass",
                "Fail"
       ),
       IF (
               Original "as is" LTV <= 85%,
               "Pass",
                "Fail"
       ),
)`
      },
      {
        keyName: "propertyValue",
        label: "Property Value",
        formulaVariables: [{
          key: 'Original "As Is" Appraisal Value',
          fieldPath: "originalAsIsAppraisalValue"
        },
        {
          key: 'Original "As Repaired" Appraised Value',
          fieldPath: "originalRepairedAppraisalValue"
        }],
        formulaText: `=IF(
    MAX(
        Original "As Is" Appraisal Value,
        Original "As Repaired" Appraised Value
    ) < 75000,
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "guarantorTest",
        label: "Guarantor Test",
        formulaVariables: [{
          key: "State",
          fieldPath: "state"
        },
        {
          key: "Number of Guarantors",
          fieldPath: "guarantorAndBorrowerCount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            State = "AZ",
            State = "DC"
        ),
        Number of Guarantors  = " "
    ),
    "Pass",
    IF(
        AND(
            Number of Guarantors  >= 1
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        keyName: "loanAmountTest",
        label: "Loan Amount",
        formulaVariables: [
          {
            key: "Current Loan Balance",
            fieldPath: "loanEconomics.cutOffMaxLoanAmount"
          }],
        formulaText: `=IF(
         OR(
        Current Loan Balance > 3000000,
        Current Loan Balance < 50000
        ),
        "Fail",
        "Pass"
    )`
      }
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        required: "required",
        keyName: "concentrationAiltvTest",
        label: "AILTV <= 75%",
        formulaVariables: [{
          key: 'Original "As is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    Original "as is" LTV <= 75%,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "concentrationArltvTest",
        label: "ARLTV <= 70%",
        formulaVariables: [{
          key: 'Original "As repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    Original "as repaired" LTV <= 70%,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "appraisalAgeAsOfToday",
        label: "Appraisal Age As Of Today",
        formulaVariables: [{
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF((TODAY() - Most Recent Property Valuation Date ) < 180,
            "Pass",
            "Fail"
          )`
      },
      {
        keyName: "concentrationLtcTest",
        label: "LTC <= 85%",
        formulaVariables: [{
          key: 'Original LTC',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF( Original LTC <= 85 %,
          "Pass",
          "Fail"
      )`
      },
      {
        required: "required",
        keyName: "concentrationFico",
        label: "FICO >= 680",
        formulaVariables: [
          {
            key: "Original Credit Score (Median)",
            fieldPath: "borrowerInfo.originalCreditScoreMedian"
          }],
        formulaText: `=IF(
        Original Credit Score (Median)  >= 680,
    "Pass",
    "Fail"
    )`
      },
    ]
  }
];

export const ChurchillFieldsBridgeFunding: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "maxLoanAmountTest",
        label: "Churchill Approval",
        formulaVariables: [{
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        },
        {
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }, {
          key: "Total Budget Amount ($)",
          fieldPath: "loanEconomics.budgetAmount"
        }],
        formulaText: `=IF(
    AND(
        Cut off Date Maximum Loan Amount > 6000000,
        Total Budget Amount ($) >= 50% * Cut off Date Maximum Loan Amount,
        Toorak Product = "Bridge"
    ),
    "Fail",
    IF(
        AND(
            Cut off Date Maximum Loan Amount > 6000000,
            Toorak Product = "Bridge"
        ),
        "Fail",
        IF(
            AND(
                Toorak Product = "2+1",
                Cut off Date Maximum Loan Amount > 6000000
            ),
            "Fail",
            "Pass"
        )
    )
)`
      },
      {
        keyName: "minLoanAmountTest",
        label: "Max Loan Amount Test",
        formulaVariables: [{
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    Cut off Date Maximum Loan Amount >= 50000,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        },
        {
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        }],
        formulaText: `=IF(
    AND(
        Foreign National  = "No",
        Original Credit Score (Median)  >= 600
    ),
    "Pass",
    IF(
        AND(
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        keyName: "loanTerm",
        label: "Loan Term",
        formulaVariables: [{
          key: "First Payment Date of Loan",
          fieldPath: "loanInformation.firstPaymentDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Original Maturity Date",
          fieldPath: "loanInformation.maturityDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    AND(
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) <= 24,
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) > 5
    ),
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal Age As Of Origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) <= 180,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "eligibilityLtcTest",
        label: "LTC <= 85%",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }, {
          key: 'Original LTC',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
          AND(
              Toorak Product = "Bridge", Original LTC <= 85 %
          ),
          "Pass",
          IF(
              AND(
                  Toorak Product = "2+1", Original LTC <= 80 %
              ),
              "Pass",
              IF(
                  Loan Purpose = "Refinance",
                  "Pass",
                  "Fail"
              )
          )
      )`
      },
      {
        required: "required",
        keyName: "eligibilityArltvTest",
        label: "ARLTV <= 80%",
        formulaVariables: [{
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    Original "as repaired" LTV <= 80%,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "eligibilityAiltvTest",
        label: "AILTV <= 85%",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    Original "as is" LTV <= 85%,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "propertyValue",
        label: "Property Value",
        formulaVariables: [{
          key: 'Original "As Is" Appraisal Value',
          fieldPath: "originalAsIsAppraisalValue"
        },
        {
          key: 'Original "As Repaired" Appraised Value',
          fieldPath: "originalRepairedAppraisalValue"
        }],
        formulaText: `=IF(
    MAX(
        Original "As Is" Appraisal Value,
        Original "As Repaired" Appraised Value
    ) < 75000,
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "guarantorTest",
        label: "Guarantor Test",
        formulaVariables: [{
          key: "State",
          fieldPath: "state"
        },
        {
          key: "Number of Guarantors",
          fieldPath: "guarantorAndBorrowerCount"
        }],
        formulaText: `=IF(
    AND(
        OR(
            State = "AZ",
            State = "DC"
        ),
        Number of Guarantors  = " "
    ),
    "Pass",
    IF(
        AND(
            Number of Guarantors  > 0
        ),
        "Pass",
        "Fail"
    )
)`
      },
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "concentrationChurchillTest",
        label: "Churchill",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        },
        {
          key: "Customer Type",
          fieldPath: "loanCharacterisations.customerType"
        }],
        formulaText: `IF (
                Customer Type = "New Customer",
                "Pass",
                IF ( 
                AND
                 OR (
               Customer Type = "Experienced Customer", 
               Customer Type = "Professional Customer"
               )
                Original "as is" LTV <= 75%,)
                "Pass",
                "Fail"
                )
                )`
      }
    ]
  }
];

export const BawagFieldsBridgeFunding: any = [
  {
    groupLabel: "Eligibility",
    key: "eligibility",
    tests: [
      {
        keyName: "gucTest",
        label: "No-Gound Up Loans",
        formulaVariables: [{
          key: "Toorak Product",
          fieldPath: "loanInformation.selectedToorakProduct"
        }],
        formulaText: `= IF(
              Toorak Product = "Ground Up",
          "Fail",
          "Pass"
      )`
      },
      {
        keyName: "minLoanAmountTest",
        label: "Min Note Amount Test",
        formulaVariables: [{
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    Cut off Date Maximum Loan Amount >= 50000,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "fico",
        label: "FICO",
        formulaVariables: [{
          key: "Original Credit Score (Median)",
          fieldPath: "borrowerInfo.originalCreditScoreMedian"
        },
        {
          key: "Foreign National",
          fieldPath: "borrowerInfo.isForeignNational"
        }],
        formulaText: `=IF(
    AND(
        Foreign National  = "No",
        Original Credit Score (Median)  >= 575
    ),
    "Pass",
    IF(
        AND(
            Foreign National  = "Yes"
        ),
        "Pass",
        "Fail"
    )
)`
      },
      {
        required: "required",
        keyName: "eligibilityArltvTest",
        label: "ARLTV <= 80%",
        formulaVariables: [{
          key: 'Original "As Repaired" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsRepairedLtv"
        }],
        formulaText: `=IF(
    Original "as repaired" LTV <= 80%,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "eligibilityAiltvTest",
        label: "AILTV <= 90%",
        formulaVariables: [{
          key: 'Original "As Is" LTV',
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalAsIsLtv"
        }],
        formulaText: `=IF(
    Original "as is" LTV <= 90%,
    "Pass",
    "Fail"
)`
      },
      {
        keyName: "eligibilityLtcTest",
        label: "LTC 90%",
        formulaVariables: [{
          key: "Loan Purpose",
          fieldPath: "loanInformation.loanPurpose"
        },
        {
          key: "Original LTC",
          formatter: (text: string) => sanitizePercentage(text, true),
          fieldPath: "loanFeatures.originalLtc"
        }],
        formulaText: `=IF(
    AND(
        Loan Purpose = "Purchase",
        Original LTC <= 90%
    ),
    "Pass",
    IF(
        Loan Purpose = "Refinance",
        "Pass",
        "Fail"
    )
)`
      },
      {
        keyName: "appraisalAgeAsOfOrigination",
        label: "Appraisal Age As Of Origination",
        formulaVariables: [{
          key: "Origination Date",
          fieldPath: "loanInformation.originationDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Most Recent Property Valuation Date",
          fieldPath: "propertyValuationDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    ( Origination Date - Most Recent Property Valuation Date ) < 180,
    "Pass",
    "Fail"
)`
      },
      {
        required: "required",
        keyName: "loanTerm",
        label: "Loan Term",
        formulaVariables: [{
          key: "First Payment Date of Loan",
          fieldPath: "loanInformation.firstPaymentDate",
          formatter: MMMDDYYYY
        },
        {
          key: "Original Maturity Date",
          fieldPath: "loanInformation.maturityDate",
          formatter: MMMDDYYYY
        }],
        formulaText: `=IF(
    AND(
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) <= 37,
        (            DATEDIF(
                First Payment Date of Loan,
                Original Maturity Date,
                "M"
            ) + 1 ) > 5
    ),
    "Pass",
    "Fail"
)`
      },
    ]
  },
  {
    groupLabel: "Concentration",
    key: "concentration",
    tests: [
      {
        keyName: "mixUsedOrMulti",
        label: "Mix Used or multi",
        formulaVariables: [{
          key: "Property Type",
          fieldPath: "propertyType"
        }],
        formulaText: `=IF(
    OR(
        Property Type = "Mixed Use",
        Property Type = "5+ Unit Multifamily"
    ),
    "Fail",
    "Pass"
)`
      },
      {
        keyName: "maxLoanAmountTest",
        label: "UPB Test",
        formulaVariables: [{
          key: "Cut off Date Maximum Loan Amount",
          fieldPath: "loanEconomics.cutOffMaxLoanAmount"
        }],
        formulaText: `=IF(
    Cut off Date Maximum Loan Amount >= 2000000,
    "Fail",
    "Pass"
)`
      },
    ]
  }
];

export const fieldArrMap: { [key: string]: any } = {
  BridgeLoan: {
    MS: MSFieldsBridgeFunding,
    DB: DBFieldsBridgeFunding,
    JPM: JPMFieldsBridgeFunding,
    CHR: ChurchillFieldsBridgeFunding,
    BWG: BawagFieldsBridgeFunding,
    "bridge2021": securtization2021_1,
    "bridge2022_1": securtization2021_1,
    "bridge2022_2": securtization2021_1,
    "bridge2024_1": securtization2024_1,
    "bridge2024_2": securtization2024_2,
    "bridge2024_rrtl1": securtization2024RTL,
    "bridge2024_rrtl2": securtization2024RTL
  },
  InvestorDSCR: {
    MS: MSFieldsDSCRFunding,
    DB: DBFieldsDSCRFunding
  }
};

export const fundingRadioMap: { [key: string]: string } = {
  DB: "dbWhl",
  MS: "msWhl",
  JPM: "jpmWhl",
  CHR: "chrWhl",
  BWG: "bwgWhl",
  "bridge2021": "bridge2021",
  "bridge2022_1": "bridge2022_1",
  "bridge2022_2": "bridge2022_2",
  "bridge2024_1": "bridge2024_1",
  "bridge2024_2": "bridge2024_2",
  "bridge2024_rrtl1": "bridge2024_rrtl1",
  "bridge2024_rrtl2": "bridge2024_rrtl2"
};

export let equity: any = {
  label: "Equity",
  value: "Equity"
};

const MS = {
  tabLabel: "Morgan Stanley (MS) Tests",
  label: "Morgan Stanley (MS)",
  shortLabel: "MS",
  key: "MS",
  value: "MS",
  mappingKey: "msWhl",
  redirect: true,
  isEditable: true
}
const DB = {
  tabLabel: "Deutsche Bank (DB) Tests",
  label: "Deutsche Bank (DB)",
  shortLabel: "DB",
  key: "DB",
  value: "DB",
  mappingKey: "dbWhl",
  redirect: true,
  isEditable: true
}
const JPM = {
  tabLabel: "JP Morgan (JPM) Tests",
  label: "JP Morgan (JPM)",
  shortLabel: "JPM",
  key: "JPM",
  value: "JPM",
  mappingKey: "jpmWhl",
  redirect: true,
  isEditable: true
};
const CHR = {
  tabLabel: "Churchill (CHR) Tests",
  label: "Churchill (CHR)",
  shortLabel: "CHR",
  key: "CHR",
  value: "CHR",
  mappingKey: "chrWhl",
  redirect: true,
  isEditable: true
};
const BWG = {
  tabLabel: "Bawang (BWG) Tests",
  label: "Bawang (BWG)",
  shortLabel: "BWG",
  key: "BWG",
  value: "BWG",
  mappingKey: "bwgWhl",
  redirect: true,
  isEditable: true
};

export const getWareHouseLines = (loanType?: string) => {
  const fundingLines: ObjectType = {
    BridgeLoan: [
      MS,
      DB,
      JPM,
      CHR,
      BWG
    ],
    InvestorDSCR: [
      MS,
      DB
    ]
  }
  return loanType ? fundingLines?.[loanType] : fundingLines.BridgeLoan;
};

export const getSecurtizationGrades = (needActiveCheck?: boolean) => {
  const lines = [
    // {
    //   label: "2020-1",
    //   key: "2020-1",
    //   value: "bridge2020",
    //   mappingKey: "bridge2020",
    //   tabLabel: "2020-1",
    //   redirect: false,
    //   isEditable: false
    // },
    // {
    //   label: "2021-1",
    //   shortLabel: "2021-1",
    //   key: "bridge2021",
    //   value: "bridge2021",
    //   mappingKey: "bridge2021",
    //   tabLabel: "2021-1",
    //   redirect: true,
    //   isEditable: false
    // },
    {
      label: "2022-1",
      shortLabel: "2022-1",
      key: "bridge2022_1",
      value: "bridge2022_1",
      mappingKey: "bridge2022_1",
      tabLabel: "2022-1",
      redirect: true,
      isEditable: false,
      isActiveLine: (loanCreationDate: string) => checkLineIsActive({ effectiveEndDate: "2024-05-10" }, loanCreationDate)
    },
    {
      label: "2022-2",
      shortLabel: "2022-2",
      key: "bridge2022_2",
      value: "bridge2022_2",
      mappingKey: "bridge2022_2",
      tabLabel: "2022-2",
      redirect: true,
      isEditable: false,
      isActiveLine: (loanCreationDate: string) => checkLineIsActive({ effectiveEndDate: "2024-05-10" }, loanCreationDate)
    },
    {
      label: "2024-1",
      shortLabel: "2024-1",
      key: "bridge2024_1",
      value: "bridge2024_1",
      mappingKey: "bridge2024_1",
      tabLabel: "2024-1",
      redirect: true,
      isEditable: false,
      isActiveLine: (loanCreationDate: string) => checkLineIsActive({ effectiveStartDate: "2024-05-11" }, loanCreationDate)
    },
    {
      label: "2024-2",
      shortLabel: "2024-2",
      key: "bridge2024_2",
      value: "bridge2024_2",
      mappingKey: "bridge2024_2",
      tabLabel: "2024-2",
      redirect: true,
      isEditable: false,
      isActiveLine: (loanCreationDate: string) => checkLineIsActive({ effectiveStartDate: "2024-05-11" }, loanCreationDate)
    },
    {
      label: "2024-RRTL1",
      shortLabel: "2024-RRTL1",
      key: "bridge2024_rrtl1",
      value: "bridge2024_rrtl1",
      mappingKey: "bridge2024_rrtl1",
      tabLabel: "2024-RRTL1",
      redirect: true,
      isEditable: false,
      isActiveLine: (loanCreationDate: string) => checkLineIsActive({ effectiveStartDate: "2024-05-11" }, loanCreationDate)
    },
    {
      label: "2024-RRTL2",
      shortLabel: "2024-RRTL2",
      key: "bridge2024_rrtl2",
      value: "bridge2024_rrtl2",
      mappingKey: "bridge2024_rrtl2",
      tabLabel: "2024-RRTL2",
      redirect: true,
      isEditable: false,
      isActiveLine: (loanCreationDate: string) => checkLineIsActive({ effectiveStartDate: "2024-05-11" }, loanCreationDate)
    }
  ];
  if (needActiveCheck) {
    return lines;
  }
  return lines.map((line: any) => {
    delete line.isActiveLine;
    return line;
  })
};