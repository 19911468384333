import React, { useCallback, useEffect, useRef, useState } from "react";
// @ts-ignore
import { BudgetReviewWrapperSDK } from "@toorak/budget-review-module";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { getDocuments } from "../create-loan/create-loan.action";
import { RootState } from "../stores/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DrawRolesEnum,
  InternalRolesEnum,
  URLParamsType
} from "../utils/constants";
import { filterObjectsByTag } from "./InternalBudgetReview";

import { ILPStore } from "../stores/ILP/ILP.reducer";
import { getListOfAssignee, setILPUserList } from "../stores/ILP/ILP.action";
import { BudgetReviewStore } from "../stores/budgetReview/budgetReview.interface";
import {
  approvalStatuses,
  budgetApproveStatusEnum,
  budgetReviewStatusEnum,
  disableStatuses,
  editableStatuses
} from "../ilp/list-view/BudgetTaskManagement";
import { convertUSDToNumber } from "../utils/formatChecks";
import {
  // setBalanceDisbursedAmount,
  setSelectedBudgetDoc,
  updateBudgetButtonInfo,
  updateBudgetChatsData,
  updateBudgetPropData,
  updateLineItemList,
  updateSelectedPropDetails
} from "../stores/budgetReview/budgetReview.reducer";
import {
  BudgetApproverStatus,
  ExtractionInProgressState,
  ExtractionProcessState,
  isLoginPartyId
} from "./BudgetReview.constant";
import {
  deleteLineItems,
  getBudgetHistory,
  getBudgetData,
  getBudgetReviewDataV2,
  getCommentsList,
  redoBudgetReview,
  updateBudgetStatusV2,
  // updateLineReduxData,
  updatePredictionStatus,
  updateStatusOnTask,
  updateBudgetLineItems,
  getUserDetails,
  updateSelectionMode,
  getRedoValidationItems,
  getPreviousVersionData,
  lineItemResponse,
  mergeChatDetails,
  updateTotalBudgetAPI
} from "../stores/budgetReview/budgetReview.action";
import { taskNames } from "../stores/tasks/Tasks.action";
import { DocumentViewer } from "../create-loan/ttf-review/document-review/document-viewer/DocumentViewer";
import { getCookie } from "../utils/cookies";
import { ConfirmFormModal } from "@toorak/tc-common-fe-sdk";
// import { ConfigItems } from "./ReviewConfig";
import { usePolling } from "../custom-hooks/usePolling";
import { getConfig } from "../config/config";
import { addEmptyLineItems } from "./FieldsPanel/TotalBudgetArea";
import {
  buildTaskObject,
  getAssignedBy,
  getDataByLoanID,
  getPropDataByLoanID,
  handleAddCommentProceed,
  updateFieldName
} from "./LineItemsComment.util";
import { isOrigUser, isRole } from "../utils/AccessManagement";
import { convertNumberToUSD } from "./ReviewConfig";
// import { DrawRequestStore } from "../stores/drawRequest/drawRequest.interface";
import { patchLoanData } from "../network/apiService";
import { getDrawRequestData } from "../stores/drawRequest/drawRequest.action";
import { transformToReduxList } from "./BudgetReview.utils";

export const budgetReviewStatusConst = {
  green: budgetReviewStatusEnum.Approved,
  orange: budgetReviewStatusEnum.InProgress,
  black: budgetReviewStatusEnum.LoanSubmitted
};

export function isPLDFlow() {
  let pageSource = sessionStorage.getItem("budgetReviewSource");
  if (!pageSource) return;
  return pageSource === "PLD Page";
}

export function updateWithPrevDrawData(
  updatedLineItems: any[],
  redoItems: any[]
) {
  let balanceAmount = 0;
  const formattedData = updatedLineItems?.map((item) => {
    const prevDrawData = redoItems.filter(
      (temp: any) => temp.item.trim() === item.item.trim()
    )[0];
    const minBudgetAllowed = prevDrawData
      ? Number(prevDrawData.latestCompletion) === 100
        ? prevDrawData?.financedBudget
        : (prevDrawData?.financedBudget * prevDrawData.latestCompletion) / 100
      : 0;
    const remainingFinance =
      prevDrawData && Number(prevDrawData.latestCompletion) === 100
        ? 0
        : Number(convertUSDToNumber(item.budget)) - minBudgetAllowed;

    balanceAmount = balanceAmount + remainingFinance;
    const tempItem = {
      ...item,
      isPrevDrawDisbursed: prevDrawData
        ? !(
            prevDrawData?.latestCompletion == 0 ||
            prevDrawData?.latestCompletion === null
          )
        : false,
      minBudgetAllowed,
      maxBudgetAllowed:
        prevDrawData && Number(prevDrawData.latestCompletion) === 100
          ? prevDrawData.financedBudget
          : Number(convertUSDToNumber(item.budget))
    };

    return tempItem;
  });
  return { formattedData, balanceAmount };
}

function calculateRemainingAllBudget(
  totalDisbursedAmount: number,
  budgetButtonInfo: any,
  budgetReviewResponse: any
) {
  let nonRedoTotalFB: number = 0;
  try {
    budgetReviewResponse.budgetDocumentInfoList.forEach((item: any) => {
      if (!item.isRedoInProcess) {
        item.budgetLineItemList?.forEach((itemList: any) => {
          const oldRatio =
            convertUSDToNumber(budgetButtonInfo.financedBudgetAmount) /
            convertUSDToNumber(budgetButtonInfo.loanDetailsBudgetAmount);

          const calFB =
            convertUSDToNumber(itemList.financedBudget) == 0
              ? convertUSDToNumber(itemList.budget) * oldRatio
              : convertUSDToNumber(itemList.financedBudget);
          // console.log(
          //   budgetButtonInfo,
          //   convertUSDToNumber(itemList.financedBudget),
          //   oldRatio,
          //   convertUSDToNumber(itemList.financedBudget) == 0,
          //   convertUSDToNumber(itemList.budget) * oldRatio,
          //   nonRedoTotalFB
          // );
          nonRedoTotalFB = nonRedoTotalFB + calFB;
        });
      }
    });
    // let newTotalBudget =
    //   budgetReviewResponse.updatedTotalRehabBudget ??
    //   budgetButtonInfo.rehabBudget;
    let totalRemainingBudget: number =
      convertUSDToNumber(budgetButtonInfo.financedBudgetAmount) -
      nonRedoTotalFB -
      totalDisbursedAmount;
    return totalRemainingBudget;
  } catch (e) {
    console.error("Error in calculate RemainingAllBudget ", e);
    return 0;
  }
}

function calculateNewFinancedBudget(
  newLineItem: any[],
  budgetReviewResponse: any,
  budgetButtonInfo: any
) {
  let balanceAmount: number = 0;
  let totalRemainingBudget: number = 0;
  let newFinancedItemList: any[] = [];
  let totalBudget = 0;
  let totalDisbursedAmount = 0;
  newLineItem.forEach((item) => {
    const isNewLineItem =
      typeof item.budgetLineItemId === "string" &&
      item.budgetLineItemId.startsWith("tempId_");
    const FormattedBudget = Number(convertUSDToNumber(item.budget));
    const remainingFinance =
      item.minBudgetAllowed === 0 || isNewLineItem
        ? FormattedBudget
        : item.minBudgetAllowed == item.maxBudgetAllowed //100% disbursed
        ? 0
        : FormattedBudget - item.minBudgetAllowed;
    if (isNewLineItem) {
      totalBudget = totalBudget + FormattedBudget;
      // totalDisbursedAmount = totalDisbursedAmount + 0;
    } else {
      totalBudget = totalBudget + Number(item.maxBudgetAllowed);
      totalDisbursedAmount =
        totalDisbursedAmount + Number(item.minBudgetAllowed);
    }
    balanceAmount = balanceAmount + remainingFinance;
    if (isNaN(totalDisbursedAmount)) {
      console.error(
        "Error in calculation totalDisbursedAmount",
        Number(item.minBudgetAllowed),
        item,
        totalDisbursedAmount
      );
    }
    // if (item.item === "Hvac") {
    //   console.log(remainingFinance, budgetButtonInfo.rehabBudget, item);
    //   // debugger;
    // }
    if (isNaN(remainingFinance)) {
      console.error("Error with new calculation...", item);
      return;
    }

    newFinancedItemList.push({
      ...item,
      remainingFinance
    });
  });
  totalRemainingBudget = calculateRemainingAllBudget(
    totalDisbursedAmount,
    budgetButtonInfo,
    budgetReviewResponse
  );
  // convertUSDToNumber(budgetButtonInfo.financedBudgetAmount) -
  //   totalDisbursedAmount;

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<,
  // let totRatio = 0;
  const formatttedData =
    totalRemainingBudget === 0
      ? newFinancedItemList
      : newFinancedItemList.map((item) => {
          // totRatio = totRatio + item.remainingFinance / balanceAmount;
          const remainingFinanceBudget = (
            (item.remainingFinance / balanceAmount) *
            totalRemainingBudget
          ).toFixed(4);

          // if (item.budgetLineItemId === 16623) {
          //   debugger;
          // }
          let newFB =
            item.remainingFinance === 0 //100% disbursed
              ? convertUSDToNumber(item.maxBudgetAllowed)
              : item.maxBudgetAllowed != item.minBudgetAllowed &&
                item.minBudgetAllowed !== 0 // between 0% & 100% disbursed
              ? convertNumberToUSD(
                  Number(remainingFinanceBudget) + item.minBudgetAllowed
                )
              : convertNumberToUSD(remainingFinanceBudget);
          if (Number(newFB) <= 0) {
            console.error("Financed BUdget is getting ZERO for item", item);
          }
          const tempItem = {
            ...item,
            financedBudget: newFB
          };

          return tempItem;
        });
  // console.log({ totRatio });
  return formatttedData;
}

export const InternalBudgetReviewWrapper = () => {
  const dispatch = useDispatch();
  const [budgetDocs, setbudgetDocs] = useState<any[]>([]);
  const [budgetExtractionGetResponse, setBudgetExtractionGetResponse] =
    useState<any[]>([]);
  const { loanId, loanStage, loanType } = useParams<URLParamsType>() as any;
  const [budgetDocsSelected, setBudgetDocsSelected] = useState<any>("");
  const [extractiotnStatus, setExtractionStatus] = useState<
    ExtractionProcessState | "Data Loading"
  >("Data Loading");
  const [budgetDocsOption, setbudgetDocsOption] = useState<any[]>([]);
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  const { irQCVMAdminMap } = useSelector<RootState, ILPStore>(
    (state) => state.ILPStore
  );
  const propertyDetails = useSelector<RootState, any[]>(
    (state) => state.createLoanStore.loanDetails.propertyDetails
  );
  const [isApprovalView, setIsApprovalView] = useState<boolean>(false);
  const [showReadyForReview, setShowReadyForReview] = useState<boolean>(false);
  const [userIdMapping, setUserIdMapping] = useState<any>({});
  const {
    budgetStatusHistory,
    BulkBudgetSum,
    budgetReviewHeader,
    budgetApprovalHistory,
    budgetButtonInfo,
    budgetReviewResponse,
    selectedBudgetDoc,
    lineItemsList,
    isLineItemsEditDisable,
    budgetChatsData,
    budgetPropData,
    redoValidationItems
  } = useSelector<RootState, BudgetReviewStore>(
    (state) => state.budgetReviewStore
  );
  const { startPolling, stopPolling } = usePolling();
  const { loanState } = loanDetails;
  const { tasks } = useSelector<RootState, any>((state) => state.TasksStore);
  const [reviewerApprover, setReviewerApprover] = useState<{
    reviewerName: string;
    approverName: string;
  }>({
    reviewerName: "",
    approverName: ""
  });
  const [borrowerOriginator, setBorrowerOriginator] = useState<{
    borrowersNames: string;
    originatorName: string;
  }>({
    borrowersNames: "",
    originatorName: ""
  });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isDrawManagerAndOngoingDraws, setIsDrawManagerAndOngoingDraws] =
    useState<boolean>(false);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState<boolean>(true);
  const [isApprovalDisabled, setIsApprovalDisabled] = useState<boolean>(true);
  const [isRejectDisabled, setIsRejectDisabled] = useState<boolean>(true);
  const [isRedoDisabled, setIsRedoDisabled] = useState<boolean>(true);
  const [isPLDView, setIsPLDView] = useState<boolean>(false);

  // let { isBudgetRedoDisabled } = useSelector<RootState, DrawRequestStore>(
  //   (state) => state.drawRequestStore
  // );

  useEffect(() => {
    if (!loanDetails) return;
    let budgetAmount =
      budgetReviewResponse?.updatedTotalRehabBudget && isPLDView
        ? budgetReviewResponse.updatedTotalRehabBudget
        : budgetReviewResponse?.budgetApprovalStatus === "Approved"
        ? budgetReviewResponse.submittedTotalRehabBudget
        : loanDetails.bridgeLoanEconomics.payload.budgetAmount;

    let financedBudgetAmount =
      loanDetails.bridgeLoanEconomics.payload.financedBudgetAmount;
    if (!(budgetAmount && financedBudgetAmount)) return;
    let FinancedPercent =
      (convertUSDToNumber(financedBudgetAmount) /
        convertUSDToNumber(budgetAmount)) *
      100;
    let budgetButtonInfo = {
      rehabBudget: convertNumberToUSD(budgetAmount) || budgetAmount,
      financedBudgetAmount: financedBudgetAmount,
      financedBudgetPercentage: FinancedPercent,
      loanDetailsBudgetAmount:
        loanDetails.bridgeLoanEconomics.payload.budgetAmount
    };

    dispatch(updateBudgetButtonInfo(budgetButtonInfo));
  }, [
    loanDetails,
    isPLDView,
    dispatch,
    budgetReviewResponse?.updatedTotalRehabBudget
  ]);

  const updateTotalBudgetCB = async (newBudget: string) => {
    let financedBudgetAmount =
      loanDetails.bridgeLoanEconomics.payload.financedBudgetAmount;
    if (!(newBudget && financedBudgetAmount)) return;
    try {
      await updateTotalBudgetAPI(loanId, newBudget);
      let FinancedPercent =
        (convertUSDToNumber(financedBudgetAmount) /
          convertUSDToNumber(newBudget)) *
        100;
      let newBudgetButtonInfo = {
        rehabBudget: newBudget,
        financedBudgetAmount,
        financedBudgetPercentage: FinancedPercent,
        loanDetailsBudgetAmount: budgetButtonInfo.loanDetailsBudgetAmount
      };
      dispatch(updateBudgetButtonInfo(newBudgetButtonInfo));

      if (redoValidationItems.length === 0) {
        //update all * by FinancedPercent
      } else {
        let balanceAmount: number = 0;
        let totalRemainingBudget: number = 0;
        let newFinancedItemList: any[] = [];
        let totalBudget = 0;
        let totalDisbursedAmount = 0;
        lineItemsList.forEach((item) => {
          const remainingFinance =
            Number(convertUSDToNumber(item.budget)) -
            Number(item.minBudgetAllowed);
          totalBudget = totalBudget + Number(convertUSDToNumber(item.budget));
          totalDisbursedAmount =
            totalDisbursedAmount + Number(item.minBudgetAllowed);
          balanceAmount = balanceAmount + remainingFinance;

          if (isNaN(remainingFinance)) {
            console.error("Error in New Calculation..");
            return;
          }

          newFinancedItemList.push({
            ...item,
            remainingFinance
          });
        });
        totalRemainingBudget =
          convertUSDToNumber(newBudgetButtonInfo.financedBudgetAmount) -
          totalDisbursedAmount;
        // totalBudget - totalDisbursedAmount;
        // dispatch(setBalanceDisbursedAmount(balanceAmount));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const budgetButtonInfoRef = useRef(budgetButtonInfo);

  useEffect(() => {
    budgetButtonInfoRef.current = budgetButtonInfo;
  }, [budgetButtonInfo]);

  const getCommentsDataAndBudgetDataV2 = async (
    selectedBudgetDocId: number,
    selectedBudgetData: any,
    newReviewResponse?: any
  ) => {
    try {
      dispatch(showLoader());
      let resp = await getCommentsList(`${selectedBudgetDocId}`);
      dispatch(hideLoader());
      let selectedPropId = "";
      let redoItems = [];
      selectedBudgetDoc?.assets &&
        selectedBudgetDoc?.assets.filter((item: any) => {
          if (item.assetName === "PROPERTY") {
            selectedPropId = item.assetExternalId;
          }
        });
      if (redoValidationItems[selectedPropId]) {
        redoItems = redoValidationItems[selectedPropId];
      } else {
        redoItems = await getRedoValidationItems(
          loanId,
          selectedPropId,
          dispatch
        );
      }
      dispatch(
        getBudgetReviewDataV2(
          resp,
          selectedBudgetData.budgetLineItemList,
          budgetButtonInfoRef.current.financedBudgetPercentage,
          propertyDetails,
          budgetExtractionGetResponse,
          budgetChatsData,
          redoItems,
          budgetReviewHeader,
          newReviewResponse || budgetReviewResponse,
          selectedBudgetData.isRedoInProcess ? getRedoValidationCB : undefined
        )
      );
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (Object.keys(irQCVMAdminMap).length) return;
    getListOfAssignee([
      InternalRolesEnum.LAT_IR,
      InternalRolesEnum.LAT_QC,
      InternalRolesEnum.LAT_VM,
      InternalRolesEnum.LAT_ADMIN,
      InternalRolesEnum.LAT_APPROVER,
      InternalRolesEnum.LAT_INQUIRY
    ]).then((res: any) => {
      dispatch(setILPUserList(res.data));
    });
  }, [irQCVMAdminMap, dispatch]);

  useEffect(() => {
    let history = [...budgetApprovalHistory, ...budgetStatusHistory];
    if (!history.length) return;
    async function fetchDetails() {
      let uniqueUser = [...new Set(history.map((item) => item.assignedBy))];
      uniqueUser = uniqueUser.filter((item) => item !== "None");
      const data = await getUserDetails({ partyIds: uniqueUser });
      let userData: any = {};
      data.forEach((item: any) => {
        userData = {
          ...userData,
          ...{
            [item.partyId]: {
              firstName: item.firstName,
              lastName: item.lastName
            }
          }
        };
      });
      setUserIdMapping(userData);
    }
    fetchDetails();
  }, [budgetStatusHistory, budgetApprovalHistory]);

  useEffect(() => {
    if (!propertyDetails.length) return;
    const { street_line, state, city, zipcode } =
      propertyDetails[0].propertyLocation.payload.address;
    const selectedPropInfo = {
      addressLine1: street_line,
      state,
      city,
      postalCode: zipcode
    };
    dispatch(
      updateSelectedPropDetails({
        selectedPropInfo,
        selectedPropId: propertyDetails[0].loanPropertyOrder
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDetails]);

  useEffect(() => {
    if (!loanId) return;
    let reqBody = {
      resourceIds: [loanId],
      resourceTypes: ["LOAN"],
      taskNames: [
        taskNames.TAG,
        taskNames.SPLIT,
        taskNames.REVIEW,
        taskNames.RECONCILE,
        taskNames.BUDGET_REVIEW,
        taskNames.BUDGET_APPROVE
      ]
    };
    dispatch(getBudgetHistory(reqBody, loanId));
  }, [loanId, dispatch]);

  useEffect(() => {
    //updatePropertyDetails
    if (budgetDocs.length === 0) return;
    let tempOptions = [];
    if (
      budgetStatusHistory.length &&
      isLoginPartyId(budgetStatusHistory[0].assigneePartyId) &&
      [
        budgetReviewStatusEnum.InProgress,
        budgetReviewStatusEnum.ReadyForBudgetReview
      ].includes(budgetReviewHeader.budgetReviewStatus)
    ) {
      tempOptions = budgetDocs;
    }
    if (!budgetReviewHeader?.selectedPropId) return;
    const selectDocs = budgetDocs.find((pr: any) =>
      pr.assets.find(
        // eslint-disable-next-line
        (it: any) => it.assetExternalId == budgetReviewHeader?.selectedPropId
      )
    );
    dispatch(setSelectedBudgetDoc(selectDocs));
    // eslint-disable-next-line
  }, [budgetDocs, budgetApprovalHistory, dispatch]);

  useEffect(() => {
    if (!isRole(DrawRolesEnum.DRAW_MANAGER)) {
      setIsDrawManagerAndOngoingDraws(true);
      return;
    }
    getDrawDetails();
  }, []);

  function getDrawDetails() {
    const isOrig = isOrigUser();
    const payload = {
      tableFilterConfig: {
        stage: [
          "Draw Submitted",
          "Initial Approval",
          "Final Approval",
          "In Progress",
          "Wire Unsuccessful",
          "Hold",
          "Draw In Queue",
          "Originator Pending"
        ]
      },
      tableSearchConfig: {
        toorakLoanId: loanId
      },
      tableSortConfig: {
        drawId: "DESC"
      },
      globalSearch: "",
      pageLimit: 25,
      page: 1
    };
    const cb = (drawsCount: number) => {
      if (drawsCount == 0) {
        setIsDrawManagerAndOngoingDraws(false);
      } else {
        setIsDrawManagerAndOngoingDraws(true);
      }
    };
    dispatch(getDrawRequestData(payload, isOrig, 1, 25, cb));
  }

  useEffect(() => {
    if (!budgetDocsSelected) return;
    const isPLDSource = isPLDFlow();
    let pageSource = sessionStorage.getItem("budgetReviewSource");
    // if (!pageSource) return;
    if (isRole(DrawRolesEnum.DRAW_MANAGER)) {
      setIsRedoDisabled(isDrawManagerAndOngoingDraws);
    } else {
      if (pageSource && pageSource === "ILP Page") {
        return loanDetails.loanState === LoanStatusEnum.Purchased
          ? setIsRedoDisabled(true)
          : setIsRedoDisabled(false);
      }
    }
    if (isPLDSource) {
      setIsPLDView(true);
    }
    // if (isPLDSource) {
    //   setIsPLDView(true);
    //   let isBudgetRedoDisabled = sessionStorage.getItem(
    //     "updateBudgetReviewRedoFromPLD"
    //   );
    //   if (!isBudgetRedoDisabled) return setIsRedoDisabled(false);
    //   try {
    //     // Parse and ensure the value is a boolean
    //     isBudgetRedoDisabled = JSON.parse(isBudgetRedoDisabled);
    //     if (typeof isBudgetRedoDisabled !== "boolean") {
    //       return console.error("Parsed value is not a boolean", {
    //         isBudgetRedoDisabled
    //       });
    //     }
    //     setIsRedoDisabled(isBudgetRedoDisabled);
    //   } catch (error) {
    //     console.error("Error parsing session storage value:", error);
    //     setIsRedoDisabled(false); // Fallback in case of an error
    //   }
    // }
    //eslint-disable-next-line
  }, [budgetDocsSelected, isDrawManagerAndOngoingDraws]);

  useEffect(() => {
    if (!Object.keys(irQCVMAdminMap).length) return;
    let tempReviewer =
      (budgetStatusHistory.length &&
        irQCVMAdminMap[budgetStatusHistory[0].assigneePartyId]) ||
      "--";
    let tempApprover =
      (budgetApprovalHistory.length &&
        irQCVMAdminMap[budgetApprovalHistory[0].assigneePartyId]) ||
      "--";
    setReviewerApprover({
      reviewerName: tempReviewer,
      approverName: tempApprover
    });
    // setApproverName(tempApprover);
  }, [irQCVMAdminMap, budgetStatusHistory, budgetApprovalHistory]);

  useEffect(() => {
    if (!loanDetails) return;
    const BorrowerData = loanDetails.bridgeLoanBorrowerInformation;
    const BorrowerNameList = BorrowerData.filter(
      (item: { payload: { borrowingEntityName: any; lastName: any } }) => {
        const { borrowingEntityName } = item.payload;
        return borrowingEntityName; // Filter out undefined and empty string values for borrowingEntityName
      }
    ).map((item: { payload: { borrowingEntityName: any; lastName: any } }) => {
      const { borrowingEntityName, lastName } = item.payload;
      return `${borrowingEntityName} ${lastName || ""}`;
    });
    loanDetails.originatorInfo &&
      setBorrowerOriginator({
        borrowersNames: BorrowerNameList.join(","),
        originatorName: loanDetails.originatorInfo?.accountName
      });
    // setBorrowersNames(BorrowerNameList.join(","));
    // loanDetails.originatorInfo &&
    //   setOriginatorName(loanDetails.originatorInfo.accountName);
  }, [loanDetails]);

  useEffect(() => {
    if (!(loanId && loanStage)) return;
    getDataByLoanID(loanId, loanStage, dispatch);
    getPropDataByLoanID(loanId, loanStage, loanType, dispatch);
    // dispatch(getBulkBudgetReviewDatav2(loanId, ()=>{}));
    // eslint-disable-next-line
  }, [loanId, loanStage]);

  useEffect(() => {
    //updatePropertyDetails
    if (budgetDocs.length === 0) return;

    setbudgetDocsOption(budgetDocs);
    if (!budgetReviewHeader?.selectedPropId) return;

    const selectDocs = budgetDocs.find((pr: any) =>
      pr.assets.find(
        // eslint-disable-next-line
        (it: any) => it.assetExternalId == budgetReviewHeader?.selectedPropId
      )
    );
    setBudgetDocsSelected(selectDocs); //default value
    dispatch(setSelectedBudgetDoc(selectDocs));
    // eslint-disable-next-line
  }, [budgetDocs, budgetApprovalHistory, dispatch, isLineItemsEditDisable]);

  useEffect(() => {
    if (!selectedBudgetDoc || !budgetDocsSelected) return;
    if (budgetDocsSelected.id === selectedBudgetDoc.id) return;
    setBudgetDocsSelected(selectedBudgetDoc);
    dispatch(setSelectedBudgetDoc(selectedBudgetDoc));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBudgetDoc, budgetDocsSelected]);

  const getBudgetDocs = async () => {
    try {
      const [prePurchaseList, postCloseList] = (await Promise.all([
        getDocuments(
          loanId,
          `${loanStage}-prePurchase`,
          `${loanStage}-prePurchase`,
          loanState,
          true
        ) as any,
        getDocuments(
          loanId,
          `${loanStage}-postClose`,
          `${loanStage}-postClose`,
          loanState
        )
      ])) as any;

      const budgetDocs = filterObjectsByTag([
        ...postCloseList.response.data,
        ...prePurchaseList.response.data
      ]);
      if (budgetDocs.length) {
        const tempBudgetFilesList = budgetDocs.map(
          (fileData: { name: string; id: string }) => {
            const fileType = fileData.name.split(".").pop()?.toLowerCase();
            return {
              ...fileData,
              fileType,
              ...{
                title: fileData.name
              }
            };
          }
        );
        setbudgetDocs(tempBudgetFilesList);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!selectedBudgetDoc?.id || extractiotnStatus === "Data Loading") return;
    if (ExtractionInProgressState?.includes(extractiotnStatus)) return;
    dispatch(
      getBudgetData(
        loanId,
        selectedBudgetDoc.id,
        getCommentsDataAndBudgetDataV2
      )
    );
  }, [loanId, selectedBudgetDoc, extractiotnStatus]);

  async function handleItemDelete(payload: any[]) {
    try {
      dispatch(showLoader());
      const resp = await deleteLineItems(payload, loanId);
      dispatch(hideLoader());
      return Promise.resolve(resp);
    } catch (err) {
      console.error(err);
      dispatch(hideLoader());
      return Promise.reject(err);
    }
  }

  useEffect(() => {
    let pageSource = sessionStorage.getItem("budgetReviewSource");
    const isPLDDrawManagerOrDrawMember =
      isRole(DrawRolesEnum.DRAW_MEMBER) || isRole(DrawRolesEnum.DRAW_MANAGER);
    const isApproverOrDrawManagerOrDrawMember =
      isPLDDrawManagerOrDrawMember ||
      (budgetApprovalHistory?.length > 0 &&
        isLoginPartyId(budgetApprovalHistory[0].assigneePartyId));
    const isReviewerOrDrawManagerOrDrawMember =
      isPLDDrawManagerOrDrawMember ||
      (budgetStatusHistory?.length > 0 &&
        isLoginPartyId(budgetStatusHistory[0].assigneePartyId));
    if (
      pageSource &&
      pageSource === "PLD Page" &&
      !isRole(DrawRolesEnum.DRAW_MANAGER)
    ) {
      //only AM_DRAW_MANAGER role can edit budget review, if coming from PLD
      setIsApprovalDisabled(true);
      setIsRejectDisabled(true);
      setIsFieldsDisabled(true);
      setShowReadyForReview(false);
      setIsApprovalView(false);
      return;
    }
    if (
      editableStatuses?.includes(budgetReviewHeader.budgetReviewStatus) &&
      isReviewerOrDrawManagerOrDrawMember
    ) {
      setShowReadyForReview(true);
      setIsApprovalDisabled(false);
      setIsRejectDisabled(true);
      setShowApproveBttn(false);
      setIsFieldsDisabled(false);
      setIsApprovalView(false);
    } else if (
      approvalStatuses?.includes(budgetReviewHeader.budgetReviewStatus) &&
      isApproverOrDrawManagerOrDrawMember
    ) {
      setShowReadyForReview(false);
      setIsApprovalDisabled(false);
      setIsRejectDisabled(false);
      setShowApproveBttn(true);
      setIsFieldsDisabled(false);
      setIsApprovalView(true);
    } else if (
      disableStatuses?.includes(budgetReviewHeader.budgetReviewStatus) &&
      isApproverOrDrawManagerOrDrawMember
    ) {
      setShowReadyForReview(false);
      setIsApprovalDisabled(true);
      setIsRejectDisabled(true);
      setShowApproveBttn(true);
      setIsFieldsDisabled(true);
      setIsApprovalView(true);
    } else {
      if (isReviewerOrDrawManagerOrDrawMember) {
        setShowReadyForReview(true);
        setShowApproveBttn(false);
      } else if (isApproverOrDrawManagerOrDrawMember) {
        setShowApproveBttn(true);
        setShowReadyForReview(false);
      } else {
        setShowReadyForReview(false);
        setShowApproveBttn(false);
      }

      setIsApprovalDisabled(true);
      setIsRejectDisabled(true);
      setIsFieldsDisabled(true);
      setIsApprovalView(false);
    }
  }, [
    dispatch,
    budgetReviewResponse,
    budgetApprovalHistory,
    budgetStatusHistory,
    budgetPropData,
    budgetReviewHeader.budgetReviewStatus
  ]);

  const [showApproveBttn, setShowApproveBttn] = useState<boolean>(false);
  const {
    Approved,
    Rejected,
    InProgress,
    LoanSubmitted,
    ReadyForBudgetApproval,
    ReadyForBudgetReview
  } = budgetReviewStatusEnum;

  useEffect(() => {
    const isPLDDrawManagerOrDrawMember =
      isRole(DrawRolesEnum.DRAW_MEMBER) || isRole(DrawRolesEnum.DRAW_MANAGER);
    if (
      isPLDDrawManagerOrDrawMember ||
      (budgetApprovalHistory.length &&
        isLoginPartyId(budgetApprovalHistory[0].assigneePartyId))
    ) {
      setShowApproveBttn(true);
    } else setShowApproveBttn(false);
  }, [budgetApprovalHistory, budgetReviewHeader.budgetReviewStatus]);

  useEffect(() => {
    const isFirstApprovalAssigneePartyId = budgetApprovalHistory.length
      ? isLoginPartyId(budgetApprovalHistory[0].assigneePartyId)
      : false;
    const isPLDDrawManagerOrDrawMember =
      isRole(DrawRolesEnum.DRAW_MEMBER) || isRole(DrawRolesEnum.DRAW_MANAGER);
    setIsRejectDisabled(
      isFirstApprovalAssigneePartyId || isPLDDrawManagerOrDrawMember
        ? ![ReadyForBudgetApproval].includes(
            budgetReviewHeader.budgetReviewStatus
          )
        : true
    );

    // eslint-disable-next-line
  }, [budgetReviewHeader.budgetReviewStatus, budgetApprovalHistory]);

  useEffect(
    () => {
      const isFirstAssigneePartyId = budgetStatusHistory.length
        ? isLoginPartyId(budgetStatusHistory[0].assigneePartyId) &&
          [ReadyForBudgetReview, InProgress].includes(
            budgetReviewHeader.budgetReviewStatus
          )
        : false;
      const isPLDDrawManagerOrDrawMember =
        isRole(DrawRolesEnum.DRAW_MEMBER) || isRole(DrawRolesEnum.DRAW_MANAGER);
      if (isPLDDrawManagerOrDrawMember) {
        setIsApprovalDisabled(
          ![ReadyForBudgetApproval].includes(
            budgetReviewHeader.budgetReviewStatus
          )
        );
        setIsDisabled(false);
        return;
      }
      setIsDisabled(!isFirstAssigneePartyId);

      const isFirstApprovalAssigneePartyId = budgetApprovalHistory.length
        ? isLoginPartyId(budgetApprovalHistory[0].assigneePartyId)
        : false;
      setIsApprovalDisabled(
        isFirstApprovalAssigneePartyId
          ? ![ReadyForBudgetApproval].includes(
              budgetReviewHeader.budgetReviewStatus
            )
          : true
      );
    },
    // eslint-disable-next-line
    [
      BulkBudgetSum,
      budgetReviewHeader.budgetReviewStatus,
      showApproveBttn,
      budgetStatusHistory,
      budgetApprovalHistory
    ]
  );

  const updateWithEmptyLineItems = useCallback(
    (response?: any) => {
      // function cb(resp: any) {
      //   let formattedLineItems = JSON.parse(
      //     JSON.stringify([...resp.extractedInfoProcessed.lineItems])
      //   );
      //   for (const item of formattedLineItems) {
      //     item.budget = `$${item.budget}.00`;
      //   }
      //   setFieldsData(formattedLineItems);
      //   // setIsManualEntry(true);
      // }
      const newLineItems = addEmptyLineItems({ val: 10 }, [], true);
      updatePredictionStatus(
        response ? response : budgetReviewResponse,
        response ? response.processState : budgetReviewResponse.processState,
        newLineItems,
        dispatch
        // cb
      );
    },
    [budgetReviewResponse, dispatch]
  );

  const onPollSuccess = (response: any, isManualEntry: boolean) => {
    setExtractionStatus(response[0]?.processState);
    setBudgetExtractionGetResponse(response[0]);
    if (
      ExtractionInProgressState.includes(response[0]?.processState) &&
      isManualEntry
    ) {
      stopPolling();
    }
    if (ExtractionInProgressState?.includes(response[0]?.processState)) {
      return true; // Continue polling
    }
    //when status moved to prediction completed
    stopPolling();
    if (isManualEntry) {
      updateWithEmptyLineItems();
    } else {
      getBudgetDocs();
    }
    // if (
    //   response.budgetDocumentInfoList &&
    //   response.budgetDocumentInfoList[0]?.budgetLineItems?.length
    // ) {
    //   // updateLineReduxData(
    //   //   response,
    //   //   dispatch,
    //   //   budgetButtonInfo.financedBudgetPercentage,
    //   //   // commentsList,
    //   //   true
    //   // );
    //   // dispatch(setBudgetGetResponse(response));
    // } else {
    //   updateWithEmptyLineItems();
    // }
  };

  const onPollTimeExceed = () => {
    console.error("Polling time exceed");
  };

  const startPollingExcecution = (isManualEntry: boolean) => {
    const url = `${
      getConfig().apiUrl
    }/ocr/extraction/${loanId}/documents?tags=REBD,SCOW`;
    startPolling({
      url,
      method: "GET",
      interval: 3000,
      onSuccess: (res) => {
        return onPollSuccess(res.data, isManualEntry);
      },
      onExceedingPollingWaitTime: () => {
        onPollTimeExceed();
      },
      pollingWaitTime: 300000 // allow 5 minutes polling
    });
  };

  useEffect(() => {
    if (!loanId) return;
    if (!propertyDetails.length) return;
    startPollingExcecution(false);
  }, [loanId, propertyDetails]);

  function handlePropTypeClickCB(
    title: any,
    fieldsData: any,
    lineItemObj: any
  ) {
    let payload = {
      budgetDocumentInfoId: lineItemObj.budgetDocumentInfoId,
      selectionMode: lineItemObj.selectionMode.toUpperCase()
    };
    updateSelectionMode(lineItemObj.DocumentId, payload);
  }

  function handleCommonTaskUpdate(
    tasks: any[],
    budgetStatusHistory: any[],
    budgetApprovalHistory: any[],
    header: string,
    showCommentPopup: any,
    isFirstApprovalAssigneePartyId?: boolean
  ) {
    const userId = getCookie("person_id") || "";
    const updatedTasks = JSON.parse(JSON.stringify(tasks || []));

    const reviewAssignedBy = getAssignedBy(
      updatedTasks,
      taskNames.BUDGET_REVIEW,
      userId
    );
    const approveAssignedBy = getAssignedBy(
      updatedTasks,
      taskNames.BUDGET_APPROVE,
      userId
    );

    let assignedReviewTasksObj = buildTaskObject(
      "budgetReview",
      reviewAssignedBy,
      budgetStatusHistory[0].assigneePartyId,
      header === "Budget Review Comment" || isFirstApprovalAssigneePartyId
        ? ReadyForBudgetApproval
        : header === "Redo Budget Review"
        ? isRole(DrawRolesEnum.DRAW_MANAGER)
          ? ReadyForBudgetApproval
          : InProgress
        : Approved,
      budgetStatusHistory[0].taskId,
      showCommentPopup.comment
    );

    let assignedApproveTasksObj = buildTaskObject(
      "budgetApprove",
      approveAssignedBy,
      budgetApprovalHistory[0].assigneePartyId,
      header === "Budget Review Comment"
        ? budgetApproveStatusEnum.ReadyForBudgetApproval
        : header === "Redo Budget Review"
        ? isRole(DrawRolesEnum.DRAW_MANAGER)
          ? budgetApproveStatusEnum.ReadyForBudgetApproval
          : budgetApproveStatusEnum.Pending
        : budgetApproveStatusEnum.Approved,
      budgetApprovalHistory[0].taskId,
      showCommentPopup.comment
    );
    if (header === "Rejection Comment") {
      assignedReviewTasksObj = {
        ...assignedReviewTasksObj,
        ...{ taskStatus: Rejected }
      };
      assignedApproveTasksObj = {
        ...assignedApproveTasksObj,
        ...{ taskStatus: budgetApproveStatusEnum.Rejected }
      };
    }
    return {
      assignedReviewTasksObj,
      assignedApproveTasksObj
    };
  }
  async function handleStatusUpdate(
    header: string,
    showCommentPopup: any,
    updatedTotalRehabBudget?: any
  ) {
    const { assignedReviewTasksObj, assignedApproveTasksObj } =
      handleCommonTaskUpdate(
        tasks,
        budgetStatusHistory,
        budgetApprovalHistory,
        header,
        showCommentPopup
      );

    let propertyData = loanDetails.propertyDetails?.map((prop: any) => {
      return {
        propertyId: prop.propertyId,
        zipcode: prop.propertyLocation?.payload?.zipCode,
        addressLine1: prop.propertyLocation?.payload?.address?.street_line,
        ...prop.propertyLocation?.payload
      };
    });
    const payload = {
      budgetApprovalStatus: assignedReviewTasksObj.taskStatus,
      approvedBy: assignedApproveTasksObj.assignedBy,
      reviewedBy: assignedReviewTasksObj.assignedBy,
      propertyData,
      primaryLoanId: loanDetails?.bridgeLoanInformation?.payload?.primaryLoanID
    };
    try {
      await updateBudgetStatusV2(loanId, payload, dispatch);

      dispatch(
        updateStatusOnTask(
          loanId,
          [assignedReviewTasksObj, assignedApproveTasksObj],
          budgetStatusHistory,
          budgetApprovalHistory
        )
      );
      if (["Approval Comment", "Rejection Comment"].includes(header)) {
        const isPLDSource = isPLDFlow();
        if (
          header === "Approval Comment" &&
          updatedTotalRehabBudget &&
          isPLDSource
        ) {
          //update in TC
          const latestCommentObj = {
            op: "replace",
            loanState,
            auditComments: {
              "data.loan.loanEconomics.totalBudgetAmount":
                showCommentPopup.comment
            },
            loanData: {
              loanEconomics: {
                totalBudgetAmount: updatedTotalRehabBudget
              },
              loanType: "BridgeLoan",
              loanId,
              loanTypeId: 2
            }
          };
          patchLoanData(
            latestCommentObj,
            "bridge",
            loanId,
            loanStage.toUpperCase()
          );
        }
        selectedBudgetDoc &&
          dispatch(
            getBudgetData(
              loanId,
              selectedBudgetDoc.id,
              getCommentsDataAndBudgetDataV2
            )
          );
      }
      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  }

  async function calculateAndUpdate(
    selectedPropId: string,
    updatedLineItems: any[],
    newBudgetReviewResponse?: any
  ) {
    const newData = await getPrevDrawInfo(selectedPropId, updatedLineItems);

    const newLineItems = calculateNewFinancedBudget(
      newData.formattedData,
      newBudgetReviewResponse || budgetReviewResponse,
      budgetButtonInfo
    );
    dispatch(updateLineItemList(newLineItems));
  }

  /**
   * For version change and property change flow
 
   */
  async function addExistingCommentsAndRedoValidation(
    lineObject: any[],
    isRedoValidationReq: boolean,
    newBudgetChatsData: any,
    newPropertyId?: string
  ) {
    let lineItemsWithoutChats: any[] = [];
    let updatedWithComments: any[] = [];
    lineObject.forEach((item) => {
      // for AM-2527
      const isPLDSource = isPLDFlow();
      let newFnancedBudget =
        convertUSDToNumber(item.financedBudget) === 0
          ? isPLDSource
            ? item.budget
            : (item.budget * budgetButtonInfo.financedBudgetPercentage) / 100
          : item.financedBudget;
      if (item.commentThreadId && newBudgetChatsData[item.commentThreadId]) {
        updatedWithComments.push({
          ...item,
          comments: newBudgetChatsData[item.commentThreadId],
          financedBudget: newFnancedBudget
        });
      } else if (
        item.commentThreadId &&
        !newBudgetChatsData[item.commentThreadId]
      ) {
        lineItemsWithoutChats.push({
          ...item,
          financedBudget: newFnancedBudget
        });
      } else {
        updatedWithComments.push({ ...item, financedBudget: newFnancedBudget });
      }
      // return item;
    });
    if (lineItemsWithoutChats?.length) {
      const missingComments = await mergeChatDetails(
        lineItemsWithoutChats,
        newBudgetChatsData,
        dispatch
      );
      updatedWithComments = [...missingComments, ...updatedWithComments];
    }

    if (isRedoValidationReq) {
      if (newPropertyId && updatedWithComments.length) {
        calculateAndUpdate(newPropertyId, updatedWithComments);
      } else {
        getRedoValidationCB(updatedWithComments, undefined, newPropertyId);
      }
    } else {
      dispatch(updateLineItemList(updatedWithComments));
    }
  }

  async function getPreviousVersionDataCB(
    version: string,
    isLatestVersion: boolean,
    budgetDocSelected: any
  ) {
    const lineObject = await getPreviousVersionData(
      budgetDocSelected.id,
      version,
      budgetButtonInfo.financedBudgetPercentage,
      dispatch
    );
    addExistingCommentsAndRedoValidation(
      lineObject,
      isLatestVersion,
      budgetChatsData
    );
  }

  function handleRedoCallBack() {
    selectedBudgetDoc &&
      dispatch(
        getBudgetData(
          loanId,
          selectedBudgetDoc.id,
          getCommentsDataAndBudgetDataV2
        )
      );
  }

  // handleRedoBudgetCB using the helper
  async function handleRedoBudgetCB(
    isFirstApprovalAssigneePartyId: boolean,
    showCommentPopup: any,
    budgetDocSelected: any
  ) {
    const { assignedReviewTasksObj, assignedApproveTasksObj } =
      handleCommonTaskUpdate(
        tasks,
        budgetStatusHistory,
        budgetApprovalHistory,
        showCommentPopup.headerText,
        showCommentPopup,
        isFirstApprovalAssigneePartyId
      );
    try {
      if (!budgetDocSelected?.id) {
        console.error("Budget Doc id not found..", {
          budgetDocSelected,
          budgetDocsSelected
        });
        return Promise.reject("Budget Doc id not found..");
      }
      let selectedPropId = "";
      let { lineItemsReq } = lineItemResponse(lineItemsList);
      budgetDocSelected?.assets &&
        budgetDocSelected?.assets.filter((item: any) => {
          if (item.assetName === "PROPERTY") {
            selectedPropId = item.assetExternalId;
          }
        });
      let payload = {
        propertyIds: showCommentPopup.extraInfo,
        budgetLineItemMap: { [budgetDocSelected.id]: lineItemsReq }
      };
      // const isPLDSource = isPLDFlow();
      await redoBudgetReview(
        loanId,
        payload,
        selectedPropId,
        dispatch,
        // getRedoValidationCB
        handleRedoCallBack
      );
      dispatch(
        updateStatusOnTask(
          loanId,
          [assignedReviewTasksObj, assignedApproveTasksObj],
          budgetStatusHistory,
          budgetApprovalHistory
        )
      );
      // if (isPLDSource) {
      //   const newData = await getPrevDrawInfo(selectedPropId, newLineItems);
      //   dispatch(updateLineItemList(newData.formattedData));
      // } else {
      // }
      return Promise.resolve();
    } catch (e) {
      console.error(e);
      return Promise.reject();
    }
  }

  const getRedoValidationCB = async (
    updatedLineItems: any[],
    propertyViewData?: any,
    newPropertyId?: string,
    newBudgetReviewResponse?: any
  ) => {
    if (!updatedLineItems?.length) {
      selectedBudgetDoc &&
        dispatch(
          getBudgetData(
            loanId,
            selectedBudgetDoc.id,
            getCommentsDataAndBudgetDataV2
          )
        );
      return;
    }
    if (propertyViewData) {
      //update available versions dropdown
      let selectedBudgetPropData = {
        ...budgetPropData.find(
          (data: any) => data.externalDocumentId == budgetDocsSelected?.id
        )
      };
      const availableVersions = [
        ...(selectedBudgetPropData?.availableVersions || []),
        propertyViewData.version.toString()
      ];
      propertyViewData = {
        ...propertyViewData,
        ...{
          availableVersions
        }
      };
      let newBudgetPropData = budgetPropData.map((obj: any) =>
        obj.externalDocumentId == budgetDocsSelected?.id
          ? propertyViewData
          : obj
      );
      dispatch(updateBudgetPropData(newBudgetPropData));
    }
    let selectedPropId = "";
    if (newPropertyId) {
      selectedPropId = newPropertyId;
    } else {
      selectedBudgetDoc?.assets &&
        selectedBudgetDoc?.assets.filter((item: any) => {
          if (item.assetName === "PROPERTY") {
            selectedPropId = item.assetExternalId;
          }
        });
    }
    if (!selectedPropId) return;
    const newTotalRehabBudget =
      newBudgetReviewResponse?.updatedTotalRehabBudget ??
      budgetReviewResponse?.updatedTotalRehabBudget;
    const isPLDSource = isPLDFlow();
    if (newTotalRehabBudget && isPLDSource)
      calculateAndUpdate(
        selectedPropId,
        updatedLineItems,
        newBudgetReviewResponse
      );
    else if (isPLDSource) {
      let noFBVal = false;
      const newData = await getPrevDrawInfo(selectedPropId, updatedLineItems);
      // newData.formattedData.forEach((item)=>{item.financedBudget ==})
      for (const item of newData.formattedData) {
        if (convertUSDToNumber(item.financedBudget) == 0) {
          noFBVal = true;
          break;
        }
      }
      if (noFBVal) {
        calculateAndUpdate(
          selectedPropId,
          updatedLineItems,
          newBudgetReviewResponse
        );
      } else {
        dispatch(updateLineItemList(newData.formattedData));
      }
    } else {
      dispatch(updateLineItemList(updatedLineItems));
    }
  };

  async function createAndUpdateCommentsCB(
    comments: any[],
    newLineItemsList: any[],
    docsId: string,
    comment: string,
    row: any,
    cb: any
  ) {
    const { newList, newChatData } = await handleAddCommentProceed(
      newLineItemsList,
      docsId,
      comment,
      row,
      comments
    );
    let newbudgetChatsData = { ...budgetChatsData, ...newChatData };
    dispatch(updateBudgetChatsData(newbudgetChatsData));
    !row.commentThreadId && dispatch(updateBudgetLineItems(newList, docsId));
    dispatch(updateLineItemList(newList));
    let newBudgetPropData = budgetPropData.map((obj: any) =>
      obj.externalDocumentId == docsId
        ? {
            ...obj,
            ...{
              budgetLineItemList: newList
            }
          }
        : obj
    );
    dispatch(updateBudgetPropData(newBudgetPropData));
    cb && cb();
  }

  async function getPrevDrawInfo(
    selectedPropId: string,
    updatedLineItems: any[]
  ) {
    let redoItems: any[] = [];

    if (redoValidationItems[selectedPropId]) {
      redoItems = redoValidationItems[selectedPropId];
    } else {
      redoItems = await getRedoValidationItems(
        loanId,
        selectedPropId,
        dispatch
      );
    }
    const newData = updateWithPrevDrawData(updatedLineItems, redoItems);
    // dispatch(setBalanceDisbursedAmount(newData.balanceAmount));
    return newData;
  }

  function loaderCB(isShow: boolean) {
    if (isShow) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }

  async function handleMultiPropertyRedo(
    RedoLineItemsList: any[],
    lineItemObj: any,
    editingLineItems: any[],
    budgetReviewResponse: any
  ) {
    let promises: any[] = [];
    RedoLineItemsList.forEach((propList: any) => {
      const newlineItems =
        lineItemObj.budgetDocumentInfoId === propList.budgetDocumentInfoId
          ? editingLineItems
          : propList.budgetLineItemList;
      const newData = getPrevDrawInfo(propList.propertyId, newlineItems);
      promises.push(newData);
    });
    try {
      let newDataWithPrevList: { formattedData: any[] }[] = await Promise.all(
        promises
      );
      const mergedLineItems: any[] = [];
      newDataWithPrevList.forEach((item: any) => {
        mergedLineItems.push(...item.formattedData);
      });
      const newFBLineItems = calculateNewFinancedBudget(
        mergedLineItems,
        budgetReviewResponse,
        budgetButtonInfo
      );
      const renderedLineItems = newFBLineItems.filter(
        (item: any) => item.budgetDocumentInfoId
      );
      // console.log({ renderedLineItems, newFBLineItems });
      return renderedLineItems;
    } catch (e) {
      console.error(e);
    }
  }
  /**
   * On blur while editing nad Delete line items flow
   
   */
  async function handleLineItemOnBlur(
    fieldsData: any,
    cb: any,
    budgetDocumentInfoList: any,
    totalCalculatedData: any,
    lineItemObj: any,
    docId: string,
    editCheck: any
    // budgetTotalInfo: BudgetTotalInfo,
  ) {
    // if (!isPropertyMapped) {
    //   console.error("Property not mapped properly");
    //   setPopUpOpen(true);
    //   return;

    // }
    let lineItems = fieldsData;
    // let tempBudgetInfo = budgetTotalInfo
    //   ? budgetTotalInfo
    //   : {
    //       rehabBudget: sanitizeCurrency(`${totalBudget}`),
    //       financedBudgetAmount: sanitizeCurrency(`${totalfinancedBudget}`)
    //     };
    // if (updatedData) {
    //   lineItems = updatedData;
    // }
    const userId = getCookie("person_id") || "";
    if (
      [
        budgetReviewStatusEnum.LoanSubmitted,
        budgetReviewStatusEnum.ReadyForBudgetReview
      ].includes(budgetReviewHeader.budgetReviewStatus)
    ) {
      //update status to in progress
      const updatedTasks: any = JSON.parse(JSON.stringify(tasks));
      let assignedBy = updatedTasks?.[0]?.assignedTasks.find((task: any) =>
        [taskNames.BUDGET_REVIEW].includes(task.taskName)
      )?.assignedBy;
      if (!assignedBy || ["UnAssigned", "None"].includes(assignedBy)) {
        assignedBy = userId;
      }
      let assignedTasksObj = [
        {
          op: "replace",
          taskName: "budgetReview",
          assignedBy,
          assigneePartyId: budgetStatusHistory[0].assigneePartyId,
          taskStatus: budgetReviewStatusEnum.InProgress,
          taskId: budgetStatusHistory[0].taskId,
          comment: "Status Updated by System"
        }
      ];
      // await updateStatusOnDocument(
      //   loanId,
      //   budgetReviewStatusEnum.InProgress,
      //   dispatch
      // );
      const payload = {
        budgetApprovalStatus: budgetReviewStatusEnum.InProgress
      };
      await updateBudgetStatusV2(loanId, payload, dispatch);
      dispatch(
        updateStatusOnTask(loanId, assignedTasksObj, budgetStatusHistory)
      );
    }
    const isPLDSource = isPLDFlow();
    if (lineItemObj.isRedoInProcess && isPLDSource) {
      const newFieldsData: any[] = [];
      const RedoLineItemsList: any[] = budgetDocumentInfoList?.filter(
        (key1: any) => {
          if (key1.budgetDocumentInfoId === lineItemObj.budgetDocumentInfoId) {
            fieldsData.forEach((line: any) => {
              newFieldsData.push({
                ...line,
                budgetDocumentInfoId: lineItemObj.budgetDocumentInfoId
              });
            });
          }
          return key1.isRedoInProcess;
        }
      );

      if (RedoLineItemsList.length > 1) {
        lineItems = await handleMultiPropertyRedo(
          RedoLineItemsList,
          lineItemObj,
          newFieldsData,
          budgetReviewResponse
        );
      } else {
        let noMinBudgetVal = true;
        for (const item of lineItems) {
          if ("minBudgetAllowed" in item) {
            noMinBudgetVal = false;
            break;
          }
        }
        if (noMinBudgetVal) {
          const budgetLines = budgetPropData?.find(
            (budget: any) => budget.externalDocumentId == docId
          );
          const newData = await getPrevDrawInfo(
            budgetLines.propertyId,
            lineItems
          );
          lineItems = calculateNewFinancedBudget(
            newData.formattedData,
            budgetReviewResponse,
            budgetButtonInfo
          );
        } else {
          lineItems = calculateNewFinancedBudget(
            lineItems,
            budgetReviewResponse,
            budgetButtonInfo
          );
        }
      }
    } else {
      lineItems = lineItems.map((item: any) => {
        const tempItem = {
          ...item,
          financedBudget: convertNumberToUSD(
            (convertUSDToNumber(item.budget) *
              budgetButtonInfo.financedBudgetPercentage) /
              100
          )
        };
        return tempItem;
      });
    }
    const changedItem = editCheck
      ? lineItems.filter((item: any) => item.budgetLineItemId === editCheck.id)
      : lineItems;
    async function callBack(response: any) {
      const selectedPropId = lineItemObj.propertyId;
      const budgetLines = response.budgetDocumentInfoList?.find(
        (budget: any) => budget.externalDocumentId == docId
      );
      // let formatttedData = [...budgetLines?.budgetLineItemList];
      const isPLDSource = isPLDFlow();
      if (lineItemObj.isRedoInProcess && isPLDSource) {
        let uiList = cb({ obj: editCheck, payload: changedItem[0] });
        let latestUpdatedList = editCheck
          ? transformToReduxList(uiList)
          : budgetLines.budgetLineItemList;
        const newData = await getPrevDrawInfo(
          selectedPropId,
          latestUpdatedList
        );

        dispatch(updateLineItemList(newData.formattedData));
      } else {
        let uiList = cb({ obj: editCheck, payload: changedItem[0] });
        let latestUpdatedList = editCheck
          ? transformToReduxList(uiList)
          : budgetLines.budgetLineItemList;

        dispatch(updateLineItemList(latestUpdatedList));
        // dispatch(updateLineItemList(budgetLines?.budgetLineItemList));
      }
      // cb();
    }

    // console.log(changedItem, lineItemsList, lineItems);
    dispatch(updateBudgetLineItems(changedItem, docId, true, callBack));
  }
  return (
    <BudgetReviewWrapperSDK
      hideOldBudget={true}
      budgetData={{
        id: loanId,
        budgetDocs,
        handlePropTypeClickCB,
        budgetChatsData,
        userIdMapping,
        reviewerApprover,
        borrowerOriginator,
        loanDetails,
        budgetReviewHeader,
        BulkBudgetSum,
        budgetButtonInfo,
        budgetApprovalHistory,
        budgetStatusHistory,
        lineItemsList,
        selectedBudgetDoc,
        redoValidationItems,
        reviewHistory: [...budgetStatusHistory],
        budgetDocumentInfoList: budgetPropData
          ? updateFieldName(budgetPropData)
          : [],
        budgetReviewResponse: {
          processState: extractiotnStatus
        },
        actionButtonAccess: {
          showReadyForReview,
          isReadyForReviewDisabled: isDisabled,
          isApproveDisabled: isApprovalDisabled,
          isRejectDisabled,
          isRedoDisabled,
          showApproveBtn: showApproveBttn,
          isFieldsDisabled,
          isApprovalView,
          isPLDView
        },
        budgetDocsOption
      }}
      DocumentViewer={{
        DocumentViewerCompo: DocumentViewer,
        budgetDocsSelected,
        DocumentViewerCB: {
          documentReviewDispatch: () => {},
          onChangeHighlightSelect: () => {}
        }
      }}
      budgetCB={{
        isLoginPartyId,
        handleItemDelete,
        createAndUpdateCommentsCB,
        handleRedoBudgetCB,
        updateTotalBudgetCB,
        getPreviousVersionDataCB,
        addExistingCommentsAndRedoValidationCB:
          addExistingCommentsAndRedoValidation,
        handleStatusChangeCB: handleStatusUpdate,
        handleLineItemOnBlurCB: handleLineItemOnBlur,
        startPollingCB: startPollingExcecution,
        loaderCB
      }}
      budgetConstants={{
        budgetReviewStatusConst,
        BudgetApproverStatus,
        ExtractionInProgressState,
        ExtractionProcessState,
        budgetReviewStatusEnum
      }}
      // configList={{
      //   configItems: ConfigItems
      // }}
      budgetModals={{ ConfirmFormModal }}
    />
  );
};