import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from "react-redux";

import { LoanStatusEnum } from '@toorak/tc-common-util-sdk';

import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import BorderColorIcon from '@mui/icons-material/BorderColor';

import { valueEditing } from '../create-loan.reducer';

import { RootState } from '../../stores/rootReducer';

import { updateFormEditStatus, updateOpenCommentDialog, updateShowLockinPopup } from '../../v2/loanDetailStore.reducer';
import { LoanStage } from '@toorak/tc-common-fe-sdk';
import { isLoanOnHold } from '../../utils/AccessManagement';
import { useParams } from 'react-router-dom';
import { getLoanType } from '../../config/config';

export default function SaveEditActions(props: any) {
  // useSelector hooks
  const { loanState, loanConfig, loanDetailId, onHoldBy } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );

  const { isFormEditing, isDataEntry, fieldEditCount } = useSelector<RootState, any>((state) => state.loanDetailsStore);
  const { loanStage = "", loanType = "" } = useParams<{
      loanStage: LoanStage,
      loanType: string
    }>();

  const dispatch = useDispatch();

  let {
    currentTab,
  } = props;

  useEffect(() => {
    if (
      loanState === LoanStatusEnum.DueDiligenceReview && isFormEditing && !isDataEntry
    ) {
      dispatch(valueEditing(true));
    }
  }, [loanState, currentTab]);

  const loanOnHold = useMemo(() => {
      const holdValue: boolean = isLoanOnHold(onHoldBy);
      return holdValue;
    }, [onHoldBy]);

  return (
    <div style={{ textAlign: "right" }}>
      {
        !isFormEditing && !isDataEntry &&
        <>
          {!loanConfig?.rateLockPeriod && loanDetailId?.rateLocks?.length === 0 && loanStage !== LoanStage.fes && !loanOnHold && loanType === getLoanType[1].displayValue ? <Button
            variant="contained"
            color="info"
            onClick={() => dispatch(updateShowLockinPopup(true))}
            style={{ marginRight: "10px" }}
          >
            Lock Loan
          </Button> : null}
          <Button
            variant="contained"
            color="info"
            startIcon={<BorderColorIcon />}
            onClick={() => {
              dispatch(updateFormEditStatus({ value: true, editStatus: "editing" }));
            }}
          >
            Edit
          </Button>
        </>
      }
      {
        isFormEditing && !isDataEntry &&
        <Grid container spacing={2} xs={12} style={{ display: "flex", justifyContent: "end" }}>
          <Grid item>
            <Button disabled={fieldEditCount === 0} variant="contained" color="info" onClick={() => dispatch(updateOpenCommentDialog({ value: true }))}>Save</Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => {
              dispatch(updateFormEditStatus({ value: false, editStatus: "notSaved" }));
              dispatch(updateOpenCommentDialog({ value: false }))
            }}>Cancel</Button>
          </Grid>
        </Grid>
      }
    </div>
  )
}
