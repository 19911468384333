/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React from "react";
import { Typography, Grid } from "@mui/material";
import { IconTooltip } from "@toorak/tc-common-fe-sdk";
import { PopOverInfo } from "./PopOverInfo";

export interface KeyMetricsTextFieldProps {
  label: React.ReactNode;
  value: string;
  key: string;
  popOverData?: string[];
  infoIconData?: string;
  redirectionType: any;
  customValue?: any
}

export const openGoogleMap = (label: string) => {
  const query = `http://maps.google.com/?q=${label}`;
  window.open(query);
};

export function KeyMetricsTextField(props: KeyMetricsTextFieldProps) {
  const {
    label,
    value,
    key,
    infoIconData,
    popOverData,
    redirectionType,
    customValue
  } = props;

  function doesntHaveInvalidString(str: any) {
    if (str !== null && str !== undefined && str !== "") {
      if (str && typeof str === "string") {
        const invalidStrings = [
          "null",
          "NaN",
          "Infinity",
          "undefined",
          "Error",
          "error"
        ];
        return !invalidStrings.some(el => str.includes(el));
      }
      return true;
    }
    return false;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "none",
        width: "100%",
        padding: "14px 16px 14px 12px"
      }}
      key={key}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontSize: 12,
            color: "#32325d"
          }}
        >
          {label}
        </Typography>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center"
          }}
        >
          {infoIconData ? (
            <IconTooltip topic="Information" infoIconData={infoIconData} />
          ) : (
            ""
          )}
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} 
        style={{
          display:"flex"
        }}>
          <Typography
            style={{
              fontSize: 14,
              marginTop: 10,
              // wordBreak: "break-word",
              verticalAlign: "middle",
              //display: "inline-block",
              width: "calc(100% - 16px)",
              color:
                redirectionType && !popOverData?.length ? "#5e72e4" : "#32325d",
              cursor:
                redirectionType && !popOverData?.length ? "pointer" : "default",
              display: "flex"
            }}
            onClick={() => {
              if (redirectionType === "googleMap" && !popOverData?.length) {
                openGoogleMap(value);
              }
            }}
          >
            {customValue 
              ? <span style={{color: customValue?.color}}>{customValue?.value}</span> 
              : doesntHaveInvalidString(value) 
                ? value 
                : <span>&#8213;</span>}
          </Typography>

          {popOverData && popOverData.length > 0 && (
            <span
              style={{
                display: "inline-block",
                height: "16px",
                marginTop: "10px",
                verticalAlign: "middle",
                width: "16px"
              }}
            >
              <PopOverInfo
                popOverData={popOverData}
                redirectionType={redirectionType}
              />
            </span>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
