/* eslint-disable no-nested-ternary */
/* eslint-disable no-eval */
/* eslint-disable no-unused-expressions */
import { AppBar, Grid, Tab, Tabs, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { useParams } from "react-router-dom";
import { ToggleGroup } from "../../ui-components/ToggleGroup";
import { GuidelinesComparision } from "./GuidlinesComparision";
import { PricingComparision } from "./PricingComparision";
import { ObjectType } from "../../masterView/common";
import {
  getEligiblePartnerByLoanId,
  getEvaluationResultForPartner,
  getTemplateData
} from "../../network/apiService";
import {
  showLoader,
  hideLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { RootState } from "../../stores/rootReducer";
import { URLParamsType } from "../../utils/constants";
import { evaluation } from "../evaluation-results/EvaluationHelper";
import {
  genFormattedUserDetails,
  getPropertiesWithFailedTest
} from "../../stores/EvaluationResults/EvaluationResults.action";
import { getLatestWaiverResponse } from "../../utils/utils";
import { aggregatePropertyData } from "../summary/NewFundingSource";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column"
    },
    paper: { maxWidth: "60%" },
    sectionWrapper: {
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      padding: `12px 0`,
      display: "flex",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3),
      borderRadius: 4
    },
    hidden: {
      background: "#fff"
    },
    headerButton: {
      backgroundColor: "#fff",
      border: "1px solid #dde1f3",
      borderRadius: "16px !important",
      padding: "2px",
      margin: "1rem 1.5rem"
    },
    button: {
      backgroundColor: "#fff",
      fontSize: "12px",
      fontWeight: 400,
      border: "0px solid #5e72e4 !important",
      letterSpacing: "0.5px",
      borderRadius: "16px !important",
      marginLeft: "0px !important",
      color: theme.palette.text.secondary,
      height: "auto",
      "&.$Mui-selected": {
        backgroundColor: "#5e72e4 !important",
        fontWeight: "600 !important",
        color: "#fff !important"
      },
      "&:disabled": {
        color: "#32325d",
        opacity: 0.5,
        fontWeight: 600,
        cursor: "no-drop",
        borderColor: "#d8d5d5 !important"
      },
      textTransform: "none"
    }
  })
);

const views = [
  { label: "Eligible Partners", value: "eligiblePartners" },
  { label: "Ineligible Partners", value: "ineligiblePartners" }
];

const subViews = [
  { label: "Pricing", value: "pricing" },
  { label: "Guideline Results", value: "guidelineTests" }
];

export const formatWaiverData = (
  waiver: ObjectType,
  customerInfo: ObjectType
) => {
  const { waiverResponses, waiverId } = waiver;
  let waiverStatus = "";
  if (waiverResponses?.length > 0) {
    const waiverResponseObject = getLatestWaiverResponse(waiverResponses);
    const waiverResponseType = waiverResponseObject.length
      ? waiverResponseObject[waiverResponseObject.length - 1].responseType
      : "Requested";
    waiverStatus = waiverResponseType;
  } else if (waiverId) {
    waiverStatus = "Requested";
  }

  let waiverUserInfo: ObjectType = {};
  if (waiverStatus === "Requested") {
    waiverUserInfo =
      genFormattedUserDetails(
        customerInfo,
        waiver.createdBy,
        waiver.createdOn
      ) || {};
  } else {
    const { length } = waiverResponses;
    waiverUserInfo =
      genFormattedUserDetails(
        customerInfo,
        waiverResponses[length - 1].responseBy,
        waiverResponses[length - 1].responseDate
      ) || {};
  }
  return {
    ...waiver,
    waiverStatus,
    ...waiverUserInfo
  };
};

export const getFlattenedData = (
  propertyDetails: any,
  loanData: any,
  evaluationResults: any,
  loanId: string
): ObjectType => {
  const {
    loanEconomics,
    loanInformation,
    borrowerInformation,
    guarantorInformation,
    loanSummary
  } = loanData;
  const borrowerCount =
    borrowerInformation?.filter((borrowerInfoObj: ObjectType) => {
      return borrowerInfoObj?.payload?.borrowerType === "Individual";
    })?.length ?? 0;
  const guarantorCount = guarantorInformation?.length ?? 0;
  const guarantorAndBorrowerCount = guarantorCount + borrowerCount;
  const aggregatedPropertyData = aggregatePropertyData(propertyDetails);

  let flatData: ObjectType = {
    loanEconomics,
    loanInformation
  };
  if (Object.keys(evaluationResults).length) {
    const {
      loanCharacterization,
      loanCharacterisations,
      loanFeatures,
      borrowerInfo,
      loanEconomics: loanResultEconomics,
      loanPricing,
      toorakProceeds
    } = evaluationResults?.loanResult?.[loanId];
    const loanCharactrerisation =
      loanCharacterization || loanCharacterisations || {};

    flatData = {
      ...flatData,
      loanCharactrerisation,
      loanFeatures,
      borrowerInfo,
      loanResultEconomics,
      loanSummary,
      loanPricing,
      toorakProceeds
    };
  }
  const transformedData: ObjectType = {};
  Object.keys(flatData)?.forEach((key: string) => {
    const obj = flatData?.[key] ?? {};
    Object.keys(obj)?.forEach((fieldId: string) => {
      transformedData[`${key}.${fieldId}`] = obj[fieldId];
    });
  });
  return {
    ...transformedData,
    ...aggregatedPropertyData,
    guarantorAndBorrowerCount
  };
};

export const getPricingAndResults = (
  takeOutPartnerData: ObjectType,
  loanId: string,
  loanStage: LoanStage,
  selectedTakeOutId: string,
  waivers?: any,
  propertyDetails?: any,
  loanData?: any,
  rateLockPeriodAndExpiry?: any,
  criticalTests?: ObjectType
) => {
  return new Promise((resolve, reject) => {
    getEvaluationResultForPartner(loanId, loanStage, takeOutPartnerData.id)
      .then((resultresp: any) => {
        if (Object.keys(resultresp?.data).length) {
          const flattenedData = getFlattenedData(
            propertyDetails,
            loanData,
            resultresp?.data,
            loanId
          );
          const failTests: any[] = [];
          const passTests: any[] = [];
          const waivedTest: any[] = [];
          const MaxMinTests = {
            ...(resultresp?.data?.loanResult?.[loanId]?.maxMinParameters ?? {})
          };
          const logicTests = {
            ...(resultresp?.data?.loanResult?.[loanId]?.logicTests ?? {})
          };
          const finalCalculationTests = {
            ...(resultresp?.data?.loanResult?.[loanId]?.finalCalculation ?? {})
          };
          const tests = {
            ...MaxMinTests,
            ...logicTests,
            ...finalCalculationTests
          };
          const testNamesTemplateBody = Object.keys(tests).map(
            (testName: any) => `testNames.${testName}`
          );
          const maxMintestFailTemplateBody = Object.keys(
            resultresp?.data?.loanResult?.[loanId]?.maxMinParameters
          ).map(
            (testName: any) =>
              `loanResult.maxMinParameters.${testName}.message.FAIL`
          );
          const logictestFailTemplateBody = Object.keys(
            resultresp?.data?.loanResult?.[loanId]?.logicTests
          ).map(
            (testName: any) => `loanResult.logicTests.${testName}.message.FAIL`
          );
          const finalCalculationFailTemplateBody = Object.keys(
            resultresp?.data?.loanResult?.[loanId]?.finalCalculation
          ).map(
            (testName: any) =>
              `loanResult.finalCalculation.${testName}.message.FAIL`
          );
          const reqBody = {
            templateKeys: [
              ...testNamesTemplateBody,
              ...maxMintestFailTemplateBody,
              ...logictestFailTemplateBody,
              ...finalCalculationFailTemplateBody
            ]
          };
          getTemplateData(reqBody, takeOutPartnerData.id).then((res: any) => {
            Object.keys(tests)?.forEach((testName: any) => {
              if (
                testName !== "sectionEligibility" &&
                testName !== "propertyleveltest"
              ) {
                const testType = logicTests[testName]
                  ? `logicTests`
                  : MaxMinTests[testName]
                    ? `maxMinParameters`
                    : `finalCalculation`;
                const waiverDetails = {
                  loanId,
                  loanStage,
                  ...(waivers?.get(
                    `loanResult.${loanId}.${testType}.${testName}`
                  ) ?? {}),
                  takeoutPartnerId: takeOutPartnerData.id
                };
                const testFailReason = evaluation(
                  res?.data?.[
                  `loanResult.${testType}.${testName}.message.FAIL`
                  ],
                  {
                    loanResult: {
                      afterAdjustmentMaxLTVAsIs:
                        flattenedData?.[
                        "toorakProceeds.afterAdjustmentMaxLTVAsIs"
                        ] ??
                        flattenedData?.["toorakProceeds.toorakAdjustedAsIsLtv"],
                      dscr: flattenedData?.["loanEconomics.dscr"] ?? null
                    }
                  }
                );
                const testData = {
                  label: eval(res?.data?.[`testNames.${testName}`]) ?? testName,
                  name: testName,
                  type: testType,
                  waiverDetails,
                  isCriticalTest:
                    criticalTests?.[takeOutPartnerData.id]?.includes(
                      testName
                    ) ?? false,
                  reason:
                    waiverDetails.waiverId || tests[testName] === "FAIL"
                      ? testFailReason
                      : "",
                  // testVariables: testInputsMap?.[testName] ?? [],
                  loanData
                };
                switch (tests[testName]) {
                  case "FAIL":
                    failTests.push(testData);
                    break;
                  case "PASS":
                    passTests.push(testData);
                    break;
                  case "WAIVED":
                    waivedTest.push(testData);
                    break;
                }
              }
            });
            const errorAddressList = getPropertiesWithFailedTest(
              resultresp?.data,
              propertyDetails
            );
            const partnersData = {
              label: takeOutPartnerData.fullName,
              id: takeOutPartnerData.id,
              "data-testid": `guideline-and-pricing-data-${takeOutPartnerData.fullName}`,
              key: takeOutPartnerData.id,
              creditLineEconomics: takeOutPartnerData.creditLineEconomics.find(
                (it: any) => it.isCurrentCycle
              ),
              data: {
                ...resultresp?.data?.loanResult?.[loanId]?.loanPricing,
                ...resultresp?.data?.loanResult?.[loanId]?.toorakProceeds
              },
              fail: failTests,
              pass: passTests,
              waived: waivedTest,
              errorAddressList,
              isSelected: takeOutPartnerData.id === selectedTakeOutId,
              failedCount: failTests.length,
              criticalFailed : failTests.filter((it: any) => it.isCriticalTest).length ?? 0
            };
            if (
              rateLockPeriodAndExpiry &&
              takeOutPartnerData.id ===
              rateLockPeriodAndExpiry[Object.keys(rateLockPeriodAndExpiry)[0]]
                .takeoutPartnerId &&
              loanId === Object.keys(rateLockPeriodAndExpiry)[0]
            ) {
              partnersData.data = {
                ...partnersData.data,
                ...rateLockPeriodAndExpiry[
                Object.keys(rateLockPeriodAndExpiry)[0]
                ]
              };
            } else delete partnersData?.data?.rateLockAdjustment;
            return resolve(partnersData);
          });
        } else {
          const partnersData = {
            label: takeOutPartnerData.fullName,
            "data-testid": `guideline-and-pricing-data-${takeOutPartnerData.fullName}`,
            key: takeOutPartnerData.id,
            creditLineEconomics: takeOutPartnerData.creditLineEconomics.find(
              (it: any) => it.isCurrentCycle
            ),
            data: {
              ...resultresp?.data?.loanResult?.[loanId]?.loanPricing,
              ...resultresp?.data?.loanResult?.[loanId]?.toorakProceeds
            },
            fail: [],
            pass: [],
            waived: [],
            errorAddressList: [],
            isSelected: takeOutPartnerData.id === selectedTakeOutId
          };
          return resolve(partnersData);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const GuidlinesComparisionContext = createContext<any>(undefined);

export const PricingAndGuidlinesSection = (props: {
  takOutPartnerList: ObjectType[];
  rateSheetType: string;
  loanId: string;
  programSelectConfig: any;
  takeOutPartner?: any;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loanStage } = useParams<URLParamsType>() as any;
  const {
    takOutPartnerList,
    rateSheetType,
    programSelectConfig,
    takeOutPartner,
    loanId
  } = props;
  const {
    isProgramSelectView,
    isLockPopUp,
    criticalTests,
    eligibleTakeOutPartnerResult
  } = programSelectConfig;
  const {
    thirtyYearLoanInformation,
    fesLoanInformation,
    thirtyYearLoanEconomics,
    bridgeLoanBorrowerInformation,
    bridgeLoanGuarantorInformation,
    loanRuleVersions,
    loanSummary,
    fesBorrowerInformation,
    fesLoanEconomics,
    propertyDetails
  } = useSelector<RootState, any>(state => state.createLoanStore?.loanDetails);
  const { waiversCreated, customerInfo } = useSelector<RootState, any>(
    state => state.evaluationResultsStore
  );
  const [selectedView, setSelectedView] = useState(views[0].value);
  const [eligiblePartnersForExcel, setGligiblePartnersForExcel] = useState<
    any[]
  >([]);
  const [selectedSubView, setSelectedSubView] = useState(subViews[0].value);
  const [partnersData, setPartnersData] = useState<ObjectType>({
    eligiblePartners: [],
    ineligiblePartners: []
  });

  useEffect(() => {
    if (!loanId) return;
    getEligiblePartnerByLoanId(loanId).then((res: any) => {
      const results = res.data.response.hits.hits;
      if (results.length) {
        setGligiblePartnersForExcel(
          results[0]._source.loan.eligibleTakeoutPartners
        );
      }
    });
  }, [loanId]);

  const { eligiblePartners } = useMemo(() => {
    let eligiblePartnersList = [];
    if (eligibleTakeOutPartnerResult?.assignedPartner) {
      eligiblePartnersList = eligibleTakeOutPartnerResult.eligiblePartners.map(
        (partner: string) => {
          return {
            takeoutPartnerPartyId: partner
          };
        }
      );
    } else {
      eligiblePartnersList =
        loanStage === LoanStage.fes
          ? fesLoanInformation?.eligibleTakeoutPartners
          : thirtyYearLoanInformation?.payload?.eligibleTakeoutPartners ?? [];
    }
    if (loanStage === LoanStage.fes) {
      return {
        takeoutPartnerId:
          eligibleTakeOutPartnerResult?.assignedPartner ||
          fesLoanInformation?.takeoutPartnerId,
        eligiblePartners: eligiblePartnersList
      };
    }
    return {
      takeoutPartnerId:
        eligibleTakeOutPartnerResult?.assignedPartner ||
        thirtyYearLoanInformation?.payload?.takeoutPartnerId,
      eligiblePartners: eligiblePartnersList
    };
  }, [
    loanStage,
    fesLoanInformation,
    thirtyYearLoanInformation,
    eligibleTakeOutPartnerResult
  ]);

  const { rateLockExpiryDate, loanConfig } = useSelector<RootState, any>(
    state => state.createLoanStore.loanDetails
  );
  const [rateLockPeriodAndExpiry, setRateLockPeriodAndExpiry] = useState<any>(
    null
  );

  useEffect(() => {
    if (!takeOutPartner) return;
    setRateLockPeriodAndExpiry({
      [loanId]: {
        rateLockPeriod: loanConfig?.rateLockPeriod,
        rateLockExpiryDate,
        takeoutPartnerId: takeOutPartner?.id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateLockExpiryDate, takeOutPartner?.id]);

  const partnerWiseWaivers = useMemo(() => {
    if (!waiversCreated.length) return {};
    const partnerWiseWaivers: any = {};
    waiversCreated?.forEach((waiver: any) => {
      const waiverData = formatWaiverData(waiver, customerInfo);
      if (!partnerWiseWaivers[waiver.takeoutPartnerId]) {
        partnerWiseWaivers[waiver.takeoutPartnerId] = new Map();
      }
      partnerWiseWaivers[waiver.takeoutPartnerId].set(
        waiver.waiverKeys[0],
        waiverData
      );
    });
    return partnerWiseWaivers;
  }, [waiversCreated, customerInfo]);

  const loanData: ObjectType = useMemo(() => {
    const fesLoanData = {
      borrowerInformation: [fesBorrowerInformation],
      loanInformation: fesLoanInformation,
      loanEconomics: fesLoanEconomics,
      loanRuleVersions
    };
    const prePostLoanData = {
      borrowerInformation: bridgeLoanBorrowerInformation,
      guarantorInformation: bridgeLoanGuarantorInformation,
      loanInformation: thirtyYearLoanInformation.payload,
      loanEconomics: thirtyYearLoanEconomics.payload,
      loanRuleVersions,
      loanSummary
    };

    if (loanStage === LoanStage.fes) {
      return fesLoanData;
    }
    return prePostLoanData;
  }, [
    loanStage,
    bridgeLoanBorrowerInformation,
    fesLoanInformation,
    bridgeLoanGuarantorInformation,
    fesBorrowerInformation,
    fesLoanEconomics,
    loanRuleVersions,
    loanSummary,
    thirtyYearLoanInformation,
    thirtyYearLoanEconomics
  ]);

  useEffect(() => {
    if (!partnerWiseWaivers) return;
    const eligiblePartnersList: any[] = [];
    const inEligiblePartnersList: any[] = [];
    const excelData = sessionStorage.getItem("excelUpload");
    const requiredEligiblePartners = excelData
      ? eligiblePartnersForExcel ?? eligiblePartners
      : eligiblePartners;
    takOutPartnerList?.forEach((tpData: any) => {
      if (
        /**
         * eligiblePartners have to changed to get data for bulk upload
         */
        requiredEligiblePartners?.some(
          (eligibleTp: ObjectType) =>
            tpData.id === eligibleTp.takeoutPartnerPartyId
        )
      ) {
        eligiblePartnersList.push(tpData);
      } else {
        inEligiblePartnersList.push(tpData);
      }
    });

    const partnersData: ObjectType = {
      eligiblePartners: eligiblePartnersList,
      ineligiblePartners: inEligiblePartnersList
    };
    setPartnersData(partnersData);
    if (!partnersData?.[selectedView]?.length) return;
    dispatch(showLoader());
    const resultsPromiseList: any[] = [];
    partnersData?.[selectedView]?.forEach((item: any) => {
      resultsPromiseList.push(
        getPricingAndResults(
          item,
          loanId,
          loanStage,
          item.id,
          partnerWiseWaivers?.[item.id] ?? new Map(),
          propertyDetails,
          loanData,
          rateLockPeriodAndExpiry,
          criticalTests
        )
      );
    });

    Promise.all(resultsPromiseList)
      .then((res: any) => {
        setPartnersData({
          ...partnersData,
          [selectedView]: res
        });
        dispatch(hideLoader());
      })
      .catch(err => {
        dispatch(hideLoader());
        console.error(err);
      });
  }, [
    loanId,
    loanStage,
    dispatch,
    selectedView,
    takOutPartnerList,
    eligiblePartners,
    eligiblePartnersForExcel,
    partnerWiseWaivers,
    loanData,
    propertyDetails,
    rateLockPeriodAndExpiry,
    criticalTests
  ]);

  const handleChange = (e: any, val: string) => {
    if (!val) return;
    setSelectedView(val);
  };
  const handleToggle = (e: any, val: string) => {
    if (!val) return;
    setSelectedSubView(val);
  };

  useEffect(() => {
    if (isProgramSelectView) {
      setSelectedView(views[1].value);
    }
  }, [isProgramSelectView]);

  return (
    <GuidlinesComparisionContext.Provider
      value={{
        partnersData: partnersData?.[selectedView],
        // getPricingAndResults,
        // setPartnersData,
        rateSheetType,
        takeOutPartner
      }}
    >
      {isLockPopUp ? (
        <>
          <PricingComparision
            programSelectConfig={programSelectConfig}
            partnersData={partnersData?.["eligiblePartners"] ?? []}
          />
        </>
      ) : isProgramSelectView ? (
        <GuidelinesComparision
          showSummary
          programSelectConfig={programSelectConfig}
        />
      ) : (
        <Grid
          container
          className={classes.sectionWrapper}
          style={{ flexDirection: "column" }}
        >
          <AppBar
            position="static"
            color="default"
            style={{
              padding: "0px 24px",
              borderBottom: "1px solid #dedede",
              backgroundColor: "transparent",
              backgroundImage: "none",
              zIndex: 1
            }}
          >
            <Tabs
              value={selectedView}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="partner classification"
              className="loans-tab-container"
            >
              {views.map((item: any, idx) => (
                <Tab
                  key={item.value}
                  label={`${item.label} (${idx === 0
                      ? partnersData.eligiblePartners.length
                      : partnersData.ineligiblePartners.length
                    })`}
                  data-testid={`eligible-and-non-eligible-partner-${idx}`}
                  value={item.value}
                  style={{ textTransform: "none", fontSize: "14px" }}
                />
              ))}
            </Tabs>
          </AppBar>
          {selectedView === "eligiblePartners" && (
            <>
              <Grid style={{ marginTop: "1rem" }}>
                <ToggleGroup
                  toggleOptions={subViews}
                  handleChange={handleToggle}
                  alignment={selectedSubView}
                  classes={classes}
                />
              </Grid>
              {selectedSubView === "pricing" && (
                <PricingComparision
                  programSelectConfig={programSelectConfig}
                  partnersData={partnersData?.["eligiblePartners"] ?? []}
                />
              )}
              {selectedSubView === "guidelineTests" && (
                <GuidelinesComparision
                  showSummary
                  programSelectConfig={{ isProgramSelectView: false }}
                />
              )}
            </>
          )}
          {selectedView === "ineligiblePartners" && (
            <GuidelinesComparision
              showSummary
              programSelectConfig={{ isProgramSelectView: false }}
            />
          )}
        </Grid>
      )}
    </GuidlinesComparisionContext.Provider>
  );
};