import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { RootState } from "../stores/rootReducer";
import { LoaderAndExceptionsStore } from "../stores/loaderAndException/loaderAndException.reducer";
import toorakLogo from "../images/toorakLogo.svg";
import { styled } from '@mui/material/styles';
// import { clearLoader } from "../stores/loaderAndException/loaderAndException.action";

const CustomBackdrop = styled(Backdrop)(({ theme, open }) => ({
  opacity: open ? 1 : 0,
  visibility: open ? 'visible' : 'hidden',
  transition: 'opacity 195ms cubic-bezier(0.4, 0, 0.2, 1)', // Optional: smooth transition
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: 999999999,
      backgroundColor: "rgba(65,65,105, 0.25)"
    },
    inner: {
      top: "35%",
      position: "absolute",
      color: "#fff",
      fontSize: "20px"
    }
  })
);
export const LoaderWithLogo = () => {
  return (
    <Grid className="loaderBody">
      <div className="e-loadholder">
        <div className="m-loader">
          <img
            src={toorakLogo}
            alt="left_img_trades"
            style={{ padding: 10 }}
            className="e-text"
          />
        </div>
      </div>
      <div id="particleCanvas-Blue" />
      <div id="particleCanvas-White" />
    </Grid>
  );
};
export const CustomLoader = (props?: any) => {
  const classes = useStyles();
  return (
    <Grid className="loaderBody">
      <div className="e-loadholder">
        <div className="m-loader">
          {props?.title ? (
            <div className={classes.inner}>{props?.title}</div>
          ) : (
            <img
              src={toorakLogo}
              alt="left_img_trades"
              style={{ padding: 10 }}
              className="e-text"
            />
          )}
        </div>
      </div>
      <div id="particleCanvas-Blue" />
      <div id="particleCanvas-White" />
    </Grid>
  );
};
export const Loader = (props:any) => {
  const {isOpen} = props;
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { loaderCount, higherOrderLoaderCount } = useSelector<
    RootState,
    LoaderAndExceptionsStore
  >(state => state.LoaderAndExceptionsStore);
  useEffect(() => {
    const isLoading = loaderCount > 0 || higherOrderLoaderCount > 0 || isOpen;
    setOpen(isLoading);
  }, [loaderCount, higherOrderLoaderCount, isOpen]);

  return (
    <CustomBackdrop className={classes.backdrop} open={open} data-testid="loader">
      <LoaderWithLogo />
    </CustomBackdrop>
  );
};

export const CustomLoaderWithLogo = (props?: any) => {
  return (
    <Grid className="loaderBody">
      <div className="e-loadholder">
        <div
          className="m-loader"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <img
            src={toorakLogo}
            alt="left_img_trades"
            style={{ padding: 10 }}
            className="e-text"
          />
          <div
            style={{
              width: "150px",
              fontSize: "16px",
              marginTop: "50px",
              fontWeight: 600,
              color: "#ffffff"
            }}
          >
            {props?.value}%
          </div>
        </div>
      </div>
      <div id="particleCanvas-Blue" />
      <div id="particleCanvas-White" />
    </Grid>
  );
};

export const LoaderCustom = (props: { value: number }) => {
  // dummy
  const classes = useStyles();
  const { value } = props;
  return (
    <Backdrop className={classes.backdrop} open data-testid="loader-custom">
      <CustomLoaderWithLogo value={value} />
    </Backdrop>
  );
};
