import { LoanStage, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../config/config";
import { getErrorConfigObject } from "../ErrorHandler/ErrorConfigData";
import { ObjectType } from "../masterView/common";
import { AuditResourceObj } from "./helpers";
import { getCookie } from "../utils/cookies";

const BASE_URL = getConfig().apiUrl;

const CUSTOM_BASE_URL =
  window.location.hostname === "dev.toorakcapital.info" ||
  window.location.hostname === "localhost"
    ? "https://api-dev.toorakcapital.info"
    : BASE_URL;

export const post = (url: string, body?: any, headers?: any) => {
  if (headers === true) {
    return new Promise((resolve, reject) => {
      publicClient
        .post(url)
        .then((res: any) => {
          resolve(res);
        })
        .catch((e: any) => reject(e));
    });
  }
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body, { headers })
      .then((res: any) => {
        resolve(res);
      })
      .catch((e: any) => reject(e));
  });
};

export const patch = (url: string, body: any, config?: any) => {
  if (config) {
    return new Promise((resolve, reject) => {
      publicClient
        .patch(url, body, config)
        .then((res: any) => {
          resolve(res);
        })
        .catch(reject);
    });
  }
  return new Promise((resolve, reject) => {
    publicClient
      .patch(url, body)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const put = (url: string, body?: any) => {
  if (body != null) {
    return new Promise((resolve, reject) => {
      publicClient
        .put(url, body)
        .then((res: any) => {
          resolve(res);
        })
        .catch(reject);
    });
  }
  return new Promise((resolve, reject) => {
    publicClient
      .put(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const get = (url: string, skipForStatuses?: number[], headers?: any) => {
  const shouldSkipForStatuses = Array.isArray(skipForStatuses);
  if (shouldSkipForStatuses) {
    return new Promise((resolve, reject) => {
      publicClient
        .get(url, {
          errorConfig: getErrorConfigObject({ skipForStatus: skipForStatuses })
        } as any)
        .then((res: any) => {
          resolve(res);
        })
        .catch(reject);
    });
  }
  return new Promise((resolve, reject) => {
    publicClient
      .get(url, {headers})
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export const remove = (url: string, reqBody?: any) => {
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url, reqBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
};

export function getCreditLinesByType(
  creditLineType: string = "TAKEOUT_PARTNER"
) {
  const url = `${
    getConfig().apiUrl
  }/credit-line/fetch?creditLineType=${creditLineType}`;
  return get(url);
}

export const updateLoanData = (body: any) => {
  const url = `${getConfig().apiUrl}/loans/bulk`;
  return patch(url, body);
};

export const startExtraction = (toorakLoanId: string) => {
  const url = `${getConfig().apiUrl}/ocr/extraction/${toorakLoanId}`;
  return post(url, {}, true);
};

export const updateTakeOutPartnerData = (body: any) => {
  const url = `${getConfig().apiUrl}/aggregate/takeout-partner/update`;
  return post(url, body);
};

export const updateLoanTableFunding = (loanId: string, data: any) => {
  const url = `${BASE_URL}/aggregate/loans/${loanId}/fundingType`;
  return patch(url, data);
};

export const updateWarehouseFunding = (loanId: string, data: any) => {
  const url = `${BASE_URL}/loans/${loanId}/loanDetail`;
  return put(url, data);
};

export const getRateLockOptions = (
  orgPatyId: string,
  requestBody: any,
  tpId?: string
) => {
  let url = `${getConfig().apiUrl}/ratesheet/30year?`;
  url = tpId ? `${url}tpId=${tpId} ${orgPatyId ? "&&" : ""}` : url;
  url = orgPatyId ? `${url}originatorId=${orgPatyId}` : url;
  return post(url, requestBody);
};

export const getSplitBoundariesById = (sourceDocumentId: string) => {
  const url = `${BASE_URL}/ocr/package/${sourceDocumentId}`;
  return get(url);
};

export const saveSplitDraftData = (sourceDocumentId: any, payload: any) => {
  const url = `${BASE_URL}/ocr/package/${sourceDocumentId}`;
  return put(url, payload);
};

export const getCategories = (reqObj: any) => {
  const url = `${CUSTOM_BASE_URL}/ttf-service/categories/fetch`;
  return post(url, reqObj);
};

export const getCategoryStatus = (reqObj: any, loanId: string) => {
  const url = `${CUSTOM_BASE_URL}/ttf-service/categories/${loanId}/status`;
  return post(url, reqObj);
};

export const getExtractedFieldsPerLoan = (loanId: string) => {
  const url = `${CUSTOM_BASE_URL}/ocr/extraction/${loanId}/ttf-info-extracted`;
  return get(url, [404]);
};

export const getExtractionStatusPerDoc = (loanId: string) => {
  const url = `${CUSTOM_BASE_URL}/ocr/extraction/${loanId}/documents`;
  return get(url, [404]);
};

export const getToken = (body: any, headers: any) => {
  return post(`${getConfig()?.keyClockUrl}/token`, body, headers);
};

export const logoutApp = (url: any, formBodyString: any, headers: any) =>
  post(url, formBodyString, headers);

export const setExtractionStatusPerDoc = (docId: string) => {
  const url = `${CUSTOM_BASE_URL}/ocr/extraction/${docId}`;
  return patch(url, {
    status: "OVERRIDDEN"
  });
};

export const addDocumentTagByDocumentId = (
  documentId: string,
  requestBody: any
) => {
  const url = `${BASE_URL}/addComprehendTags/documents/${documentId}/tags`;
  return post(url, requestBody);
};

export const removeDocumentTagByIdAndCode = (
  documentId: string,
  tagCode: string
) => {
  const url = `${BASE_URL}/deleteComprehendTags/documents/${documentId}/tags?tagCode=${tagCode}`;
  return remove(url);
};

export const updateDocumentTagByDocumentId = (documentId: string) => {
  const url = `${BASE_URL}/documentdetails/${documentId}/tagconfidence`;
  return put(url);
};

export const updateAssets = (requestBody: any) => {
  const url = `${BASE_URL}/documentdetails/assets`;
  return post(url, requestBody);
};

export const updatePurchasePrimary = (requestBody: any) => {
  const url = `${BASE_URL}/documentdetails/primary`;
  return put(url, requestBody);
};

export const updateLatest = (requestBody: any) => {
  const url = `${BASE_URL}/documentdetails/latest`;
  return put(url, requestBody);
};

export const deleteAssets = (documentId: any) => {
  const url = `${BASE_URL}/documentdetails/assets/${documentId}`;
  return remove(url);
};

export const getPresignedURL = (
  primaryIdentifier: string,
  primaryIdenValue: string,
  docsId: string
) => {
  const url = `${BASE_URL}/documentaggregatorservice/documentdetails/${primaryIdentifier}/${primaryIdenValue}/documents/${docsId}/download`;
  return post(url, "");
};

export const getPresignedURLForDrawDocs = (drawId: any, docId: any) => {
  const owner = getCookie("orig_id");
  const url = `${BASE_URL}/drawservice/assetmanagement/draws/${drawId}/documents/download?owner=${owner}&isOriginator=false`;
  return post(url, { docId: [docId] });
};

export const getPresignedURLForPDF = (
  primaryIdentifier: string,
  primaryIdenValue: string,
  docsId: string
) => {
  const url = `${BASE_URL}/documentaggregatorservice/documentdetails/${primaryIdentifier}/${primaryIdenValue}/documents/${docsId}/downloadcf`;
  return post(url, "");
};

export const getTTFData = (loanId: string) => {
  const url = `${CUSTOM_BASE_URL}/ttf-service/${loanId}/ttf-info`;
  return get(url);
};

export const saveTTFData = (saveObj: any, loanId: string) => {
  const url = `${CUSTOM_BASE_URL}/ttf-service/${loanId}/ttf-info`;
  return post(url, saveObj);
};

export const getClosingPackages = (
  loanId: string,
  skipForStatuses: number[]
) => {
  const url = `${BASE_URL}/ocr/package?loanId=${loanId}`;
  return get(url, skipForStatuses);
};

export const getLoanInfoForTTF = (loanId: string, loanStage: LoanStage) => {
  const url = `${
    getConfig().apiUrl
  }/aggregate/loans/${loanId}?loanStage=${loanStage}&includeResults=true`;
  return get(url);
};

export const evaluateLoanRuleResults = (
  loanId: string,
  loanStage: string,
  ruleType: string
) => {
  const url = `${BASE_URL}/aggregate/${loanId}/rules?stage=${loanStage}&&loanRuleType=${ruleType}`;
  return post(url, {});
};

export const removeLoanEscrowMap = (loanId: string) => {
  const url = `${CUSTOM_BASE_URL}/loans/${loanId}/loanEscrowMap`;
  return remove(url);
};

export const removePropertyEscrowMap = (
  loanId: string,
  loanStage: LoanStage
) => {
  const url = `${CUSTOM_BASE_URL}/loan_property/${loanId}/propertyEscrowMap?stage=${loanStage}`;
  return remove(url);
};

export const getPropertyInfoForTTF = (loanId: string, loanStage: LoanStage) => {
  const url = `${
    getConfig().apiUrl
  }/loan_property/${loanId}/properties?stage=${loanStage}`;
  return get(url);
};

export const updateExceptionStatus = (
  loanID: string,
  loanStage: string,
  exceptionData: any
) => {
  const url = `${
    getConfig().apiUrl
  }/exceptionservice/${loanID}?loanStage=${loanStage}`;
  return put(url, exceptionData);
};

export const getAppraisalData = (loanId: string) => {
  const url = `${BASE_URL}/appraisal/loan/${loanId}`;
  return get(url, [404]);
};

export const getAppraisalLandmarks = (appraisalId: string) => {
  const url = `${BASE_URL}/appraisal/${appraisalId}/landmarks`;
  return get(url, [404]);
};

export function getLoansList(
  requestBody: any,
  includeResults: boolean = true,
  includeSettlementResults: boolean = true
) {
  const url = `${
    getConfig().apiUrl
  }/aggregate/bulk/loans?includeResults=${includeResults}&includeSettlementResults=${includeSettlementResults}`;

  return post(url, requestBody);
}

export function getFiltersData(
  requestBody: any,
  includeResults: boolean = true,
  includeSettlementResults: boolean = true
) {
  const url = `${
    getConfig().apiUrl
  }/aggregate/bulk/loans?includeResults=${includeResults}&includeSettlementResults=${includeSettlementResults}`;
  return post(url, requestBody);
}

export const updateBulkLoans = (
  body: any,
  resetOverriddenRateSheetResults: boolean = true
) => {
  const url = `${
    getConfig().apiUrl
  }/aggregate/bulk/loans/update?resetOverriddenRateSheetResults=${resetOverriddenRateSheetResults}`;
  return post(url, body);
};

export const getAuditHistory = (body: any) => {
  const url = `${getConfig().apiUrl}/aggregate/auditservice/loans`;
  return post(url, body);
};

export const fetchcustomersData = (body: any) => {
  const url = `${getConfig().apiUrl}/customer/fetch`;
  return post(url, body);
};

export const exportCustomExcel = (excelData: ObjectType) => {
  const url = `${getConfig().apiUrl}/aggregate/export/custom`;
  return post(url, excelData);
};

export const saveExtractionData = (payload: any, loanId: string) => {
  const url = `${CUSTOM_BASE_URL}/ocr/extraction/${loanId}/ttf-info-extracted`;
  return patch(url, payload);
};

export const getAuditInfoLightWeight = (payload: {
  resources: AuditResourceObj[];
}) => {
  const url: string = `${getConfig().apiUrl}/audit/fields/fetch`;
  return post(url, payload);
};

export const fetchSearchResultsLoanAgg = (payload: any, headers: any = {}) => {
  const config = getConfig();
  const url = `${config.apiUrl}/search/${config.prefix}_loan_aggregate/_search`;
  return post(url, payload, headers);
};

export const getSpecificAuditList = (
  auditKey: string,
  auditPath: string,
  auditType: string
) => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/auditservice/${auditType}/${auditKey}/${encodeURIComponent(
    auditPath
  )}`;
  return publicClient.get(url);
};

export const getTaskDetails = (payload: any) => {
  const url = `${CUSTOM_BASE_URL}/taskservice/tasks/fetch`;
  return post(url, payload);
};

export const getTaskCount = (payload: any) => {
  const url = `${CUSTOM_BASE_URL}/taskservice/tasks/count`;
  return post(url, payload);
};

export const updateTaskDetails = (payload: any) => {
  const url = `${CUSTOM_BASE_URL}/taskservice/tasks`;
  return patch(url, payload);
};

export const getSigneeUsers = (orgId: string) => {
  const url: any = `${
    getConfig().apiUrl
  }/aggregate/accountmap/originator/${orgId}`;
  return get(url);
};

export const getSigneeBasedOnSeller = (
  originatorPartyId: string,
  sellerPartyId: string
) => {
  const url: any = `${
    getConfig().apiUrl
  }/aggregate/accountmap/originator/${originatorPartyId}/seller/${sellerPartyId}`;
  return get(url);
};

export const getSellersForOriginator = (orgId: string) => {
  const url = `${getConfig().apiUrl}/aggregate/accountmap/originator/${orgId}`;
  return get(url);
};

export function addNewOrgWireCounterParty(
  reqBody: any,
  originatorId: string,
  sellerId: string
) {
  const url = `${
    getConfig().apiUrl
  }/aggregate/accountmap/originator/${originatorId}/seller/${sellerId}/warehouseLender`;
  return post(url, reqBody);
}

export function removeOrgWireCounterParty(
  originatorId: string,
  sellerId: string,
  reqBody: ObjectType
) {
  const url = `${
    getConfig().apiUrl
  }/accountmap/originator/${originatorId}/seller/${sellerId}`;
  return patch(url, reqBody);
}

export function getDocumentsAndInternalTags(
  loanId: string,
  identifier: string
) {
  const url = `${
    getConfig().apiUrl
  }/automaticTaggedDocumentDetails/LOAN/${loanId}/documents?secondary_identifier_name=${identifier}&secondary_identifier_value=${identifier}&includeAssets=true&setPrimaryFlags=true`;
  return publicClient.get(url);
}

export function getDocumentsForMultipleLoans(
  reqBody: ObjectType[],
  identifier: string
) {
  const url = `${
    getConfig().apiUrl
  }/documentdetails/fetch?secondary_identifier_name=${identifier}&secondary_identifier_value=${identifier}`;
  return post(url, reqBody);
}

export function updateboxfoldername(
  identifier: string,
  loanId: string,
  loanStage: string,
  loanStatus: string,
  oldLoanStatus: string
) {
  const url = `${
    getConfig().apiUrl
  }/documentaggregatorservice/updateboxfoldername/${identifier}/${loanId}?loanStage=${loanStage}&newLoanStatus=${loanStatus}&oldLoanStatus=${oldLoanStatus}`;
  return post(url);
}

export const createDoc = (
  primaryIdentifier: string,
  primaryIdenValue: string,
  secondaryIidentifier: string,
  secondaryIidentifierValue: string,
  body: ObjectType
) => {
  const url = `${BASE_URL}/docs/${primaryIdentifier}/${primaryIdenValue}/documents?secondary_identifier_name=${secondaryIidentifier}&secondary_identifier_value=${secondaryIidentifierValue}`;
  return post(url, body);
};

export const attachDocToOrg = (originatorPartyId: string, body: ObjectType) => {
  const url = `${BASE_URL}/accountmap/originator/${originatorPartyId}/documents`;
  return patch(url, body);
};

export const getOrgMappings = (originatorPartyId: string) => {
  const url = `${BASE_URL}/accountmap/originator/${originatorPartyId}`;
  return get(url);
};

export const getDocsById = (docId: string) => {
  const url = `${BASE_URL}/getdocumentdetails/document/${docId}`;
  return get(url);
};

export const getDocsByIds = (docIds: []) => {
  const url = `${BASE_URL}/documentdetails/getdocuments`;
  return post(url, docIds);
};

export const unlinkDocFromOrg = (
  originatorPartyId: string,
  body: ObjectType
) => {
  const url = `${BASE_URL}/accountmap/originator/${originatorPartyId}/documents`;
  return patch(url, body);
};

export const extractPricingSheet = (
  pricingType: string,
  partnerId: string,
  body: ObjectType
) => {
  const url = `${BASE_URL}/extract/LOCAL/sheet/${pricingType}?takeoutPartner=${partnerId}`;
  return post(url, body,
  //   { // TODO: To be checked why header obj is getting passed as [Object, Object]
  //   errorConfig: getErrorConfigObject({
  //     skipErrorHandling: true
  //   })
  // }
);
};

export const publishPricing = (
  loanType: string,
  version: string,
  body: ObjectType,
  dataType: string
) => {
  const url = `${BASE_URL}/ratesheet/${loanType}/${version}/${dataType}`;
  return post(url, body);
};

export const disableRateLock = (tpIds: string[]) => {
  const url = `${BASE_URL}/aggregate/ratelock/disable`;
  return patch(url, tpIds);
};

export const enableRateLock = (tpIds: string[]) => {
  const url = `${BASE_URL}/aggregate/ratelock/enable`;
  return post(url, tpIds);
};

export const getLatestAggregateRuleVersion = (
  loanType: string,
  pId?: string
) => {
  let url = `${BASE_URL}/ratesheet/v2/ruleVersion/${loanType}`;
  if (pId) {
    url = `${url}?tpName=${pId}`;
  }
  return get(url);
};

export const postLatestAggregateRuleVersion = (loanType: string, body: any) => {
  const url = `${BASE_URL}/ratesheet/ruleVersion/${loanType}`;
  return post(url, body);
};

export const getMmtcRateSheetData = () => {
  const url = `${BASE_URL}/custom/MMTC_RATE_SHEET_DETAILS/value`;
  return get(url);
};

export function getEvaluationResultForPartner(
  loanId: string,
  loanStage: LoanStage,
  partnerId: string
) {
  const url = `${
    getConfig().apiUrl
  }/evaluation/v2/${loanId}/partner/results?loanStage=${loanStage}&partnerId=${partnerId}`;
  return get(url);
}

export function rejectLoanById(loan: ObjectType) {
  const url = `${getConfig().apiUrl}/aggregate/loans/${loan.loanId}/status`;
  // eslint-disable-next-line no-param-reassign
  delete loan.loanId;
  return put(url, { ...loan, comments: "Rejected" });
}
export const getGaRateSheetData = () => {
  const url = `${BASE_URL}/custom/GA_RATE_SHEET_DETAILS/value`;
  return get(url);
};

export const getTakeOutPartnerList = () => {
  const url = `${BASE_URL}/credit-line/fetch?creditLineType=TAKEOUT_PARTNER`;
  return get(url);
};

export const postLatestRateSheetToCustom = (partnerKey: string, body: any) => {
  const url = `${BASE_URL}/custom/${partnerKey}/value`;
  return post(url, body);
};

export const getTemplateData = (body: ObjectType, tpId?: string) => {
  let url = `${BASE_URL}/template/fetch?locale=NA&countryCode=NA&partialProcess=true`;
  if (tpId) {
    url = `${url}&tpId=${tpId}`;
  }
  return post(url, body);
};

export const getResultsForDataEntryLoan = (loanData: any) => {
  const url = `${BASE_URL}/aggregate/takeout-partner/save-result`;
  return post(url, loanData);
};

export const getEligiblePartner = (reqBody: any) => {
  const url = `${BASE_URL}/aggregate/takeout-partner/change-takeout-partners?skipSave=true`;
  return post(url, reqBody);
};

export const getEligiblePartnerByLoanId = (loanId: string) => {
  const url = `${getConfig().apiUrl}/search/${
    getConfig().aggregateUrl
  }/_search`;
  const orgId = sessionStorage.getItem("originatorPartyIdNew");
  const requestBody = {
    query: {
      bool: {
        must: [
          {
            terms: {
              "loan.loanDetailId.toorakLoanId.raw": [loanId]
            }
          },
          {
            exists: {
              field: "loan.eligibleTakeoutPartners.takeoutPartnerPartyId.raw"
            }
          },
          {
            terms: {
              "loan.loanDetailId.originatorPartyId.raw": [orgId]
            }
          }
        ],
        must_not: []
      }
    },
    _source: ["loan.eligibleTakeoutPartners.*"],
    sort: [
      {
        updatedOn: "desc"
      },
      "_score"
    ],
    track_total_hits: true,
    aggs: {},
    from: 0,
    size: 20
  };
  return post(url, requestBody);
};

export const getOrgSpecificLoanTypes = (originatorId: string) => {
  const url: any = `${
    getConfig().apiUrl
  }/accountmap/originator/${originatorId}`;
  return get(url);
};

export const addNewRateLockExtension = (
  loanId: string,
  reqBody: ObjectType
) => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/loans/${loanId}/rate-lock-extension`;
  return post(url, reqBody);
};

export const updateRateLockExtension = (
  loanId: string,
  reqBody: ObjectType
) => {
  const url: string = `${
    getConfig().apiUrl
  }/aggregate/loans/${loanId}/rate-lock-extension`;
  return put(url, reqBody);
};

export const uploadPAToBox = (bundleId: string, sellerPartyId: string) => {
  const url: string = `${
    getConfig().apiUrl
  }/uploaddocumentintobox/SETTLEMENT/${bundleId}?secondary_identifier_name=SELLER_PARTY_ID&secondary_identifier_value=${sellerPartyId}`;
  return post(url, {});
};

export const getCriticalTests = (rVersion: string) => {
  const url = `${BASE_URL}/ruleevaluation/ruleattributes?ruleType=30year&ruleVersion=${rVersion}`;
  const body = ["criticalTestLoanEligibility"];
  return post(url, body);
};

export const fetchLoanData = (loanId: string, loanStage: string) => {
  const url = `${BASE_URL}/bff/loan/${loanId}/loanStage/${loanStage}`;
  return get(url);
};

export const checkDscrLoanSubmition = (
  loanId: string,
  loanStage: string,
  orgPartyId: string
) => {
  const url = `${BASE_URL}/bff/dscr/${loanId}/check-submit?stage=${loanStage}&originatorId=${orgPartyId}`;
  // return post(url, {}, {
  //   errorConfig: getErrorConfigObject({ skipForStatus: [400], skipErrorHandling: true })
  // });
  return post(url, {});
};

export const submitDscrLoan = (
  loanId: string,
  loanStage: string,
  reqBody: any
) => {
  const url = `${BASE_URL}/bff/dscr/${loanId}/submit?stage=${loanStage}`;
  return post(url, reqBody);
};

export const checkBridgeLoanSubmition = (
  loanStage: string,
  orgPartyId: string,
  reqBody: string[]
) => {
  const url = `${BASE_URL}/bff/bridge/check-submit?stage=${loanStage}&originatorId=${orgPartyId}`;
  // return post(url, reqBody, {
  //   errorConfig: getErrorConfigObject({ skipForStatus: [400], skipErrorHandling: true })
  // });
  return post(url, reqBody);
};

export const submitBridgeLoan = (loanStage: string, reqBody: string[]) => {
  const url = `${BASE_URL}/bff/bridge/bulk-submit?stage=${loanStage}`;
  return post(url, reqBody);
};

export function patchLoanData(
  payload: any,
  loanType: any,
  loanId: any,
  loanStage: any,
  loanSizerType?: boolean
) {
  return new Promise((resolve, reject) => {
    let url = `${
      getConfig().apiUrl
    }/bff/${loanType}/${loanId}/update?stage=${loanStage}&rulesTrigger=true`;

    if (loanSizerType) {
      url += `&loanSizerType=${loanSizerType}`;
    }

      return patch(url, payload)
      .then((res: any) => {
        if (payload?.loanData?.loanInfo?.firstPaymentDateOfLoan) {
          removeLoanEscrowMap(loanId);
          removePropertyEscrowMap(loanId, loanStage);
        }
        return resolve(res);
      })
      .catch((e: any) => reject(e));
  });
}

export const getLoanUserMapData = (loanId: string) => {
  const url = `${BASE_URL}/loans/${loanId}/user`;
  return get(url);
};

export const getUnitInfoData = (
  loanId: string,
  propertyId: any,
  loanStage: string
) => {
  const url = `${BASE_URL}/loan_property/${loanId}/properties/${propertyId}/propertyUnit?stage=${loanStage}`;
  return get(url);
};

export const getOriginatorBasedPartners = (
  originatorId: string,
  loanType: string
) => {
  const url = `${
    getConfig().apiUrl
  }/accountmap/originator/${originatorId}/loanType/${loanType}`;
  return get(url);
};

export const getOrgPartnerWise = (loanType: string, tpList: string[]) => {
  const url = `${BASE_URL}/accountmap/loanType/${loanType}`;
  return post(url, tpList);
};

export const getOrgUsers = (payload: any) => {
  const url = `${BASE_URL}/aggregate/v2/customer/emailList/fetch`;
  return post(url, payload);
};

export const getGCSPath = (payload: any) => {
  const url = `${BASE_URL}/cloudfiledownload`;
  return post(url, payload);
};

export const sendEmailBlast = (payload: any) => {
  const url = `${BASE_URL}/mock-service/send-in-blue/send-mail`;
  return post(url, payload);
};

export const getRateLockStatus = () => {
  const url = `${BASE_URL}/bff/dscr/check-ratelock`;
  return post(url);
}

export const getTermsheetData = (loanId: string, termsheetVersion: string) => {
  const url = `${BASE_URL}/online-sizer/term-sheet/${loanId}/${termsheetVersion}`;
  return get(url,undefined,{"kc-Realm" : "toorak-connect"});
}

export const getTermSheetVersions = (loanId: string) => {
  const url = `${BASE_URL}/online-sizer/term-sheet/${loanId}`;
  return get(url, undefined, { "kc-Realm" : "toorak-connect" });
}

export const getBuyDownOptions = (loanId?: string, loanStage?: string) => {
  const url = `${BASE_URL}/aggregate/v2/${loanId}/dscr/rules/buyDownOptions?stage=${loanStage}&loanRuleType=30year&generateBuyDownOptions=true`;
  return post(url);
};
export const getServicerLoanId = (reqBody: any) => {
  const url = `${BASE_URL}/serviceronboarding/onboardingrequestsForPagination?page=1&size=1`;
  return post(url, reqBody);
}
