import { Button } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { TermSheetV2 } from "@toorak/op-web-common-service";
import { getTermsheetData, getTermSheetVersions } from "../network/apiService";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { useDispatch } from "react-redux";
import { ObjectType } from "../masterView/common";
import closeIcon from "../images/close_white@2x.png";
import { useSelector } from "react-redux";
import { RootState } from "../stores/rootReducer";
import { ConfirmFormModal } from "@toorak/tc-common-fe-sdk";

const permissionsToRenderInUI = {
  showLoanDetails: true,
  showFeeDetails: true,
  showEmailSection: false,
  showEmailBody: true,
  isFullView: true,
  showMerchantsMortgage: false,
  showRequirements: true,
  showFooterActions: false,
  showDefaultSignature: false
};
const defaultTermSheetConfig = {
  isViewMode: true,
  termSheetAction: "view",
  header: {
    showOriginatorInfo: true,
    className: "termsheet-header",
    closeIcon: closeIcon
  },
  footer: {
    name: "serviceoriginator"
  },
  feeDetails: {
    config: []
  },
  termSheetVersion: "2",
  showWaterMark: false,
  capitalizeFirstLetterOfEachWord: (val: any) => {
    return val;
  }
};
const sanitizeCurrency = (value: any) => {
  if (isNaN(Number(value))) {
    return value;
  }
  const data = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
  }).format(value);

  return data;
};

export const TermSheetWrapper = (props?: any) => {
  const { updateShowTermSheetPopUp, showTermSheet, setShowTermSheet } = props;
  const [termSheetData, setTermSheetData] = useState<any>();
  const [termSheetConfig, setTermSheetConfig] = useState<any>();
  const [latestTermSheetVersion, setLatestTermSheetVersion] = useState<
    string | null
  >(null);

  const dispatch = useDispatch();

  const orgPartyId = sessionStorage.getItem("originatorPartyIdNew") || "";
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );

  const handleClose = () => {
    updateShowTermSheetPopUp?.();
    setLatestTermSheetVersion(null);
    setTermSheetData(null);
    setTermSheetConfig(null);
    setShowTermSheet(false);
  };

  const getLatestTermSheetVersion = async () => {
    try {
      const termSheetVersions: any = await getTermSheetVersions(
        loanDetails?.loanDetailId?.redirectMetaData?.[0]?.urlMeta?.loanUuid
      );
      const termSheetVersionData = termSheetVersions?.data?.data;
      const latestTermSheetVersion = termSheetVersionData?.reduce(
        (latest: any, current: any) => {
          return new Date(current["createdAt"]) > new Date(latest["createdAt"])
            ? current
            : latest;
        },
        termSheetVersionData[0]
      );
      setLatestTermSheetVersion(latestTermSheetVersion?.termSheetVersion || "");
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getLatestTermSheetVersion();
  }, [loanDetails?.loanDetailId?.redirectMetaData?.[0]?.urlMeta?.loanUuid]);

  useEffect(() => {
    if (!latestTermSheetVersion) return;
    const sizerLoanId =
      loanDetails?.loanDetailId?.redirectMetaData?.[0]?.urlMeta?.loanUuid;
    dispatch(showLoader(true));
    let getTermsheetDataPromise = [];
    getTermsheetDataPromise.push(
      getTermsheetData(sizerLoanId, latestTermSheetVersion)
    );
    Promise.all(getTermsheetDataPromise).then((res: any) => {
      const orgLogo = loanDetails?.orgMapping?.originatorLogo ?? "";
      const termSheetData = res?.[0]?.data?.data ?? {};
      const termSheetLoanData = termSheetData?.termSheetLoanInfo ?? {};
      const column1: ObjectType = {};
      const column2: ObjectType = {};
      Object.keys(termSheetLoanData).forEach((key: string, index: number) => {
        const value = sanitizeTermsheetValues(key, termSheetLoanData[key]);
        if (index <= 11) {
          column1[key] = value;
        } else {
          column2[key] = value;
        }
      });
      const termSheetResp = {
        ...termSheetData,
        loanData: [[column1], [column2]]
      };
      setTermSheetData(termSheetResp);
      const { termSheetVersion, termSheetLoanInfo, signatureInfo } =
        termSheetData ?? {};
      const termSheetTitle = `${signatureInfo?.name} - ID: ${termSheetLoanInfo?.["Scenario ID"]} (Termsheet v ${termSheetVersion}).pdf`;
      const termSheetConfig = {
        ...defaultTermSheetConfig,
        originatorLogo: orgLogo,
        signatureInfo: termSheetResp?.signatureInfo,
        header: {
          ...defaultTermSheetConfig.header,
          originatorInfo: termSheetTitle ?? ""
        }
      };
      setTermSheetConfig(termSheetConfig);
    });
    dispatch(hideLoader(true));
  }, [orgPartyId, latestTermSheetVersion]);

  const sanitizeTermsheetValues = (key: string, value: any) => {
    const currencyKeys = [
      "As-Is Value",
      "Estimated Minimum Reserves",
      "Total Loan Amount",
      "Purchase Price",
      "Monthly qualifyingPI",
      "Monthly Qualifying Payment",
      "Discount Points ($)"
    ];
    return currencyKeys?.includes(key) ? sanitizeCurrency(value) : value;
  };

  return (
    <>
      {termSheetConfig && latestTermSheetVersion ? (
        <TermSheetV2
          isOpenTermSheet={showTermSheet}
          termSheetData={termSheetData}
          setTermSheetData={setTermSheetData}
          isUserConfig={false}
          permissionsToRenderInUI={permissionsToRenderInUI}
          onClose={handleClose}
          termSheetConfig={termSheetConfig}
          triggeredSendEmail={false}
          emailBodyChange={() => {}}
          sanitizeCurrency={sanitizeCurrency}
          isTermsheetAlreadySaved={true}
          isDataEdited={false}
        />
      ) : null}
      {latestTermSheetVersion === "" ? (
        <ConfirmFormModal
          isOpen={!(termSheetConfig && latestTermSheetVersion)}
          handleClose={() => handleClose()}
          confirmCondition={false}
          header=""
          body={
            <p
              style={{
                lineHeight: 1.5,
                marginTop: 30,
                fontSize: 14,
                padding: 20,
                width: 500,
                paddingBlock: 0
              }}
            >
              There is no active termsheet available for this loan.
            </p>
          }
          size="xl"
          successHandler={() => handleClose()}
          hideSecondaryBtn={true}
          primaryBtnName="Ok"
          // SecondaryBtnName="No"
          disableBackdropClick
          footerStyles={{
            marginTop: "30px",
            padding: "0px 20px 20px 20px"
          }}
        />
      ) : null}
    </>
  );
};
