import React, { useEffect, useMemo, useState } from 'react';
import { isLATUser } from '../../utils/AccessManagement';
import { WarningModal } from '@toorak/tc-common-fe-sdk';
import { BasicDropDown } from '../../ui-components/BasicDropDown';
import CustomTable from '../../masterView/CustomTable';
import { InfoIcon } from "../../icons/info";
import { IconButton } from '@mui/material';
import { ObjectType } from '../../masterView/common';
import { formatValueByType } from '../../utils/formatChecks';

const cols = (
    lockinValuesMap: ObjectType,
    handleIndividualChange: Function
) => {
    const assignedPartnerTitle = isLATUser() ? "Partner Name" : "Program Name";
    return [
        { id: "originatorLoanId", title: "Originator Loan Id" },
        { id: "toorakLoanId", title: "Toorak Loan Id" },
        { id: "assignedPartner", title: assignedPartnerTitle },
        {
            id: "rateLockPeriod",
            title: "Lock-in Period",
            width: 240,
            renderCustom: (data: any) => {
                const options = lockinValuesMap?.[data?.takeOutPartner];
                return (
                    <BasicDropDown
                        selected={data?.rateLockPeriod}
                        handleChange={(e: any) =>
                            handleIndividualChange(e, data.toorakLoanId)
                        }
                        menuOptions={options}
                        selectStyles={{ fontSize: "12px" }}
                        placeHolderText="Select Lock-in Period"
                        width="220px"
                    />
                );
            }
        },
        {
            id: "actualInterestRate",
            title: "Interest Rate",
            renderCustom: (data: ObjectType) => {
                return formatValueByType(data.actualInterestRate, "percentage");
            }
        },
        { id: "basePrice", title: "Base Price" },
        { id: "totalPriceAdjustment", title: "Price Adj.-Total" },
        { id: "pppMaxPrice", title: "Prepayment Penalty-Max Price" },
        { id: "maxAllowedToorakPrice", title: "Max Allowed Toorak Price" },
        {
            id: "statepppRestrictionMaxPrice",
            title: "State PPP Restriction - Max Price"
        },
        {
            id: "finalToorakPrice",
            title: "Final Toorak Price",
            renderCustom: (data: ObjectType) => {
                const charge = parseFloat(
                    lockinValuesMap?.[data?.assignedPartner]?.find(
                        (it: ObjectType) => it.value === data?.rateLockPeriod
                    )?.charge ?? "0"
                );
                return (charge + parseFloat(data.finalToorakPrice)).toFixed(4);
            }
        }
    ];
};
export const RateLockSelection = (props: any) => {
    const {
        lockIn,
        handleLockInSelection,
        updatedOptions,
        // updateIsAllSelected,
        lockinValuesMap,
        selectedRowsData,
        setSelectedRowsData,
        showDisabledOptionsMessage,
        loanIsNotLockedInfo,
        setLoanIsNotLockedInfo,
        successHandler,
        classes,
        loansArray
    } = props;
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [openWarning, setOpenWarning] = useState(false);

    function haveSameAssignedPartner(arr: any[]) {
        if (arr.length === 0) {
            return true; // An empty array is considered to have the same assignedPartner.
        }

        const firstAssignedPartner = arr[0].assignedPartner;

        const diffAssignedPartner = arr.find(
            obj => obj.assignedPartner !== firstAssignedPartner
        );

        return diffAssignedPartner === undefined;
    }

    useEffect(() => {
        if (loanIsNotLockedInfo)
            setOpenWarning(true)

    }, [loanIsNotLockedInfo])



    const handleIndividualChange = (e: any, id: string) => {
        handleLockInSelection({ [id]: e.target.value });
    };
    const columns = cols(lockinValuesMap, handleIndividualChange);
    const handleChange = (e: any) => {
        let temp: { [key: string]: string } = {};
        selectedRows.forEach((id: string) => {
            temp = { ...temp, [id]: e.target.value };
        });
        handleLockInSelection(temp);
    };
    const getWarningBody = () => {
        if (loanIsNotLockedInfo) {
            return 'Loan is not currently locked.'
        }
        const tp = isLATUser() ? "partner" : "program";
        return `Please select loans assigned to same ${tp}.`;
    };

    const onRowSelect = (e: any, ids: string[], rows: any[]) => {
        let loanArr: string[] = [];
        let loanArrData: any[] = [];
        if (e.target.checked) {
            loanArr = [...selectedRows, ...ids];
            loanArrData = [...selectedRowsData, ...rows];
        } else {
            // loanArr =
            selectedRowsData.forEach((item: any) => {
                if (!ids.includes(item.toorakLoanId)) {
                    loanArr.push(item.toorakLoanId);
                    loanArrData.push(item);
                }
            });
        }
        const isSameAssignedPartner = e.target.checked
            ? haveSameAssignedPartner(loanArrData)
            : true;
        if (isSameAssignedPartner) {
            setSelectedRows(loanArr);
            setSelectedRowsData(loanArrData);
            // updateIsAllSelected(!(loansArray.length === loanArr.length));
        } else {
            setOpenWarning(true);
        }
    };

    const onSelectAll = (checked: boolean) => {
        if (checked) {
            const isSameAssignedPartner = haveSameAssignedPartner(loansArray);
            if (isSameAssignedPartner) {
                const rowIdsArr = loansArray.map((it: any) => it.toorakLoanId);
                setSelectedRows(rowIdsArr);
                setSelectedRowsData(loansArray);
                // updateIsAllSelected(false);
            } else {
                setOpenWarning(true);
            }
        } else {
            setSelectedRows([]);
            setSelectedRowsData([]);

            // updateIsAllSelected(true);
        }
    };
    const allSelected = useMemo(() => {
        return selectedRows.length === loansArray.length;
    }, [selectedRows, loansArray]);

    return (
        <>
            {openWarning && (
                <WarningModal
                    isOpen={openWarning}
                    handleClose={() => {
                        if (loanIsNotLockedInfo) {
                            successHandler()
                        }
                        setOpenWarning(false);
                        setLoanIsNotLockedInfo(false)
                    }}
                    header="Warning"
                    body={getWarningBody()}
                />
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#eef6ff",
                    border: "1px solid #f2f2f2"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                        fontSize: "16px"
                    }}
                >
                    <InfoIcon />
                    <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                        Change the lock-in period from dropdown
                    </span>
                </div>
                <BasicDropDown
                    selected={lockIn}
                    handleChange={handleChange}
                    menuOptions={updatedOptions}
                    selectStyles={{ fontSize: "12px" }}
                    placeHolderText="Select Lock-in Period"
                    width="250px"
                />
            </div>
            {showDisabledOptionsMessage && (
                <div
                    style={{
                        padding: 4,
                        backgroundColor: "#e9fafd",
                        borderRadius: 4,
                        fontSize: 12,
                        marginBottom: "1rem"
                    }}
                >
                    <IconButton
                        aria-label="information about rate lock"
                        style={{ padding: 0 }}
                    >
                        <InfoIcon />
                    </IconButton>
                    Extended lock terms not eligible due to min Toorak Price.
                </div>
            )}
            <div style={{ maxWidth: "calc(100VW - 64px)", overflowX: "auto" }}>
                <CustomTable
                    rowsData={loansArray}
                    columnsData={columns}
                    classes={classes}
                    onRowSelect={onRowSelect}
                    selectAllRows={onSelectAll}
                    allSelected={allSelected}
                    selectedLoans={selectedRows}
                    showCheckBox
                />
            </div>
        </>
    );
}