import {
  foreignNationalOptions,
  yesNoDropdown
} from "../../../create-loan/constants/loanCreationDropDownValues";
import { ObjectType } from "../../../masterView/common";

const firstName = {
  fieldName: "guarantorFirstName",
  backendKey: "guarantorFirstName",
  fieldLabel: "First Name",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please enter value",
  commentField: "customer.firstName",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const secondName = {
  fieldName: "guarantorLastName",
  backendKey: "guarantorLastName",
  fieldLabel: "Last Name",
  required: () => true,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please enter value",
  commentField: "customer.lastName",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const foreignNational = {
  fieldName: "foreignNationalString",
  backendKey: "foreignNationalString",
  fieldLabel: "Foreign National Flag",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: foreignNationalOptions.map((item) => {
    return { value: item, label: item };
  }),
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  commentField: "customer.foreignNationalString",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalCreditScoreMedian = {
  fieldName: "creditScore",
  backendKey: "creditScore",
  fieldLabel: "Original Credit Score (Median)",
  required: (guarantorInfo: any) => {
    if (
      guarantorInfo?.foreignNationalString &&
      guarantorInfo?.foreignNationalString !== "Yes"
    ) {
      return true;
    }
    return false;
  },
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please enter value",
  commentField: "originalCreditScoreMedian",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const originalCreditReportDate = {
  fieldName: "originalCreditReportDate",
  backendKey: "originalCreditReportDate",
  fieldLabel: "Original Credit Report Date",
  required: (guarantorInfo: any) => {
    if (
      guarantorInfo?.foreignNationalString &&
      guarantorInfo?.foreignNationalString !== "Yes"
    ) {
      return true;
    }
    return false;
  },
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  commentField: "originalCreditScoreReportDate",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const email = {
  fieldName: "guarantorEmail",
  backendKey: "guarantorEmail",
  fieldLabel: "Email",
  required: () => false,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  commentField: "customer.contactList[0].email",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const experience = {
  fieldName: "guarantorExperience",
  backendKey: "guarantorExperience",
  fieldLabel: "Experience",
  required: () => false,
  fieldType: (loanType: string) => "string",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  commentField: "experience",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const ownerShipEntity = {
  fieldName: "pOEntity",
  backendKey: "pOEntity",
  fieldLabel: "% of Ownership of Entity",
  required: (data: ObjectType, borrowerInfoData: any) => {
    if (Array.isArray(borrowerInfoData)) {
      return borrowerInfoData?.find(
        (borrower: any) => borrower?.payload?.borrowerType === "Entity"
      );
    }
    return (
      borrowerInfoData?.payload?.borrowerInformation?.borrowerType === "Entity"
    );
  },
  fieldType: (loanType: string) => "percentageWithout100x",
  colSpan: 4,
  isVisible: () => true,
  errorMsg: "Please select value",
  commentField: "ownershipOfEntity",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const firstTimeHomeBuyer = {
  fieldName: "firstTimeHomeBuyer",
  fieldLabel: "First Time Home Buyer",
  backendKey: "firstTimeHomeBuyer",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: yesNoDropdown.map((item) => {
    return { value: item, label: item };
  }),
  colSpan: 4,
  isVisible: (data: any) => true,
  commentField: "firstTimeHomeBuyer",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const prePostFields = [
  firstName,
  secondName,
  foreignNational,
  originalCreditScoreMedian,
  originalCreditReportDate,
  email,
  experience,
  ownerShipEntity
];

const dscrPrePostFields = [...prePostFields, firstTimeHomeBuyer];

export const getGuarantorInformationFields = (
  loanStage: string,
  loanType: string
) => {
  switch (loanStage) {
    case "fes":
      return [];
    case "pre":
    case "post":
      return loanType === "BridgeLoan" ? prePostFields : dscrPrePostFields;
  }
};
