interface Config {
  apiUrl: string;
  loginUrl: string;
  smartyStreetsApiKey: string;
  environment: string;
  aggregateUrl: string;
  dashboardUrl: string;
  prefix: string;
  wso2Url: string;
  keyClockUrl: string;
  redirectUrl: string;
  logoutCallbackURL: string;
  boxRedirectUrl: string;
  clientId: string;
  orgUser?: string;
  appraisalDashboardUrl: string;
  faroUrl: string;
  faroAPIKey: string;
  emailBlastChunkSize?: number;
}

const baseConfig: Config = {
  apiUrl: "http://localhost:8080",
  loginUrl: "http://loginurl.com",
  smartyStreetsApiKey: "3269747577631385",
  environment: "local",
  aggregateUrl: "dev_loan_aggregate",
  prefix: "dev",
  dashboardUrl: "dev_dashboard",
  wso2Url: "https://accounts-dev.toorakcapital.info",
  keyClockUrl:
    "https://auth-dev.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  redirectUrl: "http://localhost:3000/",
  logoutCallbackURL: "http://localhost:3000/logoutCallback",
  boxRedirectUrl: "http://localhost:3001/",
  clientId:
    "amwzSkREQlJNdDdyeGNzUE02YWRfbTRwSFZzYTpiRzlUNmFBdmwxRDNsOUNnVXFVUE05ZnQxaklh",
  orgUser: "dev_customer",
  appraisalDashboardUrl: "https://appraisal-dashboard.dev.toorakcapital.info/",
  faroUrl: "https://alloy-tc.dev.roemanu.io/collect",
  faroAPIKey: "tc_alloy_api_key"
};

const localConfig: Config = {
  ...baseConfig,
  smartyStreetsApiKey: "3269749656986978",
  apiUrl: "https://dev.toorakcapital.info/api",
  environment: "local",
  aggregateUrl: "dev_loan_aggregate",
  prefix: "dev",
  dashboardUrl: "dev_dashboard",
  wso2Url: "https://accounts-dev.toorakcapital.info",
  keyClockUrl:
    "https://auth-dev.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  redirectUrl: "http://localhost:3000/",
  logoutCallbackURL: "http://localhost:3000/logoutCallback",
  boxRedirectUrl: "http://localhost:3001",
  clientId:
    "amwzSkREQlJNdDdyeGNzUE02YWRfbTRwSFZzYTpiRzlUNmFBdmwxRDNsOUNnVXFVUE05ZnQxaklh",
  orgUser: "dev_customer",
  appraisalDashboardUrl: "https://appraisal-dashboard.dev.toorakcapital.info/",
  emailBlastChunkSize: 5,
};

export const devConfig: Config = {
  ...baseConfig,
  apiUrl: "https://dev.toorakcapital.info/api",
  environment: "development",
  aggregateUrl: "dev_loan_aggregate",
  prefix: "dev",
  dashboardUrl: "dev_dashboard",
  wso2Url: "https://accounts-dev.toorakcapital.info",
  keyClockUrl:
    "https://auth-dev.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  redirectUrl: "https://dev.toorakcapital.info/",
  logoutCallbackURL: "https://dev.toorakcapital.info/logoutCallback",
  boxRedirectUrl: "https://monitoring-dev.toorakcapital.info",
  clientId:
    "T1V1dndwaGNuNlpNcTJWem9GR0pwQkkxSnJ3YTpBT204VG5vNXN3MnBEQzBkcWx6dHJMRjY3SGdh",
  orgUser: "dev_customer",
  appraisalDashboardUrl: "https://appraisal-dashboard.dev.toorakcapital.info/",
  emailBlastChunkSize: 5,
};

export const qaConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-qa.toorakcapital.info",
  environment: "qa",
  aggregateUrl: "qa_loan_aggregate",
  prefix: "qa",
  dashboardUrl: "qa_dashboard",
  wso2Url: "https://accounts-qa.toorakcapital.info",
  keyClockUrl:
    "https://auth-qa.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  redirectUrl: "https://qa.toorakcapital.info/",
  logoutCallbackURL: "https://qa.toorakcapital.info/logoutCallback",
  boxRedirectUrl: "https://monitoring-qa.toorakcapital.info",
  clientId:
    "VTF1THNCQTBFUUpMY3lfeHowOUpjbWJUekNZYTp1dGJGajlXd21SMDBHOVFXU0V4ODA4dURkZmdh",
  orgUser: "qa_customer",
  appraisalDashboardUrl: "https://appraisal-dashboard.qa.toorakcapital.info/",
  faroUrl: "https://alloy-tc.qa.roemanu.io/collect",
  emailBlastChunkSize: 2,
};

export const prodConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api.toorakcapital.info",
  environment: "production",
  aggregateUrl: "prod_loan_aggregate",
  prefix: "prod",
  dashboardUrl: "prod_dashboard",
  wso2Url: "https://accounts.toorakcapital.info",
  redirectUrl: "https://www.toorakcapital.info/",
  keyClockUrl:
    "https://auth-gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  logoutCallbackURL: "https://www.toorakcapital.info/logoutCallback",
  boxRedirectUrl: "https://monitoring.toorakcapital.info",
  clientId:
    "MmkzdERiZjlIZWZzWW81M2lWRllWaWQxa2JvYTpyRkVLRTl1bDU2MUQwT2llMkJyQVgwSHM0c29h",
  orgUser: "prod_customer",
  appraisalDashboardUrl: "https://appraisal-dashboard.www.toorakcapital.info/",
  faroUrl: "https://alloy-tc.roemanu.io/collect",
  faroAPIKey: "tc_prod_alloy_api_key",
  emailBlastChunkSize: 95,
};

export const uatConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-uat.toorakcapital.info",
  environment: "uat",
  aggregateUrl: "uat_loan_aggregate",
  prefix: "uat",
  dashboardUrl: "uat_dashboard",
  wso2Url: "https://accounts-uat.toorakcapital.info",
  keyClockUrl:
    "https://auth-uat.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  redirectUrl: "https://uat.toorakcapital.info/",
  logoutCallbackURL: "https://uat.toorakcapital.info/logoutCallback",
  boxRedirectUrl: "https://monitoring-uat.toorakcapital.info",
  clientId:
    "QUxiVE85TWMyOEMzcW1mVE1kSE96UjJOM3ZvYTpVdzZsRGV0NFA0ZHpZeGZxNzB2UlBsVVVwZ0Vh",
  orgUser: "uat_customer",
  appraisalDashboardUrl: "https://appraisal-dashboard.uat.toorakcapital.info/",
  faroUrl: "https://alloy-tc.uat.roemanu.io/collect",
  emailBlastChunkSize: 2,
};

export const perfConfig: Config = {
  ...baseConfig,
  apiUrl: "https://api-perf.toorakcapital.info",
  environment: "perf",
  aggregateUrl: "perf_loan_aggregate",
  prefix: "perf",
  dashboardUrl: "perf_dashboard",
  wso2Url: "https://accounts-qa.toorakcapital.info",
  keyClockUrl:
    "https://auth-perf.gcp.roemanu.io/realms/toorak-connect/protocol/openid-connect",
  redirectUrl: "https://perf.toorakcapital.info/",
  logoutCallbackURL: "https://perf.toorakcapital.info/logoutCallback",
  boxRedirectUrl: "https://monitoring-perf.toorakcapital.info",
  clientId:
    "cFFROEE2Q2JLa3RZeGRNdHZjSk44QnY1QUw0YTpQTXBOal9HNDk4QzRvVjE5N0Zfc3dIWDFJb2th",
  orgUser: "perf_customer",
  appraisalDashboardUrl: "https://appraisal-dashboard.perf.toorakcapital.info/",
  faroUrl: "https://alloy-tc.perf.roemanu.io/collect",
  emailBlastChunkSize: 5,
};

export const getConfig: () => Config = () => {
  switch (window.location.hostname) {
    case "localhost":
      return localConfig;
    case "dev.toorakcapital.info":
      return devConfig;
    case "qa.toorakcapital.info":
      return qaConfig;
    case "www.toorakcapital.info":
      return prodConfig;
    case "toorakcapital.info":
      return prodConfig;
    case "uat.toorakcapital.info":
      return uatConfig;
    case "perf.toorakcapital.info":
      return perfConfig;
    default:
      return devConfig;
  }
};

export const getLoanType = {
  0: {
    name: "Bridge Loan",
    displayValue: "BridgeLoan",
    loanTypeId: 2,
    loanCreationName: "Bridge Loan",
    settlementTabPath: "Bridge"
  },
  1: {
    name: "Investor DSCR",
    displayValue: "InvestorDSCR",
    loanTypeId: 1,
    loanCreationName: "30 Year Loan",
    settlementTabPath: "DSCR"
  },
  2: {
    name: "2 + 1 Loan",
    displayValue: "TwoPlusOneLoan",
    loanTypeId: 3,
    loanCreationName: "2 + 1 Loan"
  },
  3: {
    name: "Bridge Toorval Loan",
    displayValue: "ToovalBridge",
    loanTypeId: 4,
    loanCreationName: "Toorval Bridge Loan"
  }
};

export const getLoanStatus = {
  0: {
    name: "Preliminary Sizing",
    displayValue: "Preliminary Sizing"
  }
};

export const loanTypeInfoByName: {
  [index: string]: {
    showAs: string;
    loanTypeId: number;
  };
} = {
  TwoPlusOneLoan: {
    showAs: "2 + 1 Loan",
    loanTypeId: 3
  },
  InvestorDSCR: {
    showAs: "Investor DSCR",
    loanTypeId: 1
  },
  BridgeLoan: {
    showAs: "Bridge Loan",
    loanTypeId: 2
  }
};
export const getLengthAndTypeOfInput = [
  {
    fieldName: "textDescriptionField",
    length: 1000,
    type: "textField"
  },
  {
    fieldName: "textField",
    length: 50,
    type: "textField"
  },
  {
    fieldName: "textField",
    length: 50,
    type: "numeric"
  },
  {
    fieldName: "alphanumericField",
    length: 50,
    type: "alphanumeric"
  }
];
