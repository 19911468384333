import React, { useContext, useMemo, useState } from "react";
import { AppBar, FormControlLabel, Grid, IconButton, List, Tab, Tabs, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { resultsTabs } from "./GuidlinesComparision";
import { ObjectType } from "../../masterView/common";
import { CustomCSSRadio } from "./PricingAndGuidlinesSection.style";
import { useStylesResultsMessage } from "./ResultsListWithFailMessages";
import { isLATUser } from "../../utils/AccessManagement";
import { CreditLineLoanViewContext } from "../CreateLoan";
import CustomChip from "./program-partner-selection/CustomChip";
import CheckCirclePass from "../../images/createloan/CheckCirclePass.svg";
import FailedIcon from "../../images/createloan/FailedTestIcon.svg";
import CriticalIcon from "../../images/createloan/CriticalFailedTestIcon.svg";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pieHollow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      width: "10rem",
      height: "10rem"
    },
    pieCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      width: "8rem",
      height: "8rem",
      margin: "1rem",
      background: "#fff",
      position: "absolute"
    },
    cardWrapper: {
      border: "1px solid #dedede",
      borderRight: "0px",
      minWidth: "calc(33VW - 16px)"
    },
    cardHeader: {
      borderBottom: "1px solid #dedede",
      padding: "1rem",
      justifyContent: "center",
      display: "flex",
      height: "100%",
    },
    cardNavigator: {
      position: "absolute",
      right: "50px"
    },
    cardChart: {
      display: "flex",
      justifyContent: "center",
      padding: "2rem",
      position: "relative",
      borderBottom: "1px solid #dedede"
    },
    tabs: {
      padding: "0px 24px",
      borderBottom: "1px solid #dedede",
      backgroundColor: "transparent",
      backgroundImage: "none",
      zIndex: 1
    },
    tabWrapper: {
      display: "flex",
      flexDirection: "row"
    },
    tabLabel: {
      minHeight: "0px",
      textTransform: "none",
      fontSize: "14px",
      minWidth: "unset !important",
      padding: "0.5rem 1rem",
      marginRight: "2rem"
    }
  })
);

const colorMap: any = {
  fail: "#f75676",
  pass: "#2ece89",
  waived: "rgb(250,195,0)"
};

const chipClasses = {
  failedChip: {
    background: "#DEBB01",
    color: "#ffffff",
    fontSize: "10px",
    borderRadius: "6px",
    padding: "0px",
    marginLeft: "4px"
  },
  criticalFailed: {
    background: "#F75676",
    color: "#ffffff",
    fontSize: "10px",
    borderRadius: "6px",
    padding: "0px"
  }
}

export const getOriginatorLabel = (label: any, creditLines: ObjectType[]) => {
  const creditLineObj = creditLines.find((cdl: any) => cdl.fullName === label);
  return creditLineObj?.programName ?? label
}

const ProgrammeSelect = ({
  programDetails,
  index,
  isSelectedProgram,
  handleProgramChange,
  creditLines,
  partnerWiseRateLockOptions
}: any) => {
  
  return (
    <div style={{textAlign: "center"}}>
    <Grid
      container
      alignItems="center"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <Grid item>
        <FormControlLabel
          value={programDetails.label}
          control={
            partnerWiseRateLockOptions[programDetails?.id || programDetails?.key]?.isRateLockEnabled ?
            <CustomCSSRadio
              checked={isSelectedProgram}
              onChange={() => handleProgramChange(programDetails)}
            /> : <span />
          }
          label=""
          style={{ margin: 0, padding: 0 }}
        />
      </Grid>
      <Grid item>
        <div
          style={{
            fontWeight: 600
          }}
        >
          {isLATUser()
            ? `${programDetails.label}`
            : `${getOriginatorLabel(programDetails.label, creditLines)}`}
        </div>
      </Grid>
      <Grid item style={{marginLeft : "1rem"}}> 
        {programDetails?.criticalFailed && CustomChip ? <CustomChip
          size="small"
          label={
            <div className="div-space-between">
              <img
                src={FailedIcon}
                alt="info-icon"
                className="mr-8"
              />
              <span style={{ fontSize: "12px", color: "white" }}>{`Critical Fails - ${programDetails?.criticalFailed}`} {/* ${test[Object.keys(test)[0]]?.length} */}</span>
            </div>
          }
          variant="outlined"
          className={chipClasses.criticalFailed}
        /> : null}
        {programDetails?.failedCount && CustomChip ? <CustomChip
          size="small"
          label={
            <div className="div-space-between">
              <img
                src={CriticalIcon}
                alt="info-icon"
                className="mr-8"
              />{" "}
              <span style={{ fontSize: "12px", color: "white" }}>{`Non-Critical Fails - ${programDetails?.failedCount - programDetails?.criticalFailed}`} {/* ${test[Object.keys(test)[0]]?.length} */}</span>
            </div>
          }
          variant="outlined"
          className={chipClasses.failedChip}
        /> : null}
        {!(programDetails?.criticalFailed || programDetails?.failedCount) ? <img
          src={CheckCirclePass}
          alt="check-pass-circle"
          className="mr-8"
        /> : null}
      </Grid>
      
    </Grid>
    {!partnerWiseRateLockOptions[programDetails?.id || programDetails?.key]?.isRateLockEnabled ? 
        <div>
          <span style={{fontSize: "10px", lineHeight:"10px", color: "#F75676", fontWeight: "600"}}>
            Rate locks for this program are currently unavailable.
            <br /> 
            Please contact your Loan Acquisition Specialist.
          </span>
        </div> : null}
    </div>
  );
};

const HallowPieChart = (props: any) => {
  const { sectionsData } = props;
  const classes = useStyles();
  const bgColor = useMemo(() => {
    const initialVal = { val: "", percent: 0 };
    const finalVal = sectionsData?.reduce(
      (acc: ObjectType, current: ObjectType, index: number) => {
        if (index === 0) {
          return {
            val: `conic-gradient(${current.color} 0% ${current.percent}%`,
            percent: current.percent
          };
        }
        if (index === sectionsData.length - 1) {
          return {
            val: `${acc.val}, ${current.color} ${acc.percent}% 100%)`,
            percent: 100
          };
        }
        return {
          val: `${acc.val}, ${current.color} ${acc.percent}% ${acc.percent +
            current.percent}%`,
          percent: acc.percent + current.percent
        };
      },
      initialVal
    );
    return finalVal.val;
  }, [sectionsData]);

  return (
    <div className={classes.cardChart}>
      <div
        style={{
          backgroundImage: bgColor
        }}
        className={classes.pieHollow}
      ></div>
      <div className={classes.pieCenter}></div>
    </div>
  );
};

const TestsList = (props: any) => {
  const classes = useStylesResultsMessage();
  return (
    <List style={{ paddingLeft: "1rem" }}>
      {props.testsData?.map((item: any) => {
        return (
          <div
            className={classes.cardWrapper}
            style={{ borderColor: "#dedede" }}
          >
            <div className={classes.testNameHeader}>
              <div className={classes.testNameWrapper}>
                <span className={classes.testName}>{item.label}</span>
                {item.type && (
                  <span style={{ marginLeft: "2px" }}>{`(${item.type})`}</span>
                )}
              </div>
            </div>
            {item.reason && (
              <div className={classes.reasonText}>
                Failure Reason: <b>{item.reason}</b>
              </div>
            )}
          </div>
        );
      })}
    </List>
  );
};

const Indicator = (props: any) => {
  const { color } = props;
  return (
    <span
      style={{
        border: `6px solid ${color}`,
        borderRadius: "50%",
        margin: "0px 1rem 0 0"
      }}
    ></span>
  );
};

export const ResultsSummaryCard = (props: any) => {
  const {
    index,
    partnerData,
    showNavigator,
    range,
    rangeEnd,
    handleNavigation,
    width,
    programmeSelectConfig,
    showSummary = true
  } = props;
  const classes = useStyles();
  const { creditLines } = useContext(CreditLineLoanViewContext);
  const [selectedView, setSelectedView] = useState("fail");
  const handleChange = (e: any, val: string) => {
    if (!val) return;
    setSelectedView(val);
  };
  const {
    handleProgramChange,
    isProgramSelectView,
    selectedProgram,
    partnerWiseRateLockOptions
  } = programmeSelectConfig;

  const sectionsData = useMemo(() => {
    const passCount = partnerData?.pass?.length;
    const failCount = partnerData?.fail?.length;
    const waivedCount = partnerData?.waived?.length;
    const totalCount = passCount + failCount + waivedCount;
    return resultsTabs.map(item => ({
      label: item.label,
      color: colorMap[item.value],
      percent: Math.round(
        (partnerData?.[item.value]?.length / totalCount) * 100
      )
    }));
  }, [partnerData]);

  return (
    <div
      className={classes.cardWrapper}
      key={partnerData.key}
      style={{ width: `${width}%`, minWidth: `${width}%`, height:"100%" }}
    >
      <div className={classes.cardHeader}>
        {isProgramSelectView ? (
          <ProgrammeSelect
            index={index + 1}
            programDetails={partnerData}
            isSelectedProgram={selectedProgram}
            handleProgramChange={handleProgramChange}
            creditLines={creditLines}
            partnerWiseRateLockOptions={partnerWiseRateLockOptions}
          />
        ) : (
          <div>{partnerData.label}</div>
        )}
        {showNavigator && (
          <div className={classes.cardNavigator}>
            <IconButton
              disabled={range.start === 0}
              style={{ padding: "0px" }}
              onClick={() => handleNavigation(-1)}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              disabled={range.end === rangeEnd}
              style={{ padding: "0px" }}
              onClick={() => handleNavigation(1)}
              size="large"
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        )}
      </div>
      {showSummary && (
        <>
          <div>
            <AppBar position="static" color="default" className={classes.tabs}>
              <Tabs
                value={selectedView}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="partner classification"
                className="loans-tab-container"
                style={{ minHeight: "unset" }}
              >
                {resultsTabs.map((item: any) => (
                  <Tab
                    icon={<Indicator color={colorMap[item.value]} />}
                    key={item.value}
                    label={`${item.label}${partnerData?.[item.value]?.length
                      ? `(${partnerData?.[item.value]?.length})`
                      : ""
                      }`}
                    value={item.value}
                    classes={{
                      iconWrapper: classes.tabWrapper,
                      labelIcon: classes.tabLabel
                    }}
                  />
                ))}
              </Tabs>
            </AppBar>
            {resultsTabs.map((item: any) => {
              return (
                <>
                  {selectedView === item.value && (
                    <TestsList testsData={partnerData?.[item.value]} />
                  )}
                </>
              );
            })}
          </div>
          <HallowPieChart sectionsData={sectionsData} />
        </>
      )}
    </div>
  );
};

export const ProgrammeSelectionHeader = (props: any) => {
  const {
    index,
    partnerData,
    showNavigator,
    range,
    rangeEnd,
    handleNavigation,
    width,
    programmeSelectConfig,
    showSummary = true
  } = props;
  const classes = useStyles();
  const { creditLines } = useContext(CreditLineLoanViewContext);
  const [selectedView, setSelectedView] = useState("fail");
  const handleChange = (e: any, val: string) => {
    if (!val) return;
    setSelectedView(val);
  };
  const {
    handleProgramChange,
    isProgramSelectView,
    selectedProgram
  } = programmeSelectConfig;

  const sectionsData = useMemo(() => {
    const passCount = partnerData?.pass?.length;
    const failCount = partnerData?.fail?.length;
    const waivedCount = partnerData?.waived?.length;
    const totalCount = passCount + failCount + waivedCount;
    return resultsTabs.map(item => ({
      label: item.label,
      color: colorMap[item.value],
      percent: Math.round(
        (partnerData?.[item.value]?.length / totalCount) * 100
      )
    }));
  }, [partnerData]);

  return (
    <div
      className={classes.cardWrapper}
      key={partnerData.key}
      style={{ width: `${width}%`, minWidth: `${width}%` }}
    >
      <div className={classes.cardHeader}>
        <ProgrammeSelect
          index={index + 1}
          programDetails={partnerData}
          isSelectedProgram={selectedProgram}
          handleProgramChange={handleProgramChange}
          creditLines={creditLines}
        />

        {showNavigator && (
          <div className={classes.cardNavigator}>
            <IconButton
              disabled={range.start === 0}
              style={{ padding: "0px" }}
              onClick={() => handleNavigation(-1)}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              disabled={range.end === rangeEnd}
              style={{ padding: "0px" }}
              onClick={() => handleNavigation(1)}
              size="large"
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        )}
      </div>
      {showSummary && (
        <>
          <div>
            <AppBar position="static" color="default" className={classes.tabs}>
              <Tabs
                value={selectedView}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="partner classification"
                className="loans-tab-container"
                style={{ minHeight: "unset" }}
              >
                {resultsTabs.map((item: any) => (
                  <Tab
                    icon={<Indicator color={colorMap[item.value]} />}
                    key={item.value}
                    label={`${item.label}${partnerData?.[item.value]?.length
                      ? `(${partnerData?.[item.value]?.length})`
                      : ""
                      }`}
                    value={item.value}
                    classes={{
                      iconWrapper: classes.tabWrapper,
                      labelIcon: classes.tabLabel
                    }}
                  />
                ))}
              </Tabs>
            </AppBar>
            {resultsTabs.map((item: any) => {
              return (
                <>
                  {selectedView === item.value && (
                    <TestsList testsData={partnerData?.[item.value]} />
                  )}
                </>
              );
            })}
          </div>
          <HallowPieChart sectionsData={sectionsData} />
        </>
      )}
    </div>
  );
};
