import { useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../stores/rootReducer";

export const useShowTermsheet = () => {
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { isEvaluationPanelVisible } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );

  const showTermSheetPopUp = useMemo(() => {
    return loanDetails?.orgMapping?.viewTermsheet;
  }, [loanDetails, isEvaluationPanelVisible]);

  return showTermSheetPopUp;
};
