/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { ObjectType } from "../../masterView/common";
import { isNullOrUndefined } from "../../utils/formatChecks";
import {
  propertyTypeDropDown,
  thirtyYearPropertyTypeDropDown,
  yOrNDropDown,
  recoursePrePost,
  borrowerType,
  loanStructureDropDown,
  toorakProductDropDown,
  loanPurposeDropDown,
  accuralTypeDropDown,
  thirtyYearLoanPurpose,
  condoEligibilityDropDown,
  yesNoDropdown,
  rentDescription,
  rentDescriptionOld,
  prepaymentPenaltyTypeDropDown,
  rateType,
  thirtyYearToorakProduct,
  ToorakProductEnum
} from "./loanCreationDropDownValues";

export interface FieldInfo {
  [index: string]: {
    fieldTpe: string;
    extraData?: any;
    optionalIn?: LoanStage[];
    defaultValue?: any;
    isHidden?: (data: any) => boolean;
  };
}
export function isTwoPlusOneLoan(data: any) {
  return (
    data?.bridgeLoanInformation?.payload?.selectedToorakProduct ===
    ToorakProductEnum.Rental
  );
}
export function notTwoPlusOneLoan(data: any) {
  return (
    data?.bridgeLoanInformation?.payload?.selectedToorakProduct !==
    ToorakProductEnum.Rental
  );
}

export function isNotStabilised(data: ObjectType) {
  return data?.loanEvaluationResult?.loanResult?.[data.loanId]?.loanCharacterisations?.stabilizedFlag !== "Stabilized";
}
export function isNotGroundUpLoan(data: ObjectType) {
  return (
    data?.bridgeLoanInformation?.payload?.selectedToorakProduct !==
    ToorakProductEnum.GroundUp
  );
}

export function isRateTypeFixed(data: ObjectType) {
  return data?.bridgeLoanEconomics?.payload?.rateType === "Fixed";
}
export function isSelectedCrossHidden(data: ObjectType) {
  return data?.thirtyYearLoanInformation?.payload?.numberOfProperties <= 1;
}
export const bridgeLoanFields: FieldInfo = {
  sellerPartyId: { fieldTpe: "string" },
  borrowingEntityName: { fieldTpe: "string" },
  firstName: { fieldTpe: "string" },
  lastName: { fieldTpe: "string" },
  borrowerType: { fieldTpe: "dropDown", extraData: borrowerType },
  borrowerExperience: { fieldTpe: "number" },
  heavyRehabExperience: { fieldTpe: "number" },
  borrowerGUCExperience: { fieldTpe: "number" },
  billingAddress: { fieldTpe: "string", optionalIn: [LoanStage.pre] },
  foreignNationalString: {
    fieldTpe: "dropDown",
    extraData: [
      "yes",
      "no",
      "Yes",
      "No",
      "Permanent/Non-Permanent Resident Alien",
      "Permanent Resident Alien",
      "Non-Permanent Resident Alien"
    ]
  },
  borrowerEmail: { fieldTpe: "email", optionalIn: [LoanStage.pre] },
  billingPhoneNumber: { fieldTpe: "phone", optionalIn: [LoanStage.pre] },
  originalCreditScoreMedian: { fieldTpe: "number" }, // in borrower
  originalCreditReportDate: { fieldTpe: "date" },
  creditScore: { fieldTpe: "number" }, // in guarantor
  guarantorFirstName: { fieldTpe: "string" },
  guarantorLastName: { fieldTpe: "string" },
  guarantorEmail: {
    fieldTpe: "email",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  guarantorExperience: {
    fieldTpe: "number",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  pOEntity: {
    fieldTpe: "percentage"
  },
  primaryLoanID: { fieldTpe: "string" },
  numberOfProperties: { fieldTpe: "number" },
  extensionOption: { fieldTpe: "number" },
  loanPurpose: { fieldTpe: "dropDown", extraData: loanPurposeDropDown },
  selectedLoanStructure: {
    fieldTpe: "dropDown",
    extraData: loanStructureDropDown,
    optionalIn: [LoanStage.pre]
  },
  selectedRecourse: {
    fieldTpe: "dropDown",
    extraData: recoursePrePost,
    optionalIn: [LoanStage.pre]
  },
  selectedCross: {
    fieldTpe: "dropDown",
    extraData: yOrNDropDown,
    optionalIn: [LoanStage.pre]
  },
  pledgeOfEquity: { fieldTpe: "dropDown", extraData: yOrNDropDown },
  selectedToorakProduct: {
    fieldTpe: "dropDown",
    extraData: toorakProductDropDown
  },
  cutOffDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre]
  },
  maturityDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre]
  },
  firstPaymentDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre]
  },
  accrualDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  nextPaymentDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  originationDate: {
    fieldTpe: "date"
  },
  interestRate: { fieldTpe: "percentage" },
  accrualType: {
    fieldTpe: "dropDown",
    extraData: accuralTypeDropDown,
    optionalIn: [LoanStage.pre]
  },
  discountPoints: {
    fieldTpe: "percentage",
    optionalIn: [LoanStage.pre]
  },
  loanAmount: { fieldTpe: "currency" },
  maxLoanAmount: {
    fieldTpe: "currency",
    isHidden: isTwoPlusOneLoan
  },
  financedBudgetAmount: {
    fieldTpe: "currency",
    isHidden: isTwoPlusOneLoan
  },
  cutOffLoanAmount: { fieldTpe: "currency" },
  cutOffMaxLoanAmount: {
    fieldTpe: "currency",
    isHidden: isTwoPlusOneLoan
  },
  budgetAmount: {
    fieldTpe: "currency",
    isHidden: isTwoPlusOneLoan
  },
  cashOutAmount: {
    fieldTpe: "currency",
    optionalIn: [LoanStage.pre]
  },
  financialInterestReserve: {
    fieldTpe: "currency",
    optionalIn: [LoanStage.pre]
  },
  rateType: { fieldTpe: "dropDown", extraData: rateType },
  armIndex: {
    fieldTpe: "string",
    isHidden: isRateTypeFixed
  },
  grossArmMargin: {
    fieldTpe: "percentage",
    isHidden: isRateTypeFixed
  },
  initialRateAdjustmentPeriod: {
    fieldTpe: "number",
    isHidden: isRateTypeFixed
  },
  initialPayAdjustmentPeriod: {
    fieldTpe: "number",
    isHidden: isRateTypeFixed
  },
  // initialInterestRateDown: {
  //   fieldTpe: "decimal",
  //   isHidden: isRateTypeFixed
  // },
  // initialInterestRateUp: {
  //   fieldTpe: "decimal",
  //   isHidden: isRateTypeFixed
  // },
  // lifetimeMaxRate: {
  //   fieldTpe: "percentage",
  //   isHidden: isRateTypeFixed
  // },
  // lifetimeMinRate: {
  //   fieldTpe: "percentage",
  //   isHidden: isRateTypeFixed
  // },
  // subsequentInterestRateDown: {
  //   fieldTpe: "decimal",
  //   isHidden: isRateTypeFixed
  // },
  // subsequentInterestRateUp: {
  //   fieldTpe: "decimal",
  //   isHidden: isRateTypeFixed
  // },
  initialPeriodicCap: { fieldTpe: "decimal", optionalIn: [LoanStage.pre] },
  lifeRateCap: { fieldTpe: "decimal", optionalIn: [LoanStage.pre] },
  subsequentPeriodicCap: { fieldTpe: "decimal", optionalIn: [LoanStage.pre] },
  paymentAdjustmentFrequency: {
    fieldTpe: "number",
    isHidden: isRateTypeFixed
  },
  rateAdjustmentFrequency: {
    fieldTpe: "number",
    isHidden: isRateTypeFixed
  },
  // armLookBackDays: {
  //   fieldTpe: "string",
  //   isHidden: isRateTypeFixed
  // },
  // armRoundFlag: {
  //   fieldTpe: "string",
  //   isHidden: isRateTypeFixed
  // },
  // armRoundingFactor: {
  //   fieldTpe: "string",
  //   isHidden: isRateTypeFixed
  // },
  includeOutOfPocketBudgetInARLTV: {
    fieldTpe: "checkBox"
  }
};
export const bridgePropertyFields: FieldInfo = {
  propertyType: { fieldTpe: "dropDown", extraData: propertyTypeDropDown },
  isPropertyPurchasedLast2Years: {
    fieldTpe: "dropDown",
    extraData: yesNoDropdown,
    isHidden: isNotGroundUpLoan
  },
  changeInUseCase: {
    fieldTpe: "dropDown",
    extraData: yOrNDropDown,
    optionalIn: [LoanStage.pre],
    isHidden: isTwoPlusOneLoan
  },
  propertyValuationDate: { fieldTpe: "date" },
  propertyAcquisitionDate: { fieldTpe: "date" },
  numberOfUnits: { fieldTpe: "number" },
  preRehabSquareFootage: { fieldTpe: "number" },
  postRehabSquareFootage: {
    fieldTpe: "number",
    isHidden: isTwoPlusOneLoan
  },
  appraisalReported: {
    fieldTpe: "currency",
    defaultValue: "$0.00",
    isHidden: isTwoPlusOneLoan
  },
  percentageOccupied: { fieldTpe: "percentage", defaultValue: "0%" },
  originalAppraisalValue: { fieldTpe: "currency" },
  originalRepairedAppraisalValue: { fieldTpe: "currency" },
  purchasePrice: { fieldTpe: "currency" },
  costBasis: {
    fieldTpe: "currency",
    optionalIn: [LoanStage.pre],
    defaultValue: "$0.00"
  },
  grossPotentialRent: { fieldTpe: "currency", isHidden: isNotStabilised },
  assignmentFees: { fieldTpe: "currency", defaultValue: "" },
  annualPropertyTaxes: { fieldTpe: "currency", isHidden: isNotStabilised },
  annualHoaFee: { fieldTpe: "currency", isHidden: notTwoPlusOneLoan },
  rentalCashflowRatio: { fieldTpe: "percentage", isHidden: notTwoPlusOneLoan },
  insurance: { fieldTpe: "currency", isHidden: isNotStabilised }
};
export const thirtyYearLoanFields: FieldInfo = {
  sellerPartyId: { fieldTpe: "string" },
  borrowingEntityName: { fieldTpe: "string" },
  firstName: { fieldTpe: "string" },
  lastName: { fieldTpe: "string" },
  borrowerType: { fieldTpe: "dropDown", extraData: borrowerType },
  billingAddress: { fieldTpe: "string", optionalIn: [LoanStage.pre] },
  foreignNationalString: {
    fieldTpe: "dropDown",
    extraData: [
      "yes",
      "no",
      "Yes",
      "No",
      "Permanent/Non-Permanent Resident Alien",
      "Permanent Resident Alien",
      "Non-Permanent Resident Alien"
    ]
  },
  borrowerEmail: { fieldTpe: "email", optionalIn: [LoanStage.pre] },
  billingPhoneNumber: { fieldTpe: "phone", optionalIn: [LoanStage.pre] },
  originalCreditScoreMedian: { fieldTpe: "number" }, // in borrower
  originalCreditReportDate: { fieldTpe: "date" },
  creditScore: { fieldTpe: "number" }, // in guarantor
  guarantorFirstName: { fieldTpe: "string" },
  guarantorLastName: { fieldTpe: "string" },
  guarantorEmail: {
    fieldTpe: "email",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  guarantorExperience: {
    fieldTpe: "number",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  pOEntity: {
    fieldTpe: "percentage"
  },
  primaryLoanID: { fieldTpe: "string" },
  loanPurpose: { fieldTpe: "dropDown", extraData: thirtyYearLoanPurpose },
  selectedToorakProduct: {
    fieldTpe: "dropDown",
    extraData: thirtyYearToorakProduct
  },
  cutOffDate: {
    fieldTpe: "date"
  },
  originationDate: {
    fieldTpe: "date"
  },
  firstPaymentDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre]
  },
  accrualDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  nextPaymentDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  maturityDate: {
    fieldTpe: "date",
    optionalIn: [LoanStage.pre]
  },
  selectedRecourse: {
    fieldTpe: "dropDown",
    extraData: recoursePrePost
  },
  selectedCross: {
    fieldTpe: "dropDown",
    extraData: yOrNDropDown,
    isHidden: isSelectedCrossHidden
  },
  numberOfProperties: { fieldTpe: "number" },
  condoEligibility: {
    fieldTpe: "dropDown",
    extraData: condoEligibilityDropDown
  },
  discountPoints: {
    fieldTpe: "percentage",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  financialInterestReserve: {
    fieldTpe: "currency"
  },
  loanAmount: { fieldTpe: "currency" },
  currentLoanBalance: { fieldTpe: "currency" },
  interestRate: { fieldTpe: "percentage" },
  rateType: { fieldTpe: "dropDown", extraData: rateType },
  accrualType: {
    fieldTpe: "dropDown",
    extraData: accuralTypeDropDown
  },
  // discountPoints: { fieldTpe: "number" },
  cashOutAmount: {
    fieldTpe: "currency"
  },
  borrowerLiquidity: {
    fieldTpe: "currency",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  // armProductType: { fieldTpe: "string" },
  armIndex: {
    fieldTpe: "string",
    optionalIn: [LoanStage.pre, LoanStage.post, LoanStage.fes]
  },
  grossArmMargin: {
    fieldTpe: "percentage",
    optionalIn: [LoanStage.pre, LoanStage.post, LoanStage.fes]
  },
  interestOnlyPeriod: {
    fieldTpe: "number",
    optionalIn: [LoanStage.pre, LoanStage.post, LoanStage.fes]
  },
  // originalMonthlyPiPayment: { fieldTpe: "currency" },
  // originalQualifyingPaymentMonthly: { fieldTpe: "currency" },
  // qualifyingPaymentInReservesMonthly: { fieldTpe: "number" },
  initialRateAdjustmentPeriod: {
    fieldTpe: "number",
    optionalIn: [LoanStage.pre]
  },
  initialPayAdjustmentPeriod: {
    fieldTpe: "number",
    optionalIn: [LoanStage.pre]
  },
  initialPeriodicCap: { fieldTpe: "decimal", optionalIn: [LoanStage.pre] },
  lifeRateCap: { fieldTpe: "decimal", optionalIn: [LoanStage.pre] },
  // lifetimeMaxRate: { fieldTpe: "percentage", optionalIn: [LoanStage.pre] },
  // lifetimeMinRate: { fieldTpe: "percentage", optionalIn: [LoanStage.pre] },
  subsequentPeriodicCap: { fieldTpe: "decimal", optionalIn: [LoanStage.pre] },
  paymentAdjustmentFrequency: {
    fieldTpe: "number",
    optionalIn: [LoanStage.pre]
  },
  rateAdjustmentFrequency: { fieldTpe: "number", optionalIn: [LoanStage.pre] },
  // prepaymentPenaltyMonths: { fieldTpe: "number" },
  prepayPenaltyType: {
    fieldTpe: "dropDown",
    extraData: prepaymentPenaltyTypeDropDown
  },
  costToLoanRatio: { fieldTpe: "percentage" },
  // debtServiceCoverageRatio: { fieldTpe: "decimal" },
  subordinateFinancing: { fieldTpe: "dropDown", extraData: yesNoDropdown },
  businessPurposeOccupancy: { fieldTpe: "dropDown", extraData: yesNoDropdown },
  creditEvent: {
    fieldTpe: "dropDown",
    extraData: yesNoDropdown,
    optionalIn: [LoanStage.pre, LoanStage.post, LoanStage.fes]
  },
};

export const thirtyYearPropertyfields: FieldInfo = {
  propertyType: {
    fieldTpe: "dropDown",
    extraData: thirtyYearPropertyTypeDropDown
  },
  numberOfUnits: { fieldTpe: "number" },
  propertyValuationDate: { fieldTpe: "date" },
  squareFootage: { fieldTpe: "number" },
  // percentageOccupied: { fieldTpe: "percentage" },
  propertyAcquisitionDate: { fieldTpe: "date" },
  originalAppraisalValue: { fieldTpe: "currency" },
  thirdPartyValuation: {
    fieldTpe: "currency",
    optionalIn: [LoanStage.pre, LoanStage.post]
  },
  purchasePrice: { fieldTpe: "currency" },
  costBasis: {
    fieldTpe: "currency"
  },
  annualPropertyTaxes: { fieldTpe: "currency" },
  annualHoaFee: { fieldTpe: "currency" },
  annualHazardInsurance: { fieldTpe: "currency" },
  annualFloodInsurance: { fieldTpe: "currency" },
  leaseStatus: {
    fieldTpe: "dropDown",
    extraData: [...rentDescription, ...rentDescriptionOld]
  },
  currentLeaseTerm: { fieldTpe: "decimal" },
  marketRentMonthly: { fieldTpe: "currency" },
  thirdPartyRentRangeAmount: { fieldTpe: "currency" },
  inPlaceLeaseRent: { fieldTpe: "currency" },
  recentLeaseRent: { fieldTpe: "currency" },
  decliningMarkets: {
    fieldTpe: "dropDown",
    extraData: yesNoDropdown
  }
};

export function getDefaultValue(loanType: string, fieldName: string) {
  let defaultValue = null;
  switch (loanType) {
    case "BridgeLoan":
      {
        const value = bridgePropertyFields[fieldName]?.defaultValue;
        if (!isNullOrUndefined(value)) defaultValue = value;
      }
      break;

    default:
      break;
  }
  return defaultValue;
}
